import { Component, OnInit } from '@angular/core'
import { PageEvent } from '@angular/material/paginator'
import { Sort } from '@angular/material/sort'
import { BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { catchError, map, switchMap, take, tap } from 'rxjs/operators'

import { AddressService } from '../../address'
import {
    PaymentAccount,
    PaymentAccountDto,
    PaymentAccountStatus,
    PaymentAccountType,
    Tab,
    TableId
} from '../../core/models'
import { BusinessService, PaymentAccountService, TabService, UrlService } from '../../core/services'
import { CurrencyService } from '../../currencies/services'
import { DialogService } from '../../dialogs'
import { ListField, PageRequest } from '../../table/models'
import { VerifyDialogComponent } from '../verify-dialog/verify-dialog.component'

@Component({
    selector: 'lqd-foreign-accounts',
    templateUrl: './foreign-accounts.component.html',
    styleUrls: ['./foreign-accounts.component.scss'],
})
export class ForeignAccountsComponent implements OnInit {

    readonly columns: Array<ListField> = [
        {
            id: 'createdTimestamp',
            label: 'Created',
            type: 'timestamp-long',
        },
        {
            id: 'accountHolderName',
            label: 'Account Holder Name',
        },
        {
            id: 'country',
            label: 'Country',
        },
        {
            id: 'bankExistsInDatabase',
            label: 'Foreign Bank',
            type: 'icon',
            notSortable: true,
            getValue(item: PaymentAccountDto): string {
                if (item.paymentAccountType !== PaymentAccountType.OtherBankAccount || item.bankId) {
                    return 'check-circle'
                }
                return 'alert-triangle'
            },
            getTooltip(row: PaymentAccountDto): string {
                if (row.paymentAccountType !== PaymentAccountType.OtherBankAccount || row.bankId) {
                    return 'All good'
                }
                return 'There is no bank with such a swift code in the database.'
            },
        },
        {
            id: 'currentVerificationStatus',
            label: 'In Review',
            notSortable: true,
            checkButtonIcon: 'check-circle',
            type: 'check-button',
        },
        {
            id: 'status',
            label: 'Verify',
            notSortable: true,
            checkButtonIcon: 'check',
            type: 'check-button',
        },
    ]
    length: number
    results: BehaviorSubject<Array<PaymentAccountDto>> = new BehaviorSubject([])
    readonly tableId: TableId = TableId.foreignPending

    get rows$(): Observable<Array<PaymentAccountDto>> { return this.results.asObservable() }

    constructor(
        private addresses: AddressService,
        private businesses: BusinessService,
        private currencies: CurrencyService,
        private dialogs: DialogService,
        private paymentAccounts: PaymentAccountService,
        private tabService: TabService,
        private urls: UrlService,
    ) { }

    ngOnInit(): void {
        this.addresses.initialize().subscribe()
    }

    onPageChange(event: { page: PageEvent, sort: Sort }): void {

        if (!event?.page?.pageSize || event?.page?.pageIndex < 0 || !event?.sort?.active || !event?.sort?.direction) {
            return
        }

        const page: PageEvent = event.page
        const sort: Sort = event.sort
        const pageRequest: PageRequest = {
            page: page.pageIndex + 1,
            pageSize: page.pageSize,
            sortBy: `${sort.active} ${sort.direction}`,
        }

        this.businesses.getForeignPendingAccounts(pageRequest)
            .pipe(
                take(1),
                tap(data => {
                    this.length = data.rowCount
                    this.results.next(data.results.map(acct => {
                        return {
                            ...acct,
                            currentVerificationStatus: acct.currentVerificationStatus === PaymentAccountStatus.InReview ? acct.currentVerificationStatus : undefined,
                        }
                    }))
                }),
            )
            .subscribe()
    }

    onRowClick(account: PaymentAccount): void {
        const tab: Tab = {
            label: `${account.accountHolderName}`,
            removable: true,
            route: this.urls.route.businessDetails(account.organizationId),
        }
        this.tabService.addTab(tab)
    }

    verifyAccount(event: { row: PaymentAccount }): void {

        const paymentAccount: PaymentAccount = event.row
        const countryId: string = this.addresses.countries.find(option => option.label === paymentAccount.country)?.value

        this.dialogs.wait()
        combineLatest([
            this.businesses.getBusiness(paymentAccount.organizationId),
            this.paymentAccounts.getOtherBankAccountProps(paymentAccount.id),
            this.currencies.get(countryId),
        ])
            .pipe(
                map(([business, bankAccountProperties, countryInfo]) => ({
                    data: {
                        account: paymentAccount,
                        business: business,
                        details: bankAccountProperties,
                        properties: countryInfo.properties,
                    },
                })),
                switchMap(config => this.dialogs.open(VerifyDialogComponent, config).afterClosed()),
                tap(updatedAccount => {
                    const updatedIndex: number = this.results.value.findIndex(account => account.id === updatedAccount.id)
                    const accounts: Array<PaymentAccountDto> = this.results.value

                    if ([PaymentAccountStatus.Verified, PaymentAccountStatus.Rejected].includes(updatedAccount.currentVerificationStatus)) {
                        accounts.splice(updatedIndex, 1)
                    } else {
                        accounts[updatedIndex] = updatedAccount
                    }

                    this.results.next(accounts)
                }),
                catchError(error => this.dialogs.error(error)),
            )
            .subscribe()
    }
}
