import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { ModuleWithProviders, NgModule } from '@angular/core'

import { AuthModule } from '../auth/auth.module'
import { SearchService } from '../search/services'

import { KycStatusService, KycStatusStore, PaymentStatusService, PaymentStatusStore, UrlService } from './services'

@NgModule({
    declarations: [],
    imports: [
        AuthModule,
        CommonModule,
        HttpClientModule,
    ],
})
export class CoreModule {

    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                KycStatusService,
                KycStatusStore,
                PaymentStatusService,
                PaymentStatusStore,
                SearchService,
                UrlService,
            ],
        }
    }
}
