<div>
    <header
        *ngIf="!!title || !hideCloseButton"
        class="header"
    >
        <h1 *ngIf="!!title">
            <div>
                {{title}}
                <ng-content select="app-help-tooltip"></ng-content>
            </div>
            <ng-content select=".discard"></ng-content>
        </h1>
        <button
            *ngIf="!hideCloseButton"
            mat-icon-button
            type="button"
            (click)="emitClose($event)"
            class="dialog-close"
        >
            <i-feather
                id="close"
                name="x"
            ></i-feather>
        </button>
    </header>

    <mat-dialog-content
        *ngIf="!hideHeader"
        class="dialog-header"
    >
        <ng-content select=".dialog-header"></ng-content>
    </mat-dialog-content>

    <mat-dialog-content>
        <ng-content select=".dialog-content"></ng-content>
    </mat-dialog-content>

    <mat-dialog-actions *ngIf="!hideButton">
        <ng-content select=".dialog-button"></ng-content>
    </mat-dialog-actions>
</div>