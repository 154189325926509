import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { catchError, take } from 'rxjs/operators'

import { DialogService } from '../../dialogs'

import { SiteStatusStore } from './site-status.store'

@Injectable({
    providedIn: 'root',
})
export class SiteStatusService {

    constructor(
        private dialogs: DialogService,
        private store: SiteStatusStore,
    ) { }

    set(turnOn: boolean, startsOn: number, endsOn: number): Observable<any> {
        return this.store.set(turnOn, startsOn, endsOn)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }
}
