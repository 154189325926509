<lqd-status-form
    *ngIf="!!businessDetail"
    [id]="businessDetail.id"
    [showKyc]="true"
    (updated)="kycStatusUpdate()"
>
</lqd-status-form>

<mat-card *ngIf="!!businessDetail">
    <mat-card-header>
        <mat-card-title>
            <h3>
                Business Details {{businessDetail.isTestAccount ? '(Test Account)' : ''}}
            </h3>
        </mat-card-title>
        <button
            mat-flat-button
            color="primary"
            (click)="popEditFlags()"
        >
            Edit Flags
        </button>
        <ng-container *ngIf="canRetryPayment">
            <button
                mat-flat-button
                class="retry-payment-button"
                color="warn"
                (click)="popRetrySubscriptionCharges()"
            >
                RETRY PAYMENT
            </button>
        </ng-container>
    </mat-card-header>
    <div class="row">
        <div class='column'>
            <h5>Payment Status: </h5>
            <p>{{businessDetail.paymentStatus}}</p>
        </div>
        <div class='column'>
            <h5>KYC Status: </h5>
            <p>{{businessDetail.kycStatus}}</p>
        </div>
        <div class='column'>
            <h5>Current Liquid Plan: </h5>
            <p>{{businessDetail.servicePlanName}}</p>
        </div>
    </div>
    <div class='row'>
        <div class='column'>
            <h5>Name: </h5>
            <p>{{businessDetail.name}}</p>
        </div>
        <div class='column'>
            <h5>EIN/SSN: </h5>
            <div class="flex-row">
                <div>
                    <p *ngIf="!unmaskedSsnOrEin">
                        {{businessDetail.taxId}}
                    </p>
                    <p *ngIf="!!unmaskedSsnOrEin">
                        {{unmaskedSsnOrEin}}
                    </p>
                </div>
                <div style="flex-grow: 3;">
                    <button
                        type="button"
                        mat-icon-button
                        [matTooltip]="!unmaskedSsnOrEin ? 'View unmasked value' : 'Hide'"
                        (click)="popUnmaskSsnOrEinOrHide()"
                    >
                        <i-feather [name]="unmaskedSsnOrEin ? 'eye' : 'eye-off'"></i-feather>
                    </button>
                </div>
            </div>
        </div>
        <div class='column'>
            <h5>Address: </h5>
            <app-address-display [address]="businessDetail.address"></app-address-display>
        </div>
    </div>
    <div class='row'>
        <div class='column'>
            <h5>Business Type: </h5>
            <p>{{businessDetail.businessType}}</p>
        </div>
        <div class='column'>
            <h5>Business Structure: </h5>
            <p>{{businessDetail.businessStructure}}</p>
        </div>
        <div class='column'>
            <h5>Created On: </h5>
            <p>{{businessDetail.dateCreated | date: 'longDate'}}</p>
        </div>
    </div>
    <div class="row">
        <div class="column">
            <h5>Is Full Bucket: </h5>
            <p>{{ businessDetail.isFullBucket }}</p>
        </div>
    </div>

    <lqd-logs
        [id]="businessLogsId"
        [logs]="businessDetail.logs"
    ></lqd-logs>

    <h5>Invoices: </h5>
    <div class="balance-row">
        <h6 *ngIf="isClient && pendingTotal !== undefined ">Pending Release Total: {{
            pendingTotal | moneyMaskPipe }}</h6>
        <div class="row balance">
            <div
                *ngIf="!!plaidBalance && !plaidLoading && !disablePlaid"
                class="subtitle1"
            >{{plaidBalance | moneyMaskPipe}}</div>
            <div
                *ngIf="!!plaidBalance && !plaidLoading && disablePlaid"
                class="subtitle1"
            >Unable to make request, default bank account is not connected to Plaid</div>
            <mat-spinner
                *ngIf="!!plaidLoading"
                [diameter]="50"
            ></mat-spinner>
            <button
                *ngIf="!plaidLoading"
                mat-flat-button
                color="primary"
                [disabled]="disablePlaid"
                (click)="requestPlaidBalance()"
            >Request Plaid Balance</button>
        </div>
    </div>
    <app-table
        *ngIf="!!businessDetail.invoices.length"
        [id]="bizInvoiceTableId"
        [noStick]="true"
        [columns]="invoiceColumns"
        [customPaging]="false"
        [data$]="invoices$"
        readonlyModeContainerClasses="overflow-x-scroll"
    >
    </app-table>

    <h5>Users: </h5>
    <app-table
        *ngIf="!!businessDetail.users.length"
        [id]="usersTableId"
        [noStick]="true"
        [columns]="userColumns"
        [customPaging]="false"
        [data$]="users$"
        (checkButtonClick)="onUserCheckButtonClick($event)"
        readonlyModeContainerClasses="overflow-x-scroll"
    >
    </app-table>
    <div
        class='row'
        style='justify-content: space-between;'
    >
        <h5>Payment Accounts: </h5>
        <button
            *ngIf="businessDetail.isTestAccount"
            mat-button
            class="no-borders"
            (click)="addDummyAccount(businessDetail?.id)"
        >
            Add Dummy Account
        </button>
    </div>
    <app-table
        *ngIf="!!businessDetail.paymentAccounts.length"
        [id]="paymentsTableId"
        [noStick]="true"
        [columns]="paymentColumns"
        [customPaging]="false"
        [data$]="paymentAccounts$"
        (rowClick)="popPaymentDetails($event)"
        (checkButtonClick)="onCheckButtonClick($event)"
        readonlyModeContainerClasses="overflow-x-scroll"
    >
    </app-table>
    <h5>KYC Documents: </h5>
    <app-table
        *ngIf="!!businessDetail.kycDocuments.length"
        [id]="kycTableId"
        [data$]="kycDocs$"
        [defaultSort]="{ active: 'originalFilename', direction: 'asc' }"
        [columns]="kycColumns"
        [customPaging]="false"
        [noStick]="true"
        (download)="downloadDoc($event)"
        (rowClick)="popKycDocDetails($event)"
        readonlyModeContainerClasses="overflow-x-scroll"
    >
    </app-table>
    <h5>Beneficial Owners: </h5>
    <app-table
        *ngIf="!!businessDetail.beneficialOwners.length"
        [id]="ownersTableId"
        [noStick]="true"
        [defaultSort]="{ active: 'name', direction: 'asc' }"
        [columns]="ownerColumns"
        [customPaging]="false"
        [data$]="owners$"
        (rowClick)="editOwner($event)"
        (remove)="removeOwner($event)"
        readonlyModeContainerClasses="overflow-x-scroll"
    ></app-table>
</mat-card>

<app-kyc-form
    [businessId]="businessDetail?.id"
    (add)="addKycDoc($event)"
    *ngIf="!!businessDetail"
></app-kyc-form>

<app-beneficial-owner-form
    *ngIf="!!businessDetail"
    [businessId]="businessDetail?.id"
    (add)="addOwner($event)"
    (update)="updateOwner($event)"
></app-beneficial-owner-form>

<lqd-manual-usbank-account-form
    *ngIf="!!businessDetail?.manualUSBankAccountEnabled"
    [enabled]="businessDetail?.manualUSBankAccountEnabled"
    [biz]="businessDetail"
    (add)="addManualUSBankAccount($event)"
></lqd-manual-usbank-account-form>