<mat-card>

    <mat-card-header>
        <mat-card-title>
            <h3>
                Add Manual US Bank Account
            </h3>
        </mat-card-title>
    </mat-card-header>

    <div
        *ngIf="!enabled && !!paymentAccount"
        class="inline-warning"
    >
        Unverified
    </div>

    <app-country-bank-account-config
        [countryName]="countryName"
        [currencyName]="currencyName"
        [editing]="false"
        [isForManualUSBankAccount]="true"
        [prefills]="prefills"
        [properties$]="properties$"
        (save)="saveManualUSBankAccount($event)"
    ></app-country-bank-account-config>

    <mat-card-actions>

        <button
            type="button"
            mat-stroked-button
            (click)="resetManualUSBankAccount()"
            [disabled]="!config?.form?.dirty"
        >
            Reset
        </button>

        <button
            mat-flat-button
            color="primary"
            (click)="onSaveManualUSBankAccount()"
            [disabled]="!!config?.form?.invalid || !config?.form?.dirty"
        >
            Add
        </button>

    </mat-card-actions>

</mat-card>
