import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { catchError, take, tap } from 'rxjs/operators'

import { AddressFactory } from '../../address'
import { OrganizationRole } from '../../auth/models'
import { OtherBankAccountRequest } from '../../country-bank-account-config/models/other-bank-account-request.interface'
import { DialogService } from '../../dialogs'
import { PagedSet, PageRequest } from '../../table/models/'
import { AdminBusiness, AdminBusinessDetail, AdminBusinessProperties, AdminKycDocument, AdminPaymentAccount, BeneficialOwner, BeneficialOwnerRequest, KycDocument, KycDocumentRequest, KycUploadResponse, PaymentAccountDto } from '../models'
import { MicrodepositVerificationRequest } from '../models/microdeposit-verification-request.interface'

import { BusinessStore } from './business.store'

@Injectable({
    providedIn: 'root',
})
export class BusinessService {

    constructor(
        private addressFactory: AddressFactory,
        private dialogs: DialogService,
        private store: BusinessStore,
    ) { }

    addDummyAccount(businessId: string): Observable<void> {
        return this.store.addDummyAccount(businessId)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    createKycDoc(req: KycDocumentRequest): Observable<AdminKycDocument> {
        return this.store.createKycDoc(req)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    createManualUSBankAccount(request: OtherBankAccountRequest): Observable<AdminPaymentAccount> {
        return this.store.createManualUSBankAccount(request)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    createOwner(req: BeneficialOwnerRequest): Observable<BeneficialOwner> {
        return this.store.createOwner(req)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    createPartialBusiness(request: any): Observable<any> {
        return this.store.createPartialBusiness(request)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    deleteOwner(ownerId: string): Observable<void> {
        return this.store.deleteOwner(ownerId)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    downloadDoc(doc: KycDocument): Observable<boolean> {
        return this.store.downloadDoc(doc)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    get(req: PageRequest): Observable<PagedSet<AdminBusiness>> {
        return this.store.get(req)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    getBusiness(id: string): Observable<AdminBusinessDetail> {
        return this.store.getBusiness(id)
            .pipe(
                take(1),
                tap(detail => {
                    detail.address = this.addressFactory.create(detail.address)
                    detail.beneficialOwners.forEach(owner => owner.address = this.addressFactory.create(owner.address))
                }),
                catchError(error => this.dialogs.error(error)),
            )
    }

    getForeignPendingAccounts(req: PageRequest): Observable<PagedSet<PaymentAccountDto>> {
        return this.store.getForeignPendingAccounts(req)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    getPaymentAccountsForReview(req: PageRequest): Observable<PagedSet<PaymentAccountDto>> {
        return this.store.getPaymentAccountsForReview(req)
            .pipe(
                take(1),
            )
    }

    getPendingReleaseTotal(orgId: string): Observable<number> {
        return this.store.getPendingReleaseTotal(orgId)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    getUnreviewedMicrodepositVerificationRequests(req: PageRequest): Observable<PagedSet<MicrodepositVerificationRequest>> {
        return this.store.getUnreviewedMicrodepositVerificationRequests(req)
            .pipe(
                take(1),
            )
    }

    getUnverifiedLiquidAccounts(req: PageRequest): Observable<PagedSet<PaymentAccountDto>> {
        return this.store.getUnverifiedLiquidAccounts(req)
            .pipe(
                take(1),
            )
    }

    getRoles(): Observable<OrganizationRole[]> {
        return this.store.getRoles()
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    setMicrodepositVerificationRequestDecision(id: string, releaseDecision: boolean): Observable<void> {
        return this.store.setMicrodepositVerificationRequestDecision(id, releaseDecision)
    }

    subscriptionBillingRetry(organizationId: string): Observable<any> {
        return this.store.subscriptionBillingRetry(organizationId)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    unmaskField(businessId: string, fieldName: Extract<keyof AdminBusinessDetail, string>, message: string): Observable<string> {
        return this.store.unmaskField(businessId, fieldName, message)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    unmaskPaymentAccountFields(businessId: string, paymentAccountId: string, paymentAccountType: string, fieldNames: Array<Extract<keyof AdminPaymentAccount, string>>, message: string): Observable<{ [key: string]: string }> {
        return this.store.unmaskPaymentAccountFields(businessId, paymentAccountId, paymentAccountType, fieldNames, message)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    unmaskPaymentOtherBankAccountFields(businessId: string, paymentAccountId: string, fieldNames: Array<string>, message: string): Observable<{ [key: string]: string }> {
        return this.store.unmaskPaymentOtherBankAccountFields(businessId, paymentAccountId, fieldNames, message)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    updateBusinessProperties(req: AdminBusinessProperties): Observable<AdminBusinessDetail> {
        return this.store.updateBusinessProperties(req)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    updateFlags(businessId: string, isTestAccount: boolean, quickBooksEnabled: boolean,
        customContractsEnabled: boolean, manualUSBankAccountEnabled: boolean,
        internationalClient: boolean, internal: boolean, fake: boolean, archive: boolean,
        message: string): Observable<AdminBusiness> {
        return this.store.updateFlags(businessId, isTestAccount, quickBooksEnabled, customContractsEnabled, manualUSBankAccountEnabled,
            internationalClient, internal, fake, archive, message)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    uploadKycDoc(businessId: string, file: File): Observable<KycUploadResponse> {

        const formData: FormData = new FormData()
        formData.append('file', file)

        return this.store.uploadKycDoc(businessId, formData, file.name)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    updateOwner(req: BeneficialOwnerRequest): Observable<BeneficialOwner> {
        return this.store.updateOwner(req)
            .pipe(
                take(1),
                tap(owner => owner.address = this.addressFactory.create(owner.address)),
                catchError(error => this.dialogs.error(error)),
            )
    }

    verifyOtherAccount(businessId: string, paymentAccountId: string, status: string, message: string): Observable<PaymentAccountDto> {
        return this.store.verifyOtherAccount(businessId, paymentAccountId, status, message)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }
}
