import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

import { BeneficialOwner } from '../../core/models'

@Component({
    selector: 'app-edit-owner-dialog',
    templateUrl: './edit-owner-dialog.component.html',
    styleUrls: ['./edit-owner-dialog.component.scss'],
})
export class EditOwnerDialogComponent {

    bizId: string
    owner: BeneficialOwner

    constructor(
        @Inject(MAT_DIALOG_DATA) data: { owner: BeneficialOwner, bizId: string },
        public dialogRef: MatDialogRef<EditOwnerDialogComponent>,
    ) {
        this.owner = data.owner
        this.bizId = data.bizId
    }

    closeEdit(owner?: BeneficialOwner): void {
        this.dialogRef.close(owner)
    }
}
