import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'adminTransactionSort',
})
// maps the column ids from transaction tables to client invoice properties to be able to sort
export class AdminTransactionSortPipe implements PipeTransform {

    transform(column: string): string {

        switch (column) {
            case 'client':
                return 'clientName'
            case 'vendor':
                return 'invoicerName'
            case 'paymentStatus':
                return 'currentState'
            default:
                return column
        }
    }
}
