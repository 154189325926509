<h3>User Table</h3>
<app-table
    [id]="tableId"
    class="data-table"
    [noStick]="true"
    [columns]="columns"
    [length]="length"
    [defaultSort]="{ active: 'name', direction: 'asc' }"
    [customPaging]="true"
    [persistPage]="true"
    [data$]="rows$"
    (rowClick)="onRowClick($event)"
    (checkButtonClick)="onCheckButtonClick($event)"
    (pageChange)="onPageChange($event)"
    readonlyModeContainerClasses="overflow-x-scroll"
></app-table>