import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { catchError, take } from 'rxjs/operators'

import { DialogService } from '../../dialogs'
import { AdminBusiness, PaymentStatus } from '../models'

import { PaymentStatusStore } from './payment-status.store'

@Injectable({
    providedIn: 'root',
})
export class PaymentStatusService {

    constructor(
        private dialogs: DialogService,
        private store: PaymentStatusStore,
    ) { }

    get(): Observable<Array<PaymentStatus>> {
        return this.store.get()
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    preview(businessId: string, status: PaymentStatus, message: string): Observable<AdminBusiness> {
        return this.store.preview(businessId, status?.id, message)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    set(businessId: string, status: PaymentStatus, message: string): Observable<AdminBusiness> {
        return this.store.set(businessId, status?.id, message)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }
}
