import { Component, Inject } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { tap } from 'rxjs/operators'

import { AdminBusiness, AdminBusinessDetail } from '../../core/models'
import { BusinessService } from '../../core/services'

@Component({
    selector: 'lqd-edit-flags-dialog',
    templateUrl: './edit-flags-dialog.component.html',
    styleUrls: ['./edit-flags-dialog.component.scss'],
})
export class EditFlagsDialogComponent {

    biz: AdminBusinessDetail
    customContractsEnabled: FormControl
    form: FormGroup
    manualUSBankAccountEnabledFlag: FormControl
    message: FormControl
    testAccount: FormControl
    qbFlag: FormControl
    internationalClient: FormControl
    internal: FormControl
    fake: FormControl
    archive: FormControl

    constructor(
        private business: BusinessService,
        @Inject(MAT_DIALOG_DATA) data: { biz: AdminBusinessDetail },
        public dialogRef: MatDialogRef<EditFlagsDialogComponent>,
    ) {
        this.biz = data.biz
        this.customContractsEnabled = new FormControl(this.biz.customContractsEnabled)
        this.message = new FormControl(undefined)
        this.testAccount = new FormControl(this.biz.isTestAccount)
        this.qbFlag = new FormControl(this.biz.quickBooksEnabled)
        this.manualUSBankAccountEnabledFlag = new FormControl(this.biz.manualUSBankAccountEnabled)
        this.internationalClient = new FormControl(this.biz.isInternationalClient)
        this.internal = new FormControl(this.biz.isInternal)
        this.fake = new FormControl(this.biz.isFake)
        this.archive = new FormControl(this.biz.isArchive)
        this.form = new FormGroup({
            testAccount: this.testAccount,
            customContractsEnabled: this.customContractsEnabled,
            qbFlag: this.qbFlag,
            manualUSBankAccountEnabledFlag: this.manualUSBankAccountEnabledFlag,
            message: this.message,
            internationalClient: this.internationalClient,
            internal: this.internal,
            fake: this.fake,
            archive: this.archive,
        })
    }

    submitFlags(): void {
        this.business.updateFlags(this.biz.id, this.testAccount.value,
            this.qbFlag.value, this.customContractsEnabled.value,
            this.manualUSBankAccountEnabledFlag.value,
            this.internationalClient.value, this.internal.value,
            this.fake.value, this.archive.value, this.message.value)
            .pipe(
                tap(biz => this.close(biz)),
            )
            .subscribe()
    }

    close(biz?: AdminBusiness): void {
        this.dialogRef.close(biz)
    }

}
