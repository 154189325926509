import { Component, OnInit } from '@angular/core'
import { interval, Subject } from 'rxjs'
import { take, takeUntil, tap } from 'rxjs/operators'

import { AuthService, LoggingService } from '../auth/services'

@Component({
    selector: 'lqd-auth-callback',
    template: '',
})
export class AuthCallbackComponent implements OnInit {

    private readonly unsubscribe$: Subject<void> = new Subject()

    constructor(
        private authService: AuthService,
    ) { }

    ngOnInit(): void {
        this.authService.completeAuthentication()
            .subscribe()
    }
}
