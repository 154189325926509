import { Injectable } from '@angular/core'

import { OrganizationRole, OrganizationRoleMembers, UserAdminRoles, UserAdminRolesIndent } from '../models'

@Injectable({
    providedIn: 'root',
})
export class RolesService {

    getHighestRoleAssigned(memberRoles: Array<String>, orgRoles: { [key: string]: UserAdminRoles }): Array<String> {
        const admin: String = memberRoles.find(role => this.checkRoleIndentLevel(<string>role, orgRoles, UserAdminRolesIndent.FirstLevel))
        if (!!admin) {
            return [admin]
        }

        const res: Array<String> = []
        const childrenNotAdded: Array<string> = []

        memberRoles.forEach((role) => {
            if (this.checkRoleIndentLevel(<string>role, orgRoles, UserAdminRolesIndent.SecondLevel)) {
                res.push(role)
                orgRoles[<string>role].includesRoles.forEach(r => {
                    childrenNotAdded.push(r)
                })
            }
        })

        memberRoles.forEach((role) => {
            if (this.checkRoleIndentLevel(<string>role, orgRoles, UserAdminRolesIndent.ThirdLevel) && !childrenNotAdded.includes(<string>role)) {
                res.push(role)
            }
        })

        return res
    }

    structureRoles(roles: OrganizationRole[]): { [key: string]: OrganizationRoleMembers } {
        const subRoles: { [key: string]: string } = {}
        let path: number = 0

        return roles.reduce((acc, role) => {
            const rolePath: string = subRoles.hasOwnProperty(role.name) ? subRoles[role.name] : (path++).toString()
            const _role: OrganizationRoleMembers = {
                ...role,
                collapsed: false,
                hidden: false,
                indent: rolePath.split('_').length - 1,
                members: [],
                path: rolePath,
            }

            if (!!_role.includesRoles.length) {
                let subPath: number = 0
                role.includesRoles.forEach(subRoleName => {
                    subRoles[subRoleName] = `${_role.path}_${subPath}`
                    subPath++
                })
            }
            acc[_role.name] = _role
            return acc
        }, {})
    }

    private checkRoleIndentLevel(role: string, orgRoles: { [key: string]: UserAdminRoles }, indent: number): boolean {
        return role === orgRoles[role].name && orgRoles[role].indent === indent
    }
}
