import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'

import { Address, AddressService } from '../../address'
import { AdminPaymentAccount } from '../../core/models'
import { AdminProfile } from '../../core/models/admin-profile.interface'
import { CountryBankAccountConfigComponent, CountryBankAccountInfo, OtherBankAccountProperty, OtherBankAccountRequest, PrefillType } from '../../country-bank-account-config'
import { CurrencyService } from '../../currencies/services'
import { DialogService } from '../../dialogs'

import { AdminBusinessDetail } from './../../core/models/admin-business-detail.interface'
import { BusinessService } from './../../core/services/business.service'

@Component({
    selector: 'lqd-manual-usbank-account-form',
    templateUrl: './manual-usbank-account-form.component.html',
    styleUrls: ['./manual-usbank-account-form.component.scss'],
})
export class ManualUSBankAccountFormComponent implements OnInit {

    private countryBankAccountInfo: CountryBankAccountInfo
    private firstCenturyBankAddressSubject: BehaviorSubject<Address> = new BehaviorSubject(undefined)
    private propertiesSubject: BehaviorSubject<Array<OtherBankAccountProperty>> = new BehaviorSubject(undefined)
    private initialProps: Array<OtherBankAccountProperty>
    private transferwiseBankAddressSubject: BehaviorSubject<Address> = new BehaviorSubject(undefined)

    readonly countryName: string = 'United States of America'
    readonly currencyName: string = 'US Dollar'
    readonly prefillFirstCenturyBankAddress: Address = {
        city: 'Bluefield',
        country: 'United States of America',
        postalCode: '24701',
        state: 'West Virginia',
        street1: '500 Federal St',
    }
    readonly prefillTransferwiseAddress: Address = {
        city: 'New York',
        country: 'United States of America',
        postalCode: '10010',
        state: 'New York',
        street1: '19 W 24th Street',
    }
    readonly prefills: { [type: string]: string | Observable<Address> } = {}

    paymentAccount: AdminPaymentAccount
    properties$: Observable<Array<OtherBankAccountProperty>> = this.propertiesSubject.asObservable()

    @Input() biz: AdminBusinessDetail
    @Input() enabled: boolean

    @Output() add: EventEmitter<AdminPaymentAccount> = new EventEmitter()

    @ViewChild(CountryBankAccountConfigComponent) config: CountryBankAccountConfigComponent

    constructor(
        private addresses: AddressService,
        private currencies: CurrencyService,
        private businesses: BusinessService,
        private dialogs: DialogService,
    ) { }

    ngOnInit(): void {
        const bizUserProfile: AdminProfile = this.biz?.users[0]?.profile
        if (bizUserProfile) {
            this.prefills[PrefillType.ACCOUNT_HOLDER_NAME] = `${bizUserProfile.firstName} ${bizUserProfile.lastName}`
        }
        this.prefills[PrefillType.TRANSFERWISE_ADDRESS] = this.transferwiseBankAddressSubject.asObservable()
        this.prefills[PrefillType.FIRSTCENTURY_ADDRESS] = this.firstCenturyBankAddressSubject.asObservable()

        this.addresses.initialize()
            .pipe(
                tap(() => {
                    const countryIndex: number = this.addresses.countryNames.findIndex(x => x.value === this.addresses.usa)
                    const usaId: string = this.addresses.countries[countryIndex].value

                    this.currencies.get(usaId)
                        .pipe(
                            tap(accountInfo => {
                                this.countryBankAccountInfo = accountInfo
                            }),
                            tap(() => {
                                this.setProperties()

                                if (this.prefills[PrefillType.ACCOUNT_HOLDER_NAME]) {
                                    const accHolderPropIndex: number = this.initialProps.findIndex(x =>
                                        x.countryBankAccountProperty.prefillType === PrefillType.ACCOUNT_HOLDER_NAME)
                                    this.initialProps[accHolderPropIndex].value = <string>this.prefills[PrefillType.ACCOUNT_HOLDER_NAME]
                                }

                                this.transferwiseBankAddressSubject.next(this.prefillTransferwiseAddress)
                                this.firstCenturyBankAddressSubject.next(this.prefillFirstCenturyBankAddress)

                                let bizAddressPropIndex: number = this.initialProps.findIndex(x =>
                                    x.countryBankAccountProperty.prefillType === PrefillType.TRANSFERWISE_ADDRESS)
                                if (bizAddressPropIndex !== -1) {
                                    this.initialProps[bizAddressPropIndex].value = JSON.stringify(this.prefillTransferwiseAddress)
                                    return
                                }

                                bizAddressPropIndex = this.initialProps.findIndex(x =>
                                    x.countryBankAccountProperty.prefillType === PrefillType.FIRSTCENTURY_ADDRESS)
                                if (bizAddressPropIndex !== -1) {
                                    this.initialProps[bizAddressPropIndex].value = JSON.stringify(this.prefillFirstCenturyBankAddress)
                                }
                            }),
                        )
                        .subscribe()
                }),
            )
            .subscribe()
    }

    setProperties(): void {
        this.paymentAccount = undefined
        const props: Array<OtherBankAccountProperty> = this.countryBankAccountInfo.properties.map(prop => ({ countryBankAccountProperty: prop, value: undefined }))
        this.initialProps = props
        this.propertiesSubject.next(props)
    }

    saveManualUSBankAccount(request: OtherBankAccountRequest): void {

        this.propertiesSubject.next(request.properties)

        request.countryId = this.countryBankAccountInfo.country.id
        request.currencyId = this.countryBankAccountInfo.country.currencies?.[0]?.currency?.id
        request.organizationId = this.biz?.id

        this.businesses.createManualUSBankAccount(request)
            .pipe(
                tap(paymentAccount => {
                    this.paymentAccount = paymentAccount
                    this.add.emit(paymentAccount)
                }),
                catchError(error => this.dialogs.error(error)),
            )
            .subscribe()
    }

    resetManualUSBankAccount(): void {
        this.config.form.reset()
        this.transferwiseBankAddressSubject.next(this.prefillTransferwiseAddress)
        const accountHolderPrefillProp: OtherBankAccountProperty = this.initialProps.find(x => x.countryBankAccountProperty.prefillType === PrefillType.ACCOUNT_HOLDER_NAME)
        this.config.resetPrefillProperty(accountHolderPrefillProp)
        this.config.restoreCountryInAddressForms()
        this.config.form.markAsPristine()
    }

    onSaveManualUSBankAccount(): void {
        this.config.form.markAsPristine()
        this.config.onSave()
    }

}
