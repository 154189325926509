<h3>Invoice Payments Pending Review</h3>
<app-table
    [id]="tableId"
    class="data-table"
    [noStick]="true"
    [columns]="columns"
    [length]="reviewLength"
    [defaultSort]="{ active: 'created', direction: 'desc' }"
    [data$]="reviewRows$"
    (rowClick)="onRowClick($event)"
    [customPaging]="true"
    [persistPage]="true"
    (pageChange)="onPageChange($event)"
    (checkButtonClick)="onCheckButtonClick($event)"
    readonlyModeContainerClasses="overflow-x-scroll"
></app-table>
<h3>Invoice Payments Scheduled</h3>
<app-table
    [id]="scheduledTableId"
    class="data-table"
    [noStick]="true"
    [columns]="columns"
    [length]="scheduledLength"
    [defaultSort]="{ active: 'created', direction: 'desc' }"
    [data$]="scheduledRows$"
    (rowClick)="onRowClick($event)"
    [customPaging]="true"
    [persistPage]="true"
    (pageChange)="onPageChange($event, true)"
    (checkButtonClick)="onCheckButtonClick($event, true)"
    readonlyModeContainerClasses="overflow-x-scroll"
></app-table>