import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ErrorStateMatcher } from '@angular/material/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { tap } from 'rxjs/operators'

import { Address, AddressFactory, AddressFormComponent } from '../address'
import { BeneficialOwner, BeneficialOwnerRequest, TableId } from '../core/models'
import { BusinessService } from '../core/services'
import { TaxIdType } from '../tax-id-field/tax-id-type.enum'
import { DirtyOrTouchedErrorStateMatcher, TaxIdValidator } from '../validation'

@Component({
    selector: 'app-beneficial-owner-form',
    templateUrl: './beneficial-owner-form.component.html',
    styleUrls: ['./beneficial-owner-form.component.scss'],
})
export class BeneficialOwnerFormComponent implements OnInit {

    private readonly addressSubject: BehaviorSubject<Address> = new BehaviorSubject<Address>(undefined)

    @ViewChild(AddressFormComponent) addressFormComponent: AddressFormComponent

    @Output() add: EventEmitter<BeneficialOwner> = new EventEmitter()
    @Output() cancel: EventEmitter<void> = new EventEmitter()
    @Output() update: EventEmitter<BeneficialOwner> = new EventEmitter()

    @Input() businessId: string
    @Input() owner: BeneficialOwner

    readonly address$: Observable<Address> = this.addressSubject.asObservable()
    readonly beneficialOwnersLogsId: string = TableId.beneficialOwnersLogs
    createdOwner: BeneficialOwner
    createOwnerForm: FormGroup
    createOwnerMessage: FormControl = new FormControl(undefined)
    readonly errorMatcher: ErrorStateMatcher = new DirtyOrTouchedErrorStateMatcher()
    firstName: FormControl = new FormControl(undefined)
    isEdit: boolean
    lastName: FormControl = new FormControl(undefined)
    primaryLabel: string
    secondaryLabel: string
    taxId: FormControl = new FormControl(undefined, [Validators.required, TaxIdValidator])
    taxIdType: TaxIdType = TaxIdType.Ssn
    title: string

    constructor(
        private addressFactory: AddressFactory,
        private business: BusinessService,
    ) {
        this.createOwnerForm = new FormGroup({
            firstName: this.firstName,
            lastName: this.lastName,
            ssn: this.taxId,
            createOwnerMessage: this.createOwnerMessage,
        })
    }

    ngOnInit(): void {
        this.resetCreateOwner()
        this.isEdit = !!this.owner
        this.primaryLabel = this.isEdit ? 'Update' : 'Add'
        this.title = this.isEdit ? 'Edit Beneficial Owner' : 'Add Beneficial Owner'
        this.secondaryLabel = this.isEdit ? 'Cancel' : 'Reset'
    }

    submitCreateOwner(): void {

        if (this.createOwnerForm.invalid) {
            return
        }

        const createOwnerRequest: BeneficialOwnerRequest = this.createBeneficialOwnerRequest()

        this.business.createOwner(createOwnerRequest)
            .pipe(
                tap(owner => {
                    this.add.emit(owner)
                    this.resetCreateOwner(true)
                }),
            )
            .subscribe()
    }

    submitUpdateOwner(): void {

        if (this.createOwnerForm.invalid) {
            return
        }

        const updateOwnerRequest: BeneficialOwnerRequest = this.createBeneficialOwnerRequest()

        this.business.updateOwner(updateOwnerRequest)
            .pipe(
                tap(owner => this.update.emit(owner)),
            )
            .subscribe()
    }

    resetCreateOwner(resetFrom: boolean = false): void {

        if (this.isEdit) {
            this.cancel.emit()
            return
        }

        if (resetFrom) {
            this.createOwnerForm.reset()
            return
        }

        this.addressSubject.next(this.owner?.address)

        this.firstName.setValue(this.owner?.firstName)
        this.lastName.setValue(this.owner?.lastName)
        this.createOwnerMessage.setValue(undefined)
        this.taxId.setValue(this.owner?.maskedTaxId)
        this.createOwnerForm.markAsPristine()
    }

    private createBeneficialOwnerRequest(): BeneficialOwnerRequest {
        return {
            ...this.addressFactory.createRequest(this.addressFormComponent.address, this.businessId, 'Beneficial Owner'),
            beneficialOwnerId: this.owner?.id,
            firstName: this.firstName.value,
            lastName: this.lastName.value,
            message: this.createOwnerMessage.value,
            organizationId: this.businessId,
            socialSecurityNumber: this.taxId.value?.startsWith('X') ? 'ssnnochng' : this.taxId.value,
        }
    }
}
