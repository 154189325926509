import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { AdminBusiness, PaymentStatus } from '../models'

import { UrlService } from './url.service'

@Injectable({
    providedIn: 'root',
})
export class SiteStatusStore {

    constructor(
        private http: HttpClient,
        private urls: UrlService,
    ) { }

    set(turnOn: boolean, startsOn: number, endsOn: number): Observable<any> {
        const request: any = {
            turnOn,
            startsOn,
            endsOn,
        }

        return this.http.post<string>(this.urls.api.maintenancePage(), request)
    }
}
