<h6
    *ngIf="bank"
    mat-dialog-title
>Create Intermediary Bank For {{bank.name}}<br>
    <b>({{bank.swiftCode}})</b>
</h6>
<mat-dialog-content
    class="dialog-with-desc"
    *ngIf="bank"
>
    <div [formGroup]="form">
        <div class="column">
            <mat-form-field>
                <mat-label>Intermediary SWIFT</mat-label>
                <input
                    matInput
                    formControlName="intermediarySwift"
                    autocomplete="off"
                    (input)="onKeyUp($event)"
                    (keyup.enter)="lookupIntermediarySwift()"
                >
            </mat-form-field>
        </div>
        <div class="column">
            <mat-form-field>
                <mat-label>For Currency</mat-label>
                <mat-select
                    *ngIf="currencyCodes"
                    formControlName="currency"
                    [(value)]="selectedCurrency"
                >
                    <mat-option
                        *ngFor="let option of currencyCodes"
                        [value]="option"
                    >
                        {{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="column">
            <mat-form-field>
                <mat-label>For Country</mat-label>
                <mat-select
                    *ngIf="countries"
                    formControlName="country"
                    [(value)]="selectedCountryId"
                >
                    <mat-option
                        *ngFor="let option of countries"
                        [value]="option.id"
                    >
                        {{option.displayName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div
            class="column"
            *ngIf="intermediaryBank"
        >
            <b>Intermediary Bank Found: <br>
                {{intermediaryBank.name}}<br></b>
            <ng-container *ngIf="intermediaryBank.branch">BRANCH: {{intermediaryBank.branch}}<br></ng-container>
            <ng-container *ngIf="intermediaryBank.address1">{{intermediaryBank.address1}}<br></ng-container>
            <ng-container *ngIf="intermediaryBank.address2">{{intermediaryBank.address2}}<br></ng-container>
            <ng-container *ngIf="intermediaryBank.city">{{intermediaryBank.city}}</ng-container>
            <ng-container *ngIf="intermediaryBank.postalCode">, {{intermediaryBank.postalCode}}</ng-container>
            <ng-container *ngIf="intermediaryBank.countryName"><br>{{intermediaryBank.countryName}}</ng-container>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="button-container">
        <button
            mat-stroked-button
            mat-dialog-close
        >
            Cancel
        </button>
        <button
            *ngIf="intermediaryBank"
            mat-flat-button
            color="primary"
            [disabled]="!submitEnabled()"
            (click)="submit()"
        >
            Create Intermediary Association
        </button>
        <button
            *ngIf="!intermediaryBank"
            mat-flat-button
            color="primary"
            (click)="lookupIntermediarySwift()"
        >
            Lookup Intermediary SWIFT
        </button>
    </div>
</mat-dialog-actions>