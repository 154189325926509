import { Component, Inject } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { tap } from 'rxjs/operators'

import { UnmaskDialogComponent } from '../../business/unmask-dialog/unmask-dialog.component'
import { SelectOption, Tab } from '../../core/models'
import { MicrodepositVerificationRequest } from '../../core/models/microdeposit-verification-request.interface'
import { BusinessService, TabService, UrlService } from '../../core/services'
import { DialogService } from '../../dialogs'

@Component({
    selector: 'lqd-review-bank-dialog',
    templateUrl: './review-micro-deposit-request-dialog.component.html',
    styleUrls: ['./review-micro-deposit-request-dialog.component.scss'],
})
export class ReviewMicrodepositRequestDialogComponent {

    readonly actionOptions: Array<SelectOption<boolean>> = [
        {
            label: 'Approve',
            value: true,
        },
        {
            label: 'Reject',
            value: false
        },
    ]

    form: FormGroup
    action: FormControl
    microdepositVerificationRequest: MicrodepositVerificationRequest
    unmaskedEin: string

    constructor(
        private businesses: BusinessService,
        private dialogsService: DialogService,
        @Inject(MAT_DIALOG_DATA) private dialogData: {
            microdepositVerificationRequest: MicrodepositVerificationRequest
            unmaskedEin: string
        },
        private dialogRef: MatDialogRef<ReviewMicrodepositRequestDialogComponent>,
        private readonly tabService: TabService,
        private readonly urls: UrlService,
    ) {
        this.action = new FormControl(undefined, Validators.required)
        this.form = new FormGroup({
            'action': this.action,
        })
        this.microdepositVerificationRequest = dialogData.microdepositVerificationRequest
        this.unmaskedEin = dialogData.unmaskedEin
    }

    maskUnmaskEin() {
        if (!this.unmaskedEin) {

            if (this.dialogData.unmaskedEin) {
                this.unmaskedEin = this.dialogData.unmaskedEin;
                return;
            }

            // Unmask
            const dialogConfig: MatDialogConfig = {
                data: {
                    biz: {
                        id: this.microdepositVerificationRequest.businessOrganizationId
                    }
                },
            }

            this.dialogsService.open(UnmaskDialogComponent, dialogConfig)
                .afterClosed()
                .pipe(
                    tap((unmaskedEin: string) => {
                        this.dialogsService.open(ReviewMicrodepositRequestDialogComponent, {
                            data: {
                                microdepositVerificationRequest: this.microdepositVerificationRequest,
                                unmaskedEin: unmaskedEin
                            }
                        })
                    }),
                )
                .subscribe()

        } else {
            this.unmaskedEin = undefined
        }
    }

    openBusinessTab(): void {
        const tab: Tab = {
            label: `${this.microdepositVerificationRequest.businessName}`,
            removable: true,
            route: this.urls.route.businessDetails(this.microdepositVerificationRequest.businessOrganizationId),
        }
        this.tabService.addTab(tab)
        this.dialogRef.close(undefined)
    }

    submitAcceptance(): void {
        const decision: boolean = <boolean>this.action.value
        this.businesses.setMicrodepositVerificationRequestDecision(this.microdepositVerificationRequest.id, decision)
            .pipe(
                tap(r => {
                    this.dialogRef.close(decision)
                })
            ).subscribe();
    }

}
