import { Component, OnInit } from '@angular/core'
import { MatDialogConfig } from '@angular/material/dialog'
import { PageEvent } from '@angular/material/paginator'
import { Sort } from '@angular/material/sort'
import { Router } from '@angular/router'
import { BehaviorSubject, Observable } from 'rxjs'
import { take, tap } from 'rxjs/operators'

import { AdminBusiness, AdminBusinessRow, Tab, TableId } from '../core/models'
import { BusinessService, TabService, UrlService } from '../core/services'
import { DialogService } from '../dialogs'
import { ListField, PageRequest } from '../table/models'

import { AddPartialBusinessDialogComponent } from './add-partial-business-dialog/add-partial-business-dialog.component'

@Component({
    selector: 'lqd-businesses',
    templateUrl: './businesses.component.html',
    styleUrls: ['./businesses.component.scss'],
})
export class BusinessesComponent implements OnInit {

    private results: BehaviorSubject<AdminBusinessRow[]> = new BehaviorSubject([])

    readonly columns: ListField[] = [
        {
            id: 'dateCreated',
            label: 'Date Created',
            type: 'date',
        },
        {
            id: 'id',
            label: 'Biz Id',
        },
        {
            id: 'name',
            label: 'Biz Name',
        },
        {
            id: 'businessType',
            label: 'Client/Vendor',
            notSortable: true,
        },
        {
            id: 'kycStatus',
            label: 'KYC Status',
        },
        {
            id: 'paymentStatus',
            label: 'Payment Status',
        },
    ]
    length: number
    readonly rows$: Observable<Array<AdminBusinessRow>> = this.results.asObservable()
    readonly tableId: string = TableId.dashboardBusinesses
    lastPageEvent: PageEvent
    lastSort: Sort

    constructor(
        private business: BusinessService,
        private dialogs: DialogService,
        private tabService: TabService,
        private urls: UrlService,
    ) { }

    ngOnInit(): void {

    }

    onRowClick(business: AdminBusiness): void {
        const tab: Tab = {
            label: business.name,
            removable: true,
            route: this.urls.route.businessDetails(business.id),
        }
        this.tabService.addTab(tab)
    }

    onPageChange(event: { page: PageEvent, sort: Sort }): void {
        if (!event?.page?.pageSize || event?.page?.pageIndex < 0 || !event?.sort?.active || !event?.sort?.direction) {
            return
        }

        const page: PageEvent = event.page
        const sort: Sort = event.sort

        this.lastPageEvent = page
        this.lastSort = sort

        const pageRequest: PageRequest = {
            page: page.pageIndex + 1,
            pageSize: page.pageSize,
            sortBy: `${sort.active} ${sort.direction}`,
        }

        this.executePageRequest(pageRequest)
    }

    private executePageRequest(pageRequest: PageRequest): void {
        this.business.get(pageRequest)
            .pipe(
                take(1),
                tap(data => {
                    this.length = data.rowCount
                    const rows: AdminBusinessRow[] = data.results.map(biz => {
                        return {
                            ...biz,
                            kycStatus: biz.kycStatus || 'NA',
                            paymentStatus: biz.paymentStatus || 'NA',
                        }
                    })
                    this.results.next(rows)
                }),
            )
            .subscribe()
    }

    addPartial(): void {
        const dialogConfig: MatDialogConfig = {
            data: {},
        }

        this.dialogs.open(AddPartialBusinessDialogComponent, dialogConfig)
            .afterClosed()
            .pipe(
                tap(() => {
                    const pageRequest: PageRequest = {
                        page: this.lastPageEvent.pageIndex + 1,
                        pageSize: this.lastPageEvent.pageSize,
                        sortBy: `${this.lastSort.active} ${this.lastSort.direction}`,
                    }
                    this.executePageRequest(pageRequest)
                }),
            )
            .subscribe()
    }

}
