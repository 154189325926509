<div class="header">
    <button
        type="button"
        class="x-button"
        mat-icon-button
        mat-dialog-close
    >
        <i-feather name="x">
        </i-feather>
    </button>
    <h5>Review Bank Account</h5>
</div>
<mat-dialog-content class="dialog-with-desc">
    <h5>Payment Account Details</h5>
    <p><strong>Created By:</strong> {{ paymentAccount?.createdBy }}</p>
    <p><strong>Country:</strong>: {{ paymentAccount?.country}}</p>
    <p><strong>Currency:</strong> {{ paymentAccount?.currency }}</p>
    <p><strong>Account Status:</strong> {{ paymentAccount?.status }}</p>
    <lqd-other-bank-account-details [details]="details"></lqd-other-bank-account-details>
    <h5 *ngIf="!!bankDetailsFromDb">Matched Bank</h5>
    <div *ngIf="!!bankDetailsFromDb">
        <p><strong>Bank Name:</strong> {{ bankDetailsFromDb.name }}</p>
        <p><strong>Bank Branch:</strong> {{ bankDetailsFromDb.branch }}</p>
        <p><strong>Bank Address:</strong> {{ bankFullAddress }}</p>
        <p *ngIf="!bankHasUSIntermediary"><strong style="color: orange">Bank does not have US Intermediary! </strong><a
                (click)="popAddIntermediary()"
            >(Add)</a></p>
        <p *ngIf="bankHasUSIntermediary"><strong style="color: green">Bank has US Intermediary set. </strong></p>
    </div>
    <div *ngIf="!bankDetailsFromDb">
        <p style="color: red"><strong>Bank does not match any Liquid DB Bank</strong></p>
    </div>
    <div class='column'>
        <mat-form-field>
            <mat-label>Action</mat-label>
            <mat-select [formControl]="action">
                <mat-option
                    *ngFor="let option of actionOptions"
                    [value]="option.value"
                >
                    {{option.label}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

</mat-dialog-content>
<div class="button-row">
    <button
        mat-flat-button
        color="primary"
        [disabled]="form.invalid"
        (click)="submitAcceptance()"
    >Submit</button>
</div>