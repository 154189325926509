import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { catchError, take } from 'rxjs/operators'

import { DialogService } from '../../dialogs'
import { AdminBusiness, KycStatus } from '../models'

import { KycStatusStore } from './kyc-status.store'

@Injectable({
    providedIn: 'root',
})
export class KycStatusService {

    constructor(
        private dialogs: DialogService,
        private store: KycStatusStore,
    ) { }

    get(): Observable<Array<KycStatus>> {
        return this.store.get()
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    preview(businessId: string, status: KycStatus, message: string): Observable<AdminBusiness> {
        return this.store.preview(businessId, status?.id, message)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    set(businessId: string, status: KycStatus, message: string): Observable<AdminBusiness> {
        return this.store.set(businessId, status?.id, message)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }
}
