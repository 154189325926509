
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FeatherModule } from 'angular-feather'

import { AddressModule } from '../address'
import { DialogModule } from '../dialogs'
import { SharedModule } from '../shared/shared.module'
import { TableModule } from '../table/table.module'

import { BanksReviewComponent } from './banks-review/banks-review.component'
import { ForeignAccountsComponent } from './foreign-accounts/foreign-accounts.component'
import { LiquidAccountsComponent } from './liquid-accounts/liquid-accounts.component'
import { OtherBankAccountDetailsComponent } from './other-bank-account-details/other-bank-account-details.component'
import { ReviewBankDialogComponent } from './review-bank-dialog/review-bank-dialog.component'
import { VerifyDialogComponent } from './verify-dialog/verify-dialog.component';
import { MicrodepositRequestsReviewComponent } from './review-micro-deposit-requests-review/micro-deposits-requests-review.component'
import { ReviewMicrodepositRequestDialogComponent } from './review-micro-deposit-request-dialog/review-micro-deposit-request-dialog.component'
import { MatTooltipModule } from '@angular/material/tooltip'

@NgModule({
    declarations: [
        BanksReviewComponent,
        ForeignAccountsComponent,
        LiquidAccountsComponent,
        OtherBankAccountDetailsComponent,
        VerifyDialogComponent,
        ReviewBankDialogComponent,
        ReviewMicrodepositRequestDialogComponent,
        MicrodepositRequestsReviewComponent
    ],
    imports: [
        AddressModule,
        CommonModule,
        DialogModule,
        FeatherModule,
        MatTooltipModule,
        SharedModule,
        TableModule,
    ],
    exports: [
        BanksReviewComponent,
        ForeignAccountsComponent,
        LiquidAccountsComponent,
        MicrodepositRequestsReviewComponent,
        OtherBankAccountDetailsComponent,
        VerifyDialogComponent,
    ],
})
export class UnverifiedAccountsModule { }
