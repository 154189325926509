<h6 mat-dialog-title>{{title}}</h6>
<mat-dialog-content class="dialog-with-desc">
    <p class="dialog-desc">{{description}}</p>
    <p> <strong>Created:</strong> {{ created | date }}</p>
    <p><strong>Invoice #:</strong> {{ invoiceNumber }}</p>
    <p><strong>Invoice Amount:</strong> {{ invoiceAmount | number:'1.2-2' }} {{invoiceCurrency}}</p>
    <p><strong>Payout Amount:</strong> {{ payoutAmount | number:'1.2-2' }} {{payoutCurrency}} <ng-container
            *ngIf="payoutTotalLocked"
        >
            (Stable Currency Locked)</ng-container>
    </p>
    <p><strong>Client:</strong>: {{ client }}</p>
    <p><strong>Vendor:</strong> {{ vendor }}</p>
    <p *ngIf="!!workOrderName"><strong>Work Order:</strong> {{ workOrderName }}</p>
</mat-dialog-content>

<mat-form-field>
    <mat-label>Action</mat-label>
    <mat-select [formControl]="action">
        <mat-option
            *ngFor="let option of actionOptions"
            [value]="option.value"
        >
            {{option.label}}
        </mat-option>
    </mat-select>
</mat-form-field>

<mat-form-field>
    <mat-label>Message</mat-label>
    <textarea
        matInput
        required
        [formControl]="message"
    ></textarea>
</mat-form-field>

<mat-dialog-actions>
    <div class="button-container">
        <button
            *ngIf="!disableNoButton"
            mat-stroked-button
            mat-dialog-close
        >
            {{noButtonText}}
        </button>
        <button
            mat-flat-button
            color="primary"
            [disabled]="form.invalid"
            (click)="submitReviewAction()"
        >
            {{yesButtonText}}
        </button>
    </div>
</mat-dialog-actions>