import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatTooltipModule } from '@angular/material/tooltip'
import { FeatherModule } from 'angular-feather'

import { AddressModule } from '../address'
import { TaxIdFieldModule } from '../tax-id-field'

import { CountryBankAccountConfigComponent } from './country-bank-account-config.component'

@NgModule({
    declarations: [
        CountryBankAccountConfigComponent,
    ],
    exports: [
        CountryBankAccountConfigComponent,
    ],
    imports: [
        AddressModule,
        CommonModule,
        FeatherModule,
        FormsModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTooltipModule,
        ReactiveFormsModule,
        TaxIdFieldModule,
    ],
})
export class CountryBankAccountConfigModule { }
