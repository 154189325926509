import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MatDialogConfig } from '@angular/material/dialog'
import { ActivatedRoute } from '@angular/router'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { catchError, filter, switchMap, take, takeUntil, tap } from 'rxjs/operators'

import { AdminClientInvoiceDetails, Charge, PaymentAccountDto, PaymentAccountStatus, Tab, TableId } from '../core/models'
import { ClientInvoiceService, PaymentAccountService, TabService, UrlService } from '../core/services'
import { DialogService } from '../dialogs'
import { ReviewDialogComponent } from '../review-client-invoices/review-dialog/review-dialog.component'
import { ListField } from '../table/models'
import { VerifyAccountService } from '../unverified-accounts/services/verify-account.service'

@Component({
    selector: 'lqd-client-invoice',
    templateUrl: './client-invoice.component.html',
    styleUrls: ['./client-invoice.component.scss'],
})
export class ClientInvoiceComponent implements OnDestroy, OnInit {

    cancellablePayoutId: string = undefined
    cancellablePayoutE2EId: string = undefined

    private charges: BehaviorSubject<Charge[]> = new BehaviorSubject([])
    private unsubscribe$: Subject<void> = new Subject<void>()

    readonly columns: ListField[] = [
        {
            id: 'chargeType',
            label: 'Charge Type',
        },
        {
            id: 'amount',
            label: 'Amount',
            type: 'money',
        },
        {
            id: 'amountRefunded',
            label: 'Amount Refunded',
            type: 'money',
        },
        {
            id: 'pendingCompletion',
            label: 'Pending Completion',
        },
        {
            id: 'description',
            label: 'Description',
        },
    ]

    resendForm: FormGroup
    bypassPayout: FormControl
    inReview: boolean
    invoiceDetails: AdminClientInvoiceDetails
    isPendingVendorBankVerification: boolean = false
    isRejectedVendorBank: boolean = false
    paymentRequestType: string = 'N/A'
    payoutRequestType: string = 'N/A'
    readonly rows$: Observable<Array<Charge>> = this.charges.asObservable()
    showResendOptions: boolean = false
    readonly tableId: string = TableId.charges
    vendorPayoutAccount: PaymentAccountDto

    constructor(
        private readonly dialogs: DialogService,
        private readonly invSvc: ClientInvoiceService,
        private readonly paymentAccounts: PaymentAccountService,
        private readonly route: ActivatedRoute,
        private readonly tabService: TabService,
        private readonly urls: UrlService,
        private readonly verifyAccounts: VerifyAccountService,
    ) { }

    ngOnInit(): void {
        this.route.params
            .pipe(
                takeUntil(this.unsubscribe$),
                tap(() => this.loadInvoice()),
            )
            .subscribe()
        this.bypassPayout = new FormControl(false)
        this.resendForm = new FormGroup({
            bypassPayout: this.bypassPayout,
        })
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next()
        this.unsubscribe$.complete()
    }

    popReviewDialog(): void {
        const dialogConfig: MatDialogConfig = new MatDialogConfig()
        dialogConfig.data = {
            description: '',
            title: 'Transaction Review',
            yesButtonText: 'Submit',
            noButtonText: 'Cancel',
            invoiceId: this.invoiceDetails.invoiceId,
            invoiceNumber: this.invoiceDetails.invoiceNumber,
            client: this.invoiceDetails.client,
            vendor: this.invoiceDetails.vendor,
            amount: this.invoiceDetails.amount,
            created: this.invoiceDetails.created,
            workOrderName: this.invoiceDetails.associatedWorkOrders[0]?.name,
        }
        this.dialogs.open(ReviewDialogComponent, dialogConfig)
            .afterClosed()
            .pipe(
                take(1),
                switchMap(() => this.invSvc.getInvoiceDetails(this.route.snapshot.params.invoiceId)),
                tap(transaction => {
                    this.invoiceDetails = transaction
                    this.inReview = this.invoiceDetails.scheduledPayments.some(p => p.inReview)
                    this.charges.next(this.invoiceDetails.charges)
                }),
            )
            .subscribe()
    }

    openVerifyVendorAccountDialog(): void {
        this.verifyAccounts.verifyAccount(this.vendorPayoutAccount)
            .pipe(
                tap(account => this.setVendorPayoutInfo(account)),
                catchError(error => this.dialogs.error(error)),
            )
            .subscribe()
    }

    addTimezone(date: string): string {
        return date.endsWith('Z') ? date : date.concat('Z')
    }

    navigateToBiz(isClient: boolean): void {
        let tab: Tab
        if (isClient) {
            tab = {
                label: this.invoiceDetails.client,
                removable: true,
                route: this.urls.route.businessDetails(this.invoiceDetails.clientOrganizationId),
            }
        } else {
            tab = {
                label: this.invoiceDetails.vendor,
                removable: true,
                route: this.urls.route.businessDetails(this.invoiceDetails.vendorOrganizationId),
            }
        }
        this.tabService.addTab(tab)
    }

    resendPayment(): void {
        this.dialogs.confirm('Are you sure you want to re-send this payment?', 'Yes',
            `Re-send payment for invoice ${this.invoiceDetails.invoiceNumber}`, 'Cancel')
            .pipe(
                filter(v => !!v),
                switchMap(() => this.invSvc.resendPayment(this.invoiceDetails.invoiceId, this.bypassPayout.value)),
                tap(r => {
                    this.showResendOptions = false
                    this.bypassPayout.setValue(false)
                    this.dialogs.inform('Re-send payment complete!')
                        .pipe(
                            tap(() => {
                                this.loadInvoice()
                            }),
                        )
                }),
            )
            .subscribe()
    }

    resendPayout(): void {
        this.dialogs.confirm('Are you sure you want to re-send this payout?', 'Yes',
            `Re-send payout for invoice ${this.invoiceDetails.invoiceNumber}`, 'Cancel')
            .pipe(
                filter(v => !!v),
                switchMap(() => this.invSvc.resendPayout(this.invoiceDetails.invoiceId)),
                tap(r => {
                    this.loadInvoice()
                }),
            )
            .subscribe()
    }

    private loadInvoice(): void {
        this.invoiceDetails = undefined
        this.invSvc.getInvoiceDetails(this.route.snapshot.params.invoiceId)
            .pipe(
                tap(transaction => {
                    this.invoiceDetails = {
                        ...transaction,
                        adminLogs: transaction.adminLogs.map(log => {
                            return {
                                ...log,
                                requestedBy: `${log.requestedByLiquidProfile.firstName} ${log.requestedByLiquidProfile.lastName}`,
                            }
                        }),
                        jpmTransactionDetails: transaction.jpmTransactionDetails.map(jpmTransaction => {
                            return {
                                ...jpmTransaction,
                                payoutAmount: transaction.payoutCurrency.toLowerCase() === transaction.invoiceCurrency.toLowerCase()
                                    ? '-' : `${transaction.payoutAmount} ${transaction.payoutCurrency}`,
                            }
                        }),
                    }
                    this.inReview = this.invoiceDetails.scheduledPayments.some(p => p.inReview)
                    this.setPaymentTransactionType()
                    this.setPayoutTransactionType()
                    this.getAndSetCancellablePayout()
                    this.charges.next(this.invoiceDetails.charges)
                }),
                // if the vendor's bank needs to be verified, set the vendpor payment account
                switchMap(() => this.paymentAccounts.getLiquidBankAccount(this.invoiceDetails.vendorOrganizationId)),
                tap((account: PaymentAccountDto) => this.setVendorPayoutInfo(account)),
            )
            .subscribe()
    }

    private setVendorPayoutInfo(account: PaymentAccountDto): void {
        this.isPendingVendorBankVerification = account?.currentVerificationStatus === PaymentAccountStatus.InReview
        this.isRejectedVendorBank = account?.currentVerificationStatus === PaymentAccountStatus.Rejected
        this.vendorPayoutAccount = account
    }

    private setPaymentTransactionType(): void {

        if (!this.invoiceDetails?.paymentAndPayoutSummary?.paymentRequestType) {
            return
        }

        switch (this.invoiceDetails.paymentAndPayoutSummary.paymentRequestType.toLowerCase()) {
            case 'manualcharge':
                this.paymentRequestType = 'Manually Marked Paid'
                break
            case 'paymentechcharge':
                this.paymentRequestType = 'Paymentech CC'
                break
            case 'jpmachdebitrequest':
                this.paymentRequestType = 'JPM ACH Debit'
                break
            case 'stripecharge':
                this.paymentRequestType = 'Stripe CC'
                break
            default:
                this.paymentRequestType = this.invoiceDetails.paymentAndPayoutSummary.paymentRequestType
                break
        }
    }

    private setPayoutTransactionType(): void {
        if (!this.invoiceDetails?.paymentAndPayoutSummary?.payoutRequestType) {
            return
        }
        switch (this.invoiceDetails.paymentAndPayoutSummary.payoutRequestType.toLowerCase()) {
            case 'jpmachpaymentrequest':
                this.payoutRequestType = 'JPM ACH'
                break
            case 'jpmwirepaymentrequest':
                this.payoutRequestType = 'JPM Wire'
                break
            default:
                this.payoutRequestType = this.invoiceDetails.paymentAndPayoutSummary.payoutRequestType
                break
        }
    }

    cancelPayout(): void {
        if (!!this.cancellablePayoutId) {
            this.dialogs.confirm(`Are you sure you want to cancel payout ${this.cancellablePayoutE2EId}?`, 'Yes',
                `Cancel payout for invoice ${this.cancellablePayoutE2EId}`, 'Cancel')
                .pipe(
                    filter(v => !!v),
                    switchMap(() => this.invSvc.cancelPayout(this.cancellablePayoutId)),
                    tap(r => {
                        this.cancellablePayoutE2EId = undefined
                        this.cancellablePayoutId = undefined
                        this.loadInvoice()
                    }),
                )
                .subscribe()
        }
    }

    private getAndSetCancellablePayout(): void {
        if (!!this.invoiceDetails && !!this.invoiceDetails.payouts && this.invoiceDetails.payouts.length > 0) {
            let foundPaymentRequest: any
            this.invoiceDetails.payouts.forEach(p => {
                if (!!p.bankAccountTransfer) {
                    if (!!p.bankAccountTransfer.jpmWirePaymentRequest && !p.bankAccountTransfer.jpmWirePaymentRequest.responseReceivedOn) {
                        foundPaymentRequest = p.bankAccountTransfer.jpmWirePaymentRequest
                        this.cancellablePayoutE2EId = foundPaymentRequest.endToEndId
                        this.cancellablePayoutId = p.id
                    } else if (!!p.bankAccountTransfer.jpmAchPaymentRequest && !p.bankAccountTransfer.jpmAchPaymentRequest.responseReceivedOn) {
                        foundPaymentRequest = p.bankAccountTransfer.jpmAchPaymentRequest
                        this.cancellablePayoutE2EId = foundPaymentRequest.endToEndId
                        this.cancellablePayoutId = p.id
                    }
                }
            })
        }
    }
}
