import { Injectable } from '@angular/core'

import { OrganizationRole, OrganizationRoleMembers } from '../../auth/models'
import { RolesService } from '../../auth/services'
import { BusinessRoles, BusinessRoute } from '../models'
import { UrlService } from '../services/url.service'

@Injectable({
    providedIn: 'root',
})
export class BusinessRouteFactory {

    constructor(
        private roles: RolesService,
        private urls: UrlService,
    ) { }

    create(businessRole: BusinessRoles, roles: OrganizationRole[]): BusinessRoute {
        const rolesData: { [key: string]: OrganizationRoleMembers } = this.roles.structureRoles(roles)
        return {
            businessName: businessRole.businessName,
            label: `${businessRole.businessName} - ${this.roles.getHighestRoleAssigned(businessRole.roles, rolesData)}`,
            route: this.urls.route.businessDetails(businessRole.businessId),
        }
    }
}
