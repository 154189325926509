<h6 mat-dialog-title>Kyc Document Details</h6>

<mat-dialog-content class="dialog-with-desc">

     <div class="row">
        <div class="column">
            <h5>Id: </h5>
            <p>{{ kycDoc?.id }}</p>
        </div>
        <div class="column">
            <h5>File name: </h5>
            <p>{{ kycDoc.originalFilename }}</p>
        </div>
    </div>

    <div class="row">
        <div class="column">
            <h5>Description: </h5>
            <p>{{ kycDoc?.description }}</p>
        </div>
    </div>

    <div class="row logs">
        <div class="column">
            <lqd-logs
                [logs]="kycDoc?.logs"
            ></lqd-logs>
        </div>
    </div>

</mat-dialog-content>
