import { Injectable } from '@angular/core'
import { Resolve } from '@angular/router'
import { Observable } from 'rxjs'

import { PaymentStatus } from '../models'
import { PaymentStatusService } from '../services'

@Injectable({
    providedIn: 'root',
})
export class PaymentStatusResolver implements Resolve<Array<PaymentStatus>> {

    constructor(
        private paymentStatuses: PaymentStatusService,
    ) { }

    resolve(): Observable<Array<PaymentStatus>> {
        return this.paymentStatuses.get()
    }
}
