import { Component, Inject } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

import { AdminJPMTransactionDetails } from '../../core/models'

@Component({
    selector: 'lqd-jpm-tx-log-dialog',
    templateUrl: './jpm-transaction-log-dialog.component.html',
    styleUrls: ['./jpm-transaction-log-dialog.component.scss'],
})
export class JPMTransactionLogDialogComponent {

    details: AdminJPMTransactionDetails
    narrativeTextObj: any
    dismissable: boolean = false
    dismissalNote: FormControl = new FormControl(undefined)
    form: FormGroup
    constructor(
        private dialogRef: MatDialogRef<JPMTransactionLogDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
    ) {
        if (!data) {
            return
        }

        this.details = data.details
        this.dismissable = data.dismissable
        if (!!data.details?.narrativeTextJson) {
            this.narrativeTextObj = JSON.parse(data.details.narrativeTextJson)
        }

        this.form = new FormGroup({
            'dismissalNote': this.dismissalNote,
        })
    }

    excludeKey(key: string): boolean {
        const excludes: string[] = [
            'dismissedon', 'liquidprofileid', 'evaultkv', 'eiv', 'ekey', 'created', 'updated', 'version', 'id',
            'associatedbyliquidprofile', 'associatedbyliquidprofileid', 'associatedjpmachdebitrequest',
            'associatedjpmachdebitrequestid', 'associatedjpmachpaymentrequest', 'associatedjpmachpaymentrequestid',
            'associatedjpmwirepaymentrequest', 'associatedjpmwirepaymentrequestid',
        ]
        return !!excludes.find(x => x === key.toLowerCase())
    }

    submitDismissal(): void {
        this.dialogRef.close({
            isDismissed: !!this.dismissalNote.value,
            dismissalNote: this.dismissalNote.value,
        })
    }
}
