<h3>Invoices</h3>
<mat-form-field class="full-width search-field">
    <input
        class="search-input"
        matInput
        placeholder="Invoice #, Client or Vendor"
        [formControl]="invoiceSearch"
        autocomplete="off"
        [matAutocomplete]="auto"
    >
    <i-feather
        name="search"
        matSuffix
    ></i-feather>
    <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="searchResultSelected($event)"
        [displayWith]="searchResultDisplayFn"
    >
        <ng-container *ngIf="!isSearching && invoiceSearchResults">
            <mat-optgroup
                *ngFor="let group of invoiceSearchResultGroups"
                [label]="group.groupName"
            >
                <mat-option
                    *ngFor="let result of group.searchResults ; let i = index"
                    [value]="group.searchResults[i]"
                >
                    <div class="result">
                        <span class="name">
                            {{result.clientName}} - Inv {{result.shortName}}
                        </span>
                    </div>
                </mat-option>
            </mat-optgroup>
        </ng-container>
    </mat-autocomplete>
</mat-form-field>

<app-table
    [id]="tableId"
    class="data-table"
    [columns]="columns"
    [length]="length"
    [defaultSort]="{ active: 'created', direction: 'desc' }"
    [data$]="rows$"
    (rowClick)="onRowClick($event)"
    [customPaging]="true"
    [persistPage]="true"
    [defaultPageSize]="100"
    (pageChange)="onPageChange($event)"
    readonlyModeContainerClasses="overflow-x-scroll"
></app-table>