<div
    class="table-container"
    [ngClass]="readonlyModeContainerClasses"
>
    <table
        mat-table
        matSort
        [matSortDisabled]="disableSort"
        [matSortActive]="(sortStartField$ | async)"
        [matSortDirection]="(sortDirection$ | async)"
        [matSortDisableClear]="true"
        [dataSource]="dataTable"
        class="table-container"
    >
        <tr
            mat-header-row
            [ngClass]="{ stuck: !topVisible && !noStick }"
            appIntersectionObserver
            [intersectionThreshold]="[0]"
            (visibilityChange)="onVisibilityChange('header', $event)"
            *matHeaderRowDef="columnIds; sticky: !noStick"
        ></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: columnIds;"
        ></tr>

        <ng-container
            *ngFor="let field of _columns; let i = index"
            [matColumnDef]="field.id"
            [sticky]="i < stickyColumns"
        >

            <ng-container [ngSwitch]="field.type">
                <ng-container *ngSwitchCase="'checkbox'">
                    <th
                        mat-header-cell
                        [ngClass]="getHeaderClass(field)"
                        *matHeaderCellDef
                    >
                        <mat-checkbox
                            (click)="$event.stopPropagation()"
                            (change)="allSelected ? clearAll() : toggleAll()"
                            [checked]="allSelected">
                        </mat-checkbox>
                    </th>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        [start]="sortStart(field)"
                        [disabled]="sortDisabled(field)"
                        [ngClass]="getHeaderClass(field)"
                    >
                        {{field.label}}
                    </th>
                </ng-container>
            </ng-container>

            <ng-container [ngSwitch]="field.type">

                <ng-container *ngSwitchCase="'list'">
                    <td
                        mat-cell
                        *matCellDef="let row"
                        (click)="onRowClick(row)"
                        [ngClass]="cellClass(field,row)"
                        [matTooltipDisabled]="!field.getTooltip"
                        [matTooltip]="!!field.getTooltip ? field.getTooltip(row, field) : undefined"
                    >
                        <span class="subtitle3 label">{{field.label}}</span>
                        <div *ngFor="let item of row[field.id]">{{item}}</div>
                    </td>
                </ng-container>

                <ng-container *ngSwitchCase="'list-nav'">
                    <td
                        mat-cell
                        *matCellDef="let row"
                        [ngClass]="cellClass(field,row)"
                        [matTooltipDisabled]="!field.getTooltip"
                        [matTooltip]="!!field.getTooltip ? field.getTooltip(row, field) : undefined"
                    >
                        <span class="subtitle3 label">{{field.label}}</span>
                        <div *ngFor="let item of row[field.id]">
                            <a (click)="onRowClick(item)">{{item.label}}</a>
                        </div>
                    </td>
                </ng-container>

                <ng-container *ngSwitchCase="'money'">
                    <td
                        mat-cell
                        *matCellDef="let row"
                        (click)="onRowClick(row)"
                        [ngClass]="cellClass(field,row)"
                        [matTooltipDisabled]="!field.getTooltip"
                        [matTooltip]="!!field.getTooltip ? field.getTooltip(row, field) : undefined"
                        class="right-aligned"
                    >
                        <span class="subtitle3 label">{{field.label}}</span>
                        {{row[field.id] | currency }}
                        <!-- TODO: handle other currencies -->
                    </td>
                </ng-container>

                <ng-container *ngSwitchCase="'date'">
                    <td
                        mat-cell
                        *matCellDef="let row"
                        (click)="onRowClick(row)"
                        [ngClass]="cellClass(field,row)"
                        [matTooltipDisabled]="!field.getTooltip"
                        [matTooltip]="!!field.getTooltip ? field.getTooltip(row, field) : undefined"
                    >
                        <span class="subtitle3 label">{{field.label}}</span>
                        {{!!row[field.id] ? (addTimezone(row[field.id]) | date: 'longDate') : '-' }}
                    </td>
                </ng-container>

                <ng-container *ngSwitchCase="'datetime'">
                    <td
                        mat-cell
                        *matCellDef="let row"
                        (click)="onRowClick(row)"
                        [ngClass]="cellClass(field,row)"
                        [matTooltipDisabled]="!field.getTooltip"
                        [matTooltip]="!!field.getTooltip ? field.getTooltip(row, field) : undefined"
                    >
                        <span class="subtitle3 label">{{field.label}}</span>
                        {{!!row[field.id] ? (addTimezone(row[field.id]) | date: 'medium') : '-' }}
                    </td>
                </ng-container>
                <ng-container *ngSwitchCase="'date@time'">
                    <td
                        mat-cell
                        *matCellDef="let row"
                        (click)="onRowClick(row)"
                        [ngClass]="cellClass(field,row)"
                        [matTooltipDisabled]="!field.getTooltip"
                        [matTooltip]="!!field.getTooltip ? field.getTooltip(row, field) : undefined"
                    >
                        <span class="subtitle3 label">{{field.label}}</span>
                        {{!!row[field.id] ? (addTimezone(row[field.id]) | date: 'shortDate') + ' @ ' +
                        (addTimezone(row[field.id]) | date: 'shortTime') : '-' }}
                    </td>
                </ng-container>

                <ng-container *ngSwitchCase="'download'">
                    <td
                        mat-cell
                        *matCellDef="let row"
                        [ngClass]="buttonIndexClass(field)"
                        class="center-aligned button-column"
                    >
                        <button
                            mat-icon-button
                            (click)="onDownload(row)"
                        >
                            <i-feather name="download"></i-feather>
                        </button>
                    </td>
                </ng-container>

                <ng-container *ngSwitchCase="'number'">
                    <td
                        mat-cell
                        *matCellDef="let row"
                        (click)="onRowClick(row)"
                        [ngClass]="cellClass(field,row)"
                        [matTooltipDisabled]="!field.getTooltip"
                        [matTooltip]="!!field.getTooltip ? field.getTooltip(row, field) : undefined"
                        class="right-aligned"
                    >
                        <span class="subtitle3 label">{{field.label}}</span>
                        {{row[field.id]}}
                    </td>
                </ng-container>


                <ng-container *ngSwitchCase="'remove'">
                    <td
                        mat-cell
                        *matCellDef="let row"
                        [ngClass]="buttonIndexClass(field)"
                        class="center-aligned button-column"
                    >
                        <button
                            mat-icon-button
                            (click)="onRemove(row)"
                        >
                            <i-feather name="trash-2"></i-feather>
                        </button>
                    </td>
                </ng-container>

                <ng-container *ngSwitchCase="'status'">
                    <td
                        mat-cell
                        *matCellDef="let row"
                        (click)="onRowClick(row)"
                        [ngClass]="cellClass(field,row)"
                        [matTooltipDisabled]="!field.getTooltip"
                        [matTooltip]="!!field.getTooltip ? field.getTooltip(row, field) : undefined"
                    >
                        <span class="subtitle3 label">{{field.label}}</span>
                        <span [ngClass]="statusClass(row[field.id])">{{row[field.id]}}</span>
                    </td>
                </ng-container>

                <ng-container *ngSwitchCase="'timestamp'">
                    <td
                        mat-cell
                        *matCellDef="let row"
                        (click)="onRowClick(row)"
                        [ngClass]="cellClass(field,row)"
                        [matTooltipDisabled]="!field.getTooltip"
                        [matTooltip]="!!field.getTooltip ? field.getTooltip(row, field) : undefined"
                        class="left-aligned"
                    >
                        <span class="subtitle3 label">{{field.label}}</span>
                        {{!!row[field.id] ? (formatDate(row[field.id]) | date: 'shortDate') : '-'}}
                    </td>
                </ng-container>

                <ng-container *ngSwitchCase="'timestamp-long'">
                    <td
                        mat-cell
                        *matCellDef="let row"
                        (click)="onRowClick(row)"
                        [ngClass]="cellClass(field,row)"
                        [matTooltipDisabled]="!field.getTooltip"
                        [matTooltip]="!!field.getTooltip ? field.getTooltip(row, field) : undefined"
                        class="left-aligned"
                    >
                        <span class="subtitle3 label">{{field.label}}</span>
                        {{!!row[field.id] ? (formatDate(row[field.id]) | date: 'longDate') : '-'}}
                    </td>
                </ng-container>

                <ng-container *ngSwitchCase="'timestamp-long-range'">
                    <td
                        mat-cell
                        *matCellDef="let row"
                        (click)="onRowClick(row)"
                        [ngClass]="cellClass(field,row)"
                        [matTooltipDisabled]="!field.getTooltip"
                        [matTooltip]="!!field.getTooltip ? field.getTooltip(row, field) : undefined"
                        class="left-aligned"
                    >
                        <span class="subtitle3 label">{{field.label}}</span>
                        {{
                        !!row[field.id] && !!row[field.secondId]
                        ? ((formatDate(row[field.id]) | date: 'longDate') + ' - ' + (formatDate(row[field.secondId]) |
                        date: 'longDate'))
                        : '-'
                        }}
                    </td>
                </ng-container>

                <ng-container *ngSwitchCase="'actions'">
                    <td
                        mat-cell
                        *matCellDef="let row"
                        (click)="onRowClick(row)"
                        [ngClass]="buttonIndexClass(field)"
                        class="center-aligned button-column actions"
                    >
                        <button
                            mat-icon-button
                            class="low-contrast"
                            *ngIf="!!row[field.id]?.length && !hideAction(row, field)"
                            (click)="$event.stopPropagation()"
                            [matMenuTriggerFor]="actionsMenu"
                            [matMenuTriggerData]="{ options: row[field.id], row: row }"
                        >
                            <i-feather name="more-horizontal"></i-feather>
                        </button>
                    </td>
                </ng-container>

                <ng-container *ngSwitchCase="'check-button'">
                    <td
                        mat-cell
                        *matCellDef="let row"
                        (click)="onRowClick(row)"
                        [ngClass]="buttonIndexClass(field)"
                        class="center-aligned button-column check-button"
                    >
                        <button
                            mat-icon-button
                            ngClass="low-contrast"
                            (click)="executeCheckToggle($event, row, field)"
                            [disabled]="isCheckButtonDisabled(row, field)"
                            *ngIf="row[field.id] !== undefined && !hideAction(row, field)"
                        >
                            <i-feather
                                [name]="field.checkButtonIcon || 'check'"
                                [ngClass]="getCheckButtonClass(row, field)"
                            ></i-feather>
                        </button>
                    </td>
                </ng-container>

                <ng-container *ngSwitchCase="'checkbox'">
                    <td
                        mat-cell
                        *matCellDef="let row"
                    >
                        <mat-checkbox
                            (click)="$event.stopPropagation()"
                            (change)="toggle(row)"
                            [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>

                <ng-container *ngSwitchCase="'check'">
                    <td
                        mat-cell
                        *matCellDef="let row"
                        (click)="onRowClick(row)"
                        [ngClass]="buttonIndexClass(field)"
                        class="center-aligned button-column check-button"
                    >
                        <button
                            mat-icon-button
                            class="low-contrast"
                            *ngIf="row[field.id] !== undefined"
                        >
                            <i-feather
                                [name]="field.checkButtonIcon || 'check'"
                                [ngClass]="{ selected: !!row[field.id] }"
                            ></i-feather>
                        </button>
                    </td>
                </ng-container>

                <ng-container *ngSwitchCase="'chips'">
                    <td
                        mat-cell
                        *matCellDef="let row"
                        (click)="onRowClick(row)"
                        [ngClass]="cellClass(field,row)"
                        [matTooltipDisabled]="!field.getTooltip"
                        [matTooltip]="!!field.getTooltip ? field.getTooltip(row, field) : undefined"
                        class="left-aligned"
                    >
                        <span class="subtitle3 label">{{field.label}}</span>
                        <app-chip-list
                            [clickable]="isChipListClickable(row, field)"
                            [options]="row[field.id]"
                            (clicked)="isChipListClickable(row, field) ? executeChipClick($event, row, field) : undefined"
                        ></app-chip-list>
                    </td>
                </ng-container>

                <ng-container *ngSwitchCase="'icon'">
                    <td
                        mat-cell
                        *matCellDef="let row"
                        (click)="onRowClick(row)"
                        [ngClass]="cellClass(field,row)"
                        [matTooltipDisabled]="!field.getTooltip"
                        [matTooltip]="!!field.getTooltip ? field.getTooltip(row, field) : undefined"
                        class="center-aligned"
                    >
                        <i-feather
                            [name]="field.getValue ? field.getValue(row) : row[field.id]"
                            *ngIf="field.getValue ? !!field.getValue(row) : !!row[field.id]"
                        ></i-feather>
                    </td>
                </ng-container>

                <ng-container *ngSwitchCase="'image'">
                    <td
                        mat-cell
                        *matCellDef="let row"
                        (click)="onRowClick(row)"
                        [ngClass]="cellClass(field,row)"
                        [matTooltipDisabled]="!field.getTooltip"
                        [matTooltip]="!!field.getTooltip ? field.getTooltip(row, field) : undefined"
                        class="center-aligned"
                    >
                        <img
                            *ngIf="!!row[field.id]"
                            [src]="row[field.id]"
                            alt=""
                        />
                    </td>
                </ng-container>

                <ng-container *ngSwitchCase="'stringOrButton'">
                    <td
                        mat-cell
                        *matCellDef="let row"
                        (click)="onRowClick(row)"
                        [ngClass]="cellClass(field,row)"
                        [matTooltipDisabled]="!field.getTooltip"
                        [matTooltip]="!!field.getTooltip ? field.getTooltip(row, field) : undefined"
                    >
                        <a
                            *ngIf="!!row.isButtonNotString; else itIsString"
                            (click)="executeStringOrButtonClick(row, field);$event.stopPropagation()"
                        >{{field.getValue ? field.getValue(row) : row[field.id]}}</a>
                        <ng-template #itIsString>
                            <span class="subtitle3 label">{{row[field.id]}}</span>
                            {{field.getValue ? field.getValue(row) : row[field.id]}}
                        </ng-template>
                    </td>
                </ng-container>

                <ng-container *ngSwitchCase="'rollup'">
                    <td
                        mat-cell
                        [ngClass]="cellClass(field,row)"
                        *matCellDef="let row"
                        [matTooltip]="!!field.getTooltip ? field.getTooltip(row, field) : undefined"
                    >
                        <span *ngIf="field.getValue">
                            {{field.getValue(row)}}
                        </span>
                        <span
                            class="more-button"
                            [matMenuTriggerFor]="rollup"
                            [matMenuTriggerData]="{row: row, field: field}"
                            *ngIf="field.getRollupItems(row)?.length"
                        >
                            more...
                        </span>
                    </td>
                </ng-container>

                <ng-container *ngSwitchCase="'router-link'">
                    <td
                        mat-cell
                        *matCellDef="let row"
                        (click)="supressEvent($event)"
                        [ngClass]="cellClass(field,row)"
                        [matTooltipDisabled]="!field.getTooltip"
                        [matTooltip]="!!field.getTooltip ? field.getTooltip(row, field) : undefined"
                    >
                        <a [routerLink]="row[field.id]?.value">{{row[field.id]?.label}}</a>
                    </td>
                </ng-container>

                <ng-container *ngSwitchCase="'callback-link'">
                    <td
                        mat-cell
                        *matCellDef="let row"
                        (click)="supressEvent($event)"
                        [ngClass]="cellClass(field,row)"
                        [matTooltipDisabled]="!field.getTooltip"
                        [matTooltip]="!!field.getTooltip ? field.getTooltip(row, field) : undefined"
                    >
                        <a
                            (click)="onCallbackLinkClick($event, row, field)"
                            [routerLink]="row[field.id]?.value"
                        >{{row[field.id]?.label}}</a>
                    </td>
                </ng-container>

                <ng-container *ngSwitchCase="'template'">
                    <td
                        mat-cell
                        *matCellDef="let row"
                        (click)="supressEvent($event)"
                        [ngClass]="cellClass(field,row)"
                        [matTooltipDisabled]="!field.getTooltip"
                        [matTooltip]="!!field.getTooltip ? field.getTooltip(row, field) : undefined"
                    >
                        <!-- these will be available as so:
                            <ng-template
                                #customCell
                                let-row
                                let-index="index"
                                let-field="field"
                            >
                                {{ index }}: {{ row.id }} - {{ field.id }}
                            </ng-template>
                        -->
                        <ng-container
                            [ngTemplateOutlet]="field?.template"
                            [ngTemplateOutletContext]="{ $implicit: row, index: index, field: field }"
                        ></ng-container>
                    </td>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    <td
                        mat-cell
                        *matCellDef="let row"
                        (click)="onRowClick(row)"
                        [style]="field.cellStyles"
                        [ngClass]="cellClass(field,row)"
                        [matTooltipDisabled]="!field.getTooltip"
                        [matTooltip]="!!field.getTooltip ? field.getTooltip(row, field) : undefined"
                    >
                        <span class="subtitle3 label">{{field.label}}</span>
                        {{field.getValue ? field.getValue(row) : row[field.id]}}
                    </td>
                </ng-container>

            </ng-container>

        </ng-container>

    </table>

    <div
        class="sentinel paginator"
        appIntersectionObserver
        [intersectionThreshold]="[0]"
        (visibilityChange)="onVisibilityChange('paginator', $event)"
    ></div>
</div>
<mat-paginator
    #paginator
    [style.contentVisibility]="showPaging ? 'auto' : 'hidden'"
    class="lqd-paginator"
    [ngClass]="{ 'stuck': !bottomVisible, 'no-stick': noStick }"
    [length]="length"
    [pageIndex]="pageIndex$ | async"
    [pageSize]="pageSize$ | async"
    [pageSizeOptions]="[10, 20, 50, 100]"
    [showFirstLastButtons]="true"
>
</mat-paginator>
<mat-menu
    #actionsMenu="matMenu"
    [overlapTrigger]="false"
>
    <ng-template
        matMenuContent
        let-options="options"
        let-row="row"
    >
        <button
            mat-menu-item
            *ngFor="let option of options"
            (click)="executeAction(option, row)"
        >
            {{option.label}}
        </button>
    </ng-template>
</mat-menu>

<mat-menu #rollup="matMenu">
    <ng-template
        matMenuContent
        let-field="field"
        let-row="row"
    >
        <mat-list>
            <mat-list-item *ngFor="let rollupItem of field.getRollupItems(row)">
                {{field.getRollupItemValue(rollupItem)}}
            </mat-list-item>
        </mat-list>
    </ng-template>
</mat-menu>
