<!-- CREATE Beneficial Owner -->
<mat-card>
    <form (submit)="(isEdit) ? submitUpdateOwner() : submitCreateOwner()">
        <mat-card-header>
            <mat-card-title>
                <h3>
                    {{ title }}
                </h3>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="column">
                <div class="row">
                    <mat-form-field>
                        <mat-label>First Name</mat-label>
                        <input
                            matInput
                            required
                            [errorStateMatcher]="errorMatcher"
                            [formControl]="firstName"
                        >
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Last Name</mat-label>
                        <input
                            matInput
                            required
                            [errorStateMatcher]="errorMatcher"
                            [formControl]="lastName"
                        >
                    </mat-form-field>
                </div>

                <app-address-form
                    [addressForm]="createOwnerForm"
                    [addressInput$]="address$"
                ></app-address-form>

                <app-tax-id-field
                    [hideHint]="true"
                    [maskedSsn]="owner?.maskedTaxId"
                    [taxId]="taxId"
                    [taxIdType]="taxIdType"
                ></app-tax-id-field>

                <mat-form-field>
                    <mat-label>Message</mat-label>
                    <textarea
                        matInput
                        required
                        [errorStateMatcher]="errorMatcher"
                        [formControl]="createOwnerMessage"
                    ></textarea>
                </mat-form-field>

                <lqd-logs
                    *ngIf="isEdit"
                    [id]="beneficialOwnersLogsId"
                    [logs]="owner.logs"
                ></lqd-logs>
            </div>

        </mat-card-content>

        <mat-card-actions>

            <button
                type="button"
                mat-stroked-button
                (click)="resetCreateOwner()"
                [disabled]="!createOwnerForm.dirty && !isEdit"
            >
                {{ secondaryLabel }}
            </button>

            <button
                type="submit"
                mat-flat-button
                color="primary"
                [disabled]="createOwnerForm.invalid || !createOwnerForm.dirty"
            >
                {{ primaryLabel }}
            </button>

        </mat-card-actions>

    </form>

</mat-card>