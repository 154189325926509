import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core'
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms'
import { MatSlideToggle } from '@angular/material/slide-toggle'
import { Observable, of, Subject } from 'rxjs'
import { distinctUntilChanged, filter, map, take, takeUntil, tap } from 'rxjs/operators'

import { Address, AddressFormComponent, AddressService, Country } from '../address'
import { DefaultLabel, PaymentAccount, SelectOption } from '../core/models'
import { PaymentAccountService } from '../core/services'
import { Profile } from '../modules/models'
import { debouncedAsyncValidator, IbanValidator, LiquidValidators, NoChangePatternValidator } from '../validation'

import { ALWAYS_ON_DISPLAY_FIELDS, HIDDEN_ON_MAIN_FORM_FIELDS } from './constants'
import {
    Bank,
    CountryBankAccountInfo,
    CountryBankAccountProperty,
    OtherBankAccountProperties,
    OtherBankAccountProperty,
    PropertyDataType,
    SwiftChangedEvent
} from './models'

@Component({
    selector: 'app-country-bank-account-config',
    templateUrl: './country-bank-account-config.component.html',
    styleUrls: ['./country-bank-account-config.component.scss'],
})
export class CountryBankAccountConfigComponent implements OnInit {

    private readonly addressMap: { [label: string]: Address } = {}
    private readonly bankProperties: Array<string> = [
        DefaultLabel.BankName,
        DefaultLabel.BankBranchAddress,
    ]
    private readonly bankPropertyControlsMap: Array<{ defaultLabel: string, name: string, propertyDef: CountryBankAccountProperty }> = []
    private readonly childFieldMap: { [addressField: string]: string } = {
        Street1: 'street1',
        Street2: 'street2',
        City: 'city',
        State: 'state',
        PostalCode: 'postalCode',
        Zip: 'zip',
        Country: 'country',
    }
    private preservedBranchAddress: Address
    private userProfile?: Profile

    private ngUnsubscribe: Subject<void> = new Subject()

    @Output() initialized: EventEmitter<FormGroup> = new EventEmitter()
    @Output() swiftChanged: EventEmitter<SwiftChangedEvent> = new EventEmitter<SwiftChangedEvent>()
    @Output() save: EventEmitter<OtherBankAccountProperties | PaymentAccount> = new EventEmitter<OtherBankAccountProperties | PaymentAccount>()
    @Output() intlFormValidationStatusChanges: EventEmitter<boolean> = new EventEmitter<boolean>()
    @Output() usBankFormValidationStatusChanges: EventEmitter<boolean> = new EventEmitter<boolean>()
    @Output() usBankPreferredChange: EventEmitter<boolean> = new EventEmitter<boolean>()

    @Input() bankInformationOverride: boolean
    @Input() countryName: string
    @Input() currencyName: string
    @Input() editing: boolean
    @Input() headerImage: string
    @Input() isForManualUSBankAccount: boolean
    @Input() prefills: { [type: string]: string | Observable<Address> }
    @Input() properties$: Observable<Array<OtherBankAccountProperty>>
    @Input() titleOverride: string
    @Input() set reviewMode(enabled: boolean) {
        this.reviewModeEnabled = enabled
        this.addressFormEditable = false
        if (enabled) {
            this.usAccountFormGroup.disable()
            this.nonUsForm?.disable()
            this.enableBankProperties(false)
        } else {
            this.usAccountFormGroup.enable()
            this.nonUsForm?.enable()
        }
        this.togglePersonalOrBusinessField(enabled)
    }

    @Input() set currentUserProfile(profile: Profile) {
        if (this.userProfile) {
            return
        }

        this.userProfile = profile
        this.usAccountHolderName.setValue(`${profile.firstName} ${profile.lastName}`)
    }

    @ViewChildren(AddressFormComponent) private childAddressForms: QueryList<AddressFormComponent>
    @ViewChildren(MatSlideToggle) slideToggles!: QueryList<MatSlideToggle>

    addressFormEditable: boolean = false
    bankId: string
    bankInformationOverrideOriginalSwift: string
    currentSwiftCountryIsForeign: boolean = false
    readonly defaultLabel: typeof DefaultLabel = DefaultLabel
    // this is used to switch between nonUsForm and usAccountFormGroup when doing initialization for accordion
    form: FormGroup
    // This attribute might not be necessary, the FormGroups can be accessed through form.controls [groupName]
    formGroups: { [key: string]: FormGroup } = {}
    initialize: boolean
    lastSwiftCode: string
    // this is the default form for international
    nonUsForm: FormGroup
    overrideBankInfo: boolean = false
    parentProperties: Array<OtherBankAccountProperty>
    personalOrBusinessControlId: string | undefined
    preferredCurrencyIndexForPrimaryBanking: number = 0
    properties: Array<OtherBankAccountProperty>
    readonly propertyDataType: typeof PropertyDataType = PropertyDataType
    propertySelectOptions: { [key: string]: Array<SelectOption<string>> } = {}
    propertySelectOptionsTooltips: { [propKey: string]: { [optKey: string]: string } } = {}
    reviewModeEnabled: boolean = false
    showBankInfo: boolean = false
    stateOptions: Array<SelectOption<string>> = this.addresses.stateOptions
    swiftBankAddress: Address
    swiftControlId: string
    swiftCountry: string
    swiftCountryChanged: boolean = false
    swiftCountryCurrencies: Array<string>
    swiftCountryCurrencyIds: Array<string>
    swiftCountryId: string
    usAccountAddress: FormControl = new FormControl(undefined, Validators.required)
    usAccountAddress2: FormControl = new FormControl(undefined)
    usAccountBankAccountNumber: FormControl = new FormControl(undefined, [Validators.required, Validators.minLength(4)])
    usAccountBankName: FormControl = new FormControl(undefined, Validators.required)
    usAccountCity: FormControl = new FormControl(undefined, Validators.required)
    usAccountCountry: FormControl = new FormControl({ value: 'United States of America', disabled: true }, Validators.required)
    usAccountHolderName: FormControl = new FormControl(undefined, Validators.required)
    usAccountRouting: FormControl = new FormControl(undefined, Validators.required)
    usAccountState: FormControl = new FormControl(undefined, Validators.required)
    usAccountZip: FormControl = new FormControl(undefined, Validators.required)
    usAccountBankIsBusiness: FormControl = new FormControl(false, Validators.required)
    // this is used to switch to this form when in the accordion workflow:
    usAccountFormGroup: FormGroup = new FormGroup({
        usAccountAddress: this.usAccountAddress,
        usAccountAddress2: this.usAccountAddress2,
        usAccountBankAccountNumber: this.usAccountBankAccountNumber,
        usAccountBankName: this.usAccountBankName,
        usAccountCity: this.usAccountCity,
        usAccountHolderName: this.usAccountHolderName,
        usAccountRouting: this.usAccountRouting,
        usAccountState: this.usAccountState,
        usAccountZip: this.usAccountZip,
    })
    usBankPreferred: boolean = false

    get isUsOverride(): boolean { return this.usBankPreferred && !this.isForManualUSBankAccount }
    get lastSelectedSwiftCurrencyId(): string {
        return this.swiftCountryCurrencies?.length > 0 ? this.swiftCountryCurrencyIds[this.preferredCurrencyIndexForPrimaryBanking] : undefined
    }
    get usAccountFormValid(): boolean {
        return this.usAccountFormGroup.valid
    }

    constructor(
        private addresses: AddressService,
        private paymentAccounts: PaymentAccountService,
    ) { }

    ngOnInit(): void {
        this.addresses.initialize().subscribe()

        this.reloadProperties()
            .subscribe()
    }

    reloadProperties(): Observable<OtherBankAccountProperty[]> {
        // set the us bank preferred when entering a us bank account manually
        this.usBankPreferred = this.isForManualUSBankAccount

        this.currencyName = this.currencyName || ''
        this.bankPropertyControlsMap.splice(0, this.bankPropertyControlsMap.length)

        return this.properties$
            .pipe(
                takeUntil(this.ngUnsubscribe),
                filter(data => !!data?.length),
                tap(data => {

                    this.properties = data
                    this.parentProperties = this.properties?.filter(property => !property.countryBankAccountProperty.parentId && !property.countryBankAccountProperty.parent)
                    const formControls: { [key: string]: FormGroup | FormControl } = this.parentProperties
                        ?.sort((a, b) => a.countryBankAccountProperty.order - b.countryBankAccountProperty.order)
                        .reduce((acc, property) => {
                            const propertyDef: CountryBankAccountProperty = property.countryBankAccountProperty
                            const name: string = propertyDef.id
                            let formInput: FormGroup | FormControl
                            switch (propertyDef.bankAccountPropertyDef.dataType) {

                                case PropertyDataType.ADDRESS:

                                    // get the values for the child properties
                                    const address: { [key: string]: string } = this.properties
                                        .filter(p => p.countryBankAccountProperty.parentId === propertyDef.id && !!p.value)
                                        .reduce((children, p) => {
                                            children[this.childFieldMap[p.countryBankAccountProperty.bankAccountPropertyDef.defaultLabel]] = p.value
                                            return children
                                        }, {})

                                    if (this.swiftBankAddress) {
                                        this.addressMap[propertyDef.label] = this.swiftBankAddress
                                    } else if (Object.keys(address).filter(key => !!address[key]).length) {
                                        // if the child fields map to an address, add them to a map
                                        this.addressMap[propertyDef.label] = <Address><unknown>address
                                    }

                                    this.formGroups[name] = new FormGroup({})
                                    formInput = this.formGroups[name]
                                    break

                                case PropertyDataType.HEADER:
                                    // don't add headers to the form
                                    break

                                default:
                                    const validators: ValidatorFn[] = []
                                    const asyncValidators: AsyncValidatorFn[] = []
                                    if (!!propertyDef.required) {
                                        validators.push(Validators.required)
                                    }

                                    if (!!propertyDef.validationPattern) {
                                        validators.push(NoChangePatternValidator(property.value, property.countryBankAccountProperty.validationPattern))
                                    }

                                    const vendorCountry: Country = this.addresses.getCountryById(propertyDef.countryId)
                                    const swiftCountry: Country = this.swiftCountryId ? this.addresses.getCountryById(this.swiftCountryId) : undefined
                                    if (propertyDef?.bankAccountPropertyDef?.defaultLabel === DefaultLabel.Iban) {
                                        validators.push(IbanValidator(swiftCountry ? swiftCountry.code2 : vendorCountry?.code2))
                                    } else if (propertyDef?.bankAccountPropertyDef?.defaultLabel === DefaultLabel.SwiftCode) {
                                        this.swiftControlId = propertyDef.id
                                        validators.push(LiquidValidators.swift())
                                        asyncValidators.push(debouncedAsyncValidator<string>(swiftCode => {

                                            // keep the original swift for override purposes (not messing with lastSwiftCode so there are no side-effects)
                                            if (!!this.bankInformationOverrideOriginalSwift && this.bankInformationOverrideOriginalSwift !== swiftCode) {
                                                this.bankInformationOverride = false // allow it to get updated and pre-fill
                                            } else if (!this.bankInformationOverrideOriginalSwift && !!swiftCode) {
                                                this.bankInformationOverrideOriginalSwift = swiftCode
                                            }
                                            return this.paymentAccounts.getBank(swiftCode)
                                                .pipe(
                                                    map(bank => {
                                                        if (!bank) {
                                                            this.showBankInfo = false
                                                            this.bankId = undefined
                                                            this.enableBankProperties()
                                                            this.overrideBankInfo = true
                                                            return undefined
                                                        }

                                                        // if the bank info is overridden, just treat like there is not a match
                                                        if (this.bankInformationOverride) {
                                                            this.showBankInfo = false
                                                            this.bankId = undefined
                                                            this.enableBankProperties()
                                                            return undefined
                                                        }

                                                        this.swiftBankAddress = this.getBankAddress(bank)

                                                        if (bank?.name !== undefined) {
                                                            Object.keys(bank).forEach(k => {
                                                                bank[k] = bank[k] === null ? undefined : bank[k]
                                                            })
                                                            this.updateBankProperties(bank)
                                                        }
                                                        this.bankId = bank.id
                                                        this.enableBankProperties(false)

                                                        const countryId = bank.countryId
                                                        const countryName = bank.countryName

                                                        this.currentSwiftCountryIsForeign = countryId !== vendorCountry.id
                                                        if (this.lastSwiftCode !== swiftCode) {
                                                            this.swiftCountry = bank.countryName
                                                            this.swiftChanged.emit({ bank })
                                                        }
                                                        this.showBankInfo = true
                                                        return undefined
                                                    }),
                                                )
                                        }))
                                    }

                                    const propertyValue: string = propertyDef?.bankAccountPropertyDef?.defaultLabel === DefaultLabel.SwiftCode && this.lastSwiftCode ? this.lastSwiftCode : property.value
                                    let formValue: string = propertyValue
                                    if (propertyDef?.bankAccountPropertyDef?.dataType === PropertyDataType.LIST) {
                                        const options: Array<SelectOption<string>> = JSON.parse(property.countryBankAccountProperty.listValuesJson)
                                        if (!property.countryBankAccountProperty.required) {
                                            options.unshift({ value: '', label: '' })
                                        }
                                        this.propertySelectOptions[property.countryBankAccountProperty.id] = options
                                        for (const option of options) {
                                            this.propertySelectOptionsTooltips[property.countryBankAccountProperty.id] ||= {}
                                            this.propertySelectOptionsTooltips[property.countryBankAccountProperty.id][option.value] = option.label.length > 50 ? option.label : ''
                                        }
                                        formValue = formValue ?? options[0].value
                                    }

                                    // if this is a bool, convert the string value to a bool
                                    if (!formValue && propertyDef.bankAccountPropertyDef.dataType === PropertyDataType.BOOLEAN) {
                                        formValue = 'true'
                                        this.personalOrBusinessControlId = propertyDef.id
                                    }

                                    // populate the account holder name
                                    const prefillValue: string = !!propertyDef.prefillType ? <string>this.prefills?.[propertyDef.prefillType] : undefined
                                    formInput = new FormControl({
                                        value: formValue === undefined ? prefillValue : formValue,
                                        disabled: !!this.editing && !propertyDef.editable && (!propertyDef.required || !!formValue),
                                    }, validators)
                                    formInput.setAsyncValidators(asyncValidators)
                                    break
                            }

                            const defaultLabel: string = propertyDef.bankAccountPropertyDef.defaultLabel

                            if (this.bankProperties.includes(defaultLabel) && !this.isForManualUSBankAccount) {
                                this.bankPropertyControlsMap.push({ defaultLabel, name, propertyDef })
                                formInput.disable()
                            }

                            // if we have an input, add it to the controls
                            if (formInput !== undefined) {
                                acc[name] = formInput
                            }
                            return acc

                        }, {})

                    const usBankControl: FormControl = new FormControl(false)
                    formControls.usBank = usBankControl
                    usBankControl.valueChanges.subscribe(value => {
                        this.usBankPreferred = value
                        if (value && !this.isForManualUSBankAccount) {
                            this.form = this.usAccountFormGroup
                        } else {
                            this.form = this.nonUsForm
                        }
                        this.initialized.emit(this.form)
                        this.usBankPreferredChange.emit(value)
                        const bankBranchAddressId: string = this.properties.find(x => x.countryBankAccountProperty?.bankAccountPropertyDef?.defaultLabel === DefaultLabel.BankBranchAddress)?.countryBankAccountProperty?.id
                        if (value && bankBranchAddressId) {
                            this.preservedBranchAddress = <Address>formControls[bankBranchAddressId].value
                            this.preservedBranchAddress.country = this.countryName
                        }
                    })

                    this.nonUsForm = new FormGroup(formControls)
                    this.nonUsForm.statusChanges
                        .pipe(
                            filter(() => {
                                return !this.intlFormControlsDisabled() || this.addressFormEditable
                            }),
                        )
                        .subscribe(status => {
                            this.intlFormValidationStatusChanges.emit(status !== 'INVALID')
                        })
                    this.usAccountFormGroup.statusChanges
                        .pipe(
                            filter(status => status === 'VALID' || status === 'INVALID'),
                            distinctUntilChanged(),
                        )
                        .subscribe(status => this.usBankFormValidationStatusChanges.emit(status === 'VALID'))
                    this.form = this.nonUsForm
                    if (this.lastSwiftCode) {
                        formControls.usBank.setValue(false)
                    } else {
                        usBankControl.setValue(this.isForManualUSBankAccount)
                    }

                    this.initialize = true
                    this.initialized.emit(this.form)
                }),
            )
    }

    displayHeader(property: CountryBankAccountProperty): boolean {
        if (property.bankAccountPropertyDef.defaultLabel === DefaultLabel.BankInformation) {
            return true
        }

        return this.reviewModeEnabled || this.isForManualUSBankAccount
    }

    displayAddressForm(property: CountryBankAccountProperty): boolean {
        if (property.bankAccountPropertyDef.defaultLabel === DefaultLabel.BankBranchAddress) {
            return this.reviewModeEnabled ? false : this.overrideBankInfo || this.showBankInfo || this.isForManualUSBankAccount
        }

        return this.reviewModeEnabled || this.isForManualUSBankAccount
    }

    displayStringFormControl(property: CountryBankAccountProperty): boolean {
        if (property.bankAccountPropertyDef.defaultLabel === DefaultLabel.BankName) {
            return this.overrideBankInfo || this.showBankInfo || this.reviewModeEnabled || this.isForManualUSBankAccount
        }

        const fieldIsAlwaysOnDisplay: boolean = ALWAYS_ON_DISPLAY_FIELDS.includes(property.bankAccountPropertyDef.defaultLabel)
        const fieldIsHiddenOfFirstStep: boolean = HIDDEN_ON_MAIN_FORM_FIELDS.includes(property.bankAccountPropertyDef.defaultLabel)
        if (fieldIsAlwaysOnDisplay || !fieldIsHiddenOfFirstStep) {
            return true
        }

        return this.reviewModeEnabled || this.isForManualUSBankAccount
    }

    toggleHolderAddressForm(formGroup: FormGroup, enable: boolean): void {
        this.addressFormEditable = enable
        if (enable) {
            formGroup.enable()
        } else {
            formGroup.disable()
        }
    }

    onSave(): void {
        this.preservedBranchAddress = undefined

        // if this is a us override of an intl account, save it explicitly
        if (this.isUsOverride) {
            this.saveIntlUsBankAccount()
            return
        }

        this.save.emit(this.getOnSaveData())
    }

    preferFirstForPrimaryBanking(): void {
        this.preferredCurrencyIndexForPrimaryBanking = 0
    }

    preferSecondForPrimaryBanking(): void {
        this.preferredCurrencyIndexForPrimaryBanking = 1
    }

    updateSwift(info: CountryBankAccountInfo, props: SwiftChangedEvent, defaultCurrencyCode: string = 'USD'): Observable<void> {
        if (this.countryName !== props.bank?.countryName) {
            this.properties$ = of(info.properties.map(prop => {
                let propValue: string

                if (prop.bankAccountPropertyDef?.defaultLabel === DefaultLabel.BankName) {
                    propValue = props.bank?.name
                }
                if (prop.bankAccountPropertyDef?.defaultLabel === DefaultLabel.SwiftCode) {
                    propValue = props.bank?.swiftCode
                }
                if (prop.bankAccountPropertyDef?.defaultLabel === DefaultLabel.Iban) {
                    propValue = this.parentProperties.find(p => p.countryBankAccountProperty.bankAccountPropertyDef?.defaultLabel === DefaultLabel.Iban)?.value
                }
                return {
                    countryBankAccountProperty: prop,
                    value: propValue,
                }
            }))
            this.currencyName = this.countryName !== props.bank?.countryName
                ? info.country.currencies.find(c => c.currency.alphaCode !== defaultCurrencyCode)?.currency.name
                : this.currencyName

            this.swiftCountryCurrencies = this.countryName !== props.bank?.countryName
                ? info.country.currencies.filter(c => c.enabled && (c.currency?.isStable || c.currency?.alphaCode === defaultCurrencyCode)).map(c => c.currency.alphaCode)
                : []

            this.swiftCountryCurrencyIds = this.countryName !== props.bank?.countryName
                ? info.country.currencies.filter(c => c.enabled && (c.currency?.isStable || c.currency?.alphaCode === defaultCurrencyCode)).map(c => c.currency.id)
                : []
            this.swiftCountryChanged = true
        } else {
            this.swiftCountryChanged = false
        }
        this.swiftCountryId = props.bank?.countryId
        this.countryName = props.bank.countryName
        this.lastSwiftCode = props.bank?.swiftCode

        return of(undefined)
    }

    private saveIntlUsBankAccount(): void {

        // TODO: this should not be on this form. We should
        // emit the flag that the us bank account is preferred and let
        // the containing component figure out how to save it.

        this.paymentAccounts.createForeignVendorUSABankAccount({
            accountHolderName: this.usAccountHolderName.value,
            accountNumber: this.usAccountBankAccountNumber.value,
            city: this.usAccountCity.value,
            institutionName: this.usAccountBankName.value,
            isPersonalAccount: !this.usAccountBankIsBusiness.value,
            name: this.usAccountHolderName.value,
            postalCode: this.usAccountZip.value,
            region: this.usAccountState.value,
            routingNumber: this.usAccountRouting.value,
            street1: this.usAccountAddress.value,
            street2: this.usAccountAddress2.value,
        })
            .pipe(
                take(1),
                tap(paymentAccount => this.save.emit(paymentAccount)),
            )
            .subscribe()
    }

    onSaveExternal(): Observable<OtherBankAccountProperties> {
        return of(this.getOnSaveData())
    }

    prefillAddress(input: OtherBankAccountProperty): Observable<Address> {
        const mappedAddress: Address = this.addressMap[input.countryBankAccountProperty.label]
        const preservedOrPrefilledAddress$: Observable<Address> = <Observable<Address>>this.prefills?.[input.countryBankAccountProperty.prefillType] ?? of(this.preservedBranchAddress)
        return !!mappedAddress ? of(mappedAddress) : preservedOrPrefilledAddress$
    }

    optionalChildFields(input: OtherBankAccountProperty): Array<string> {
        return this.properties.filter(prop => prop.countryBankAccountProperty.parentId === input.countryBankAccountProperty.id && !prop.countryBankAccountProperty.required)
            .map(prop => prop.countryBankAccountProperty.label)
    }

    requiredChildFields(input: OtherBankAccountProperty): Array<string> {
        return this.properties.filter(prop => prop.countryBankAccountProperty.parentId === input.countryBankAccountProperty.id && prop.countryBankAccountProperty.required)
            .map(prop => prop.countryBankAccountProperty.label)
    }

    resetAddressForms(): void {
        this.childAddressForms.forEach(f => f.addressForm.reset())
    }

    toggleOverrideBankInfo(): void {
        this.overrideBankInfo = !this.overrideBankInfo
        this.enableBankProperties(this.overrideBankInfo)
        // enabling / disabling seems to set the form's validity. Force a refresh of it:
        if (!!this.nonUsForm) {
            this.nonUsForm.updateValueAndValidity()
        }
    }

    enableBankProperties(enable: boolean = true): void {
        for (const entry of this.bankPropertyControlsMap) {
            const formControl: FormControl = <FormControl>this.nonUsForm.controls?.[entry.name]
            switch (entry.defaultLabel) {
                case DefaultLabel.BankName:
                    if (enable) {
                        formControl.enable()
                    } else {
                        formControl.disable()
                    }
                    break
                case DefaultLabel.BankBranchAddress:
                    if (enable) {
                        formControl.enable()
                        this.childAddressForms.forEach(f => f.enableAllExceptCountry(true))
                        const childCountry: FormControl = <FormControl>formControl.get('country')
                        if (!!childCountry && entry.propertyDef.disableCountry) {
                            childCountry.disable()
                        }
                    } else {
                        formControl.disable()
                        this.childAddressForms.forEach(f => f.enableAllExceptCountry(false))
                    }
                    break
                default:
                    break
            }
        }
    }

    resetPrefillProperty(prefillProp: OtherBankAccountProperty): void {
        if (!!prefillProp) {
            const formControl: FormControl = <FormControl>this.nonUsForm.controls?.[prefillProp.countryBankAccountProperty.id]
            formControl.setValue(prefillProp.value)
        }
    }

    restoreCountryInAddressForms(): void {
        this.childAddressForms.forEach(f => f.country.setValue(this.countryName))
    }

    private getBankAddress(bank: Bank): Address {
        return {
            street1: bank.address1,
            street2: bank.address2,
            city: bank.city,
            state: undefined,
            countryId: bank.countryId,
            country: bank.countryName,
            postalCode: bank.postalCode,
        }
    }

    private getChildValueFromBank(child: string, bank: Bank): string {
        switch (child) {
            case this.childFieldMap.Street1:
                return bank.address1
            case this.childFieldMap.Street2:
                return bank.address2
            case this.childFieldMap.City:
                return bank.city
            case this.childFieldMap.PostalCode:
                return bank.postalCode
            case this.childFieldMap.Country:
                return bank.countryName
            default:
                return undefined
        }
    }

    private getLastAddressForForeignPrimaryBanking(parentPropId: string): Address {
        const addressProps = this.parentProperties.filter(p => p.countryBankAccountProperty.bankAccountPropertyDef?.dataType === 'address')
        const propIndex = addressProps.findIndex(p => p.countryBankAccountProperty.id === parentPropId)

        const addressForm = this.childAddressForms.get(propIndex)

        return {
            street1: addressForm.street1.value,
            street2: addressForm.street2.value,
            city: addressForm.city.value,
            state: addressForm.state.value,
            countryId: this.swiftCountryId,
            country: this.swiftCountry,
            postalCode: addressForm.postalCode.value,
        }
    }

    private getOnSaveData(): OtherBankAccountProperties {

        if (this.isUsOverride) {
            this.saveIntlUsBankAccount()
            return undefined
        }

        const properties: Array<OtherBankAccountProperty> = this.properties
            .filter(property => property.countryBankAccountProperty.bankAccountPropertyDef.dataType !== PropertyDataType.HEADER)
            .map(property => ({
                countryBankAccountProperty: property.countryBankAccountProperty,
                value: this.getPropertyValue(property.countryBankAccountProperty),
            }))

        return {
            bankId: this.bankId,
            currencyId: this.swiftCountryCurrencies?.length > 0 ? this.swiftCountryCurrencyIds[this.preferredCurrencyIndexForPrimaryBanking] : undefined,
            countryId: this.swiftCountryId,
            willUpdate: !this.swiftCountryChanged,
            overrideBankInformation: this.overrideBankInfo,
            properties,
        }
    }

    private getPropertyValue(property: CountryBankAccountProperty): string {

        if (!!property.parent) {

            // this is a child, so get the value from the parent
            const parent: FormGroup = <FormGroup>this.nonUsForm.controls[property.parent.id]
            let child: string = parent.controls?.[this.childFieldMap[property.bankAccountPropertyDef.defaultLabel]]?.value

            if (this.lastSwiftCode) {
                child = this.getLastAddressForForeignPrimaryBanking(property.parentId)[this.childFieldMap[property.bankAccountPropertyDef.defaultLabel]]
            }

            // postal codes have zip as control name if US based
            if (property.bankAccountPropertyDef.defaultLabel === 'PostalCode' && !child) {
                child = parent.controls?.[this.childFieldMap.Zip]?.value
            }

            return child === null ? undefined : child
        }

        let output: string | boolean | Address = this.form.controls[property.id]?.value

        if (property.bankAccountPropertyDef.dataType !== PropertyDataType.ADDRESS) {
            return output === null || output === undefined ? undefined : output.toString()
        }

        output = !this.lastSwiftCode ? <Address>output : this.getLastAddressForForeignPrimaryBanking(property.id)

        // if the country is disabled, assign it
        Object.keys(output)
            .filter(key => output[key] === null)
            .forEach(key => output[key] = undefined)

        if (this.properties.find(p => p.countryBankAccountProperty.id === property.parentId)?.countryBankAccountProperty.disableCountry) {
            output.country = this.countryName
        }

        // if this is the address, stringify it
        return JSON.stringify(output)
    }

    private intlFormControlsDisabled(): boolean {
        for (const controlName of Object.getOwnPropertyNames(this.nonUsForm.controls)) {
            const control: AbstractControl = this.nonUsForm.controls[controlName]
            if (control instanceof FormControl && !control.disabled && controlName !== this.personalOrBusinessControlId) {
                return false
            }
        }

        return true
    }

    private togglePersonalOrBusinessField(enabled: boolean): void {
        if (!this.parentProperties || this.usBankPreferred) {
            return
        }

        const businessOrPersonalFieldId: string = this.parentProperties.find(prop => {
            return prop.countryBankAccountProperty.bankAccountPropertyDef.dataType === PropertyDataType.BOOLEAN
        })?.countryBankAccountProperty?.id

        if (businessOrPersonalFieldId) {
            enabled
                ? this.form.controls[businessOrPersonalFieldId].enable()
                : this.form.controls[businessOrPersonalFieldId].disable()
        }
    }

    private updateBankProperties(bank: Bank): void {
        this.bankId = bank.id
        for (const entry of this.bankPropertyControlsMap) {
            const formControl: FormControl = <FormControl>this.nonUsForm.controls?.[entry.name]
            switch (entry.defaultLabel) {
                case DefaultLabel.BankName:
                    formControl.setValue(bank.name)
                    break
                case DefaultLabel.BankBranchAddress:
                    const parent: FormGroup = <FormGroup>this.nonUsForm.controls?.[entry.name]
                    const parentControlsKeys = Object.keys(parent.controls)
                    if (parentControlsKeys.length > 0) {
                        for (const child of parentControlsKeys) {
                            const valueFromBank: string = this.getChildValueFromBank(child, bank)
                            if (valueFromBank) {
                                parent.controls?.[child].setValue(valueFromBank)
                            }
                        }
                    } else {
                        const addressProps = this.parentProperties.filter(p => p.countryBankAccountProperty.bankAccountPropertyDef?.dataType === 'address')
                        const bankBranchAddressPropIndex = addressProps.findIndex(p => p.countryBankAccountProperty.bankAccountPropertyDef?.defaultLabel === DefaultLabel.BankBranchAddress)
                        const bankBranchAddressForm = this.childAddressForms.get(bankBranchAddressPropIndex)
                        bankBranchAddressForm.city.setValue(this.swiftBankAddress.city)
                        bankBranchAddressForm.country.setValue(this.swiftBankAddress.country)
                        bankBranchAddressForm.postalCode.setValue(this.swiftBankAddress.postalCode)
                        bankBranchAddressForm.street1.setValue(this.swiftBankAddress.street1)
                        bankBranchAddressForm.street2.setValue(this.swiftBankAddress.street2)
                    }
                    break
                default:
                    break
            }
        }
    }
}
