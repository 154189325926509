import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Observable, of } from 'rxjs'
import { tap } from 'rxjs/operators'
import { AddressService } from '../../address'

import { AdminPaymentAccount, MicrodepositVerification, MicrodepositVerificationAttempt, PaymentAccountType, PlaidIdentity } from '../../core/models'
import { PaymentAccountService } from '../../core/services'
import { OtherBankAccountProperty } from '../../country-bank-account-config'
import { ListField } from '../../table/models'

@Component({
    selector: 'lqd-payment-details-dialog',
    templateUrl: './payment-details-dialog.component.html',
    styleUrls: ['./payment-details-dialog.component.scss'],
})
export class PaymentDetailsDialogComponent implements OnInit {

    readonly account: AdminPaymentAccount
    readonly addresses: Array<PlaidIdentity>
    readonly created: Date
    details: Array<OtherBankAccountProperty>
    readonly emails: Array<PlaidIdentity>
    readonly hasMicrodeposits: boolean
    readonly isCC: boolean
    readonly isLiquidBankAccount: boolean
    readonly isOtherBankAccount: boolean
    readonly isUsaBankAccount: boolean
    readonly isPlaidAccount: boolean
    readonly microdepositVerifications: Array<MicrodepositVerification>
    readonly microdepositVerificationAttempts: Array<MicrodepositVerificationAttempt>
    readonly names: Array<PlaidIdentity>
    readonly phones: Array<PlaidIdentity>
    readonly verificationMethod: string

    readonly VERIFICATION_STATUS_PENDING_AUTOMATIC_VERIFICATION: string = 'pending_automatic_verification'
    readonly VERIFICATION_STATUS_PENDING_MANUAL_VERIFICATION: string = 'pending_manual_verification'

    readonly identityAddressColumns: ListField[] = [
        {
            id: 'street',
            label: 'Street',
        },
        {
            id: 'city',
            label: 'City',
        },
        {
            id: 'region',
            label: 'Region',
        },
        {
            id: 'postalCode',
            label: 'Postal Code',
        },
        {
            id: 'country',
            label: 'Country',
        },
        {
            id: 'primary',
            label: 'Primary',
            type: 'check',
        },
        {
            id: 'created',
            label: 'Created',
            type: 'date',
        },
    ]
    readonly identityEmailColumns: ListField[] = [
        {
            id: 'data',
            label: 'Email',
        },
        {
            id: 'type',
            label: 'Type',
        },
        {
            id: 'primary',
            label: 'Primary',
            type: 'check',
        },
        {
            id: 'created',
            label: 'Created',
            type: 'date',
        },
    ]

    readonly identityNameColumns: ListField[] = [
        {
            id: 'name',
            label: 'Name',
        },
        {
            id: 'created',
            label: 'Created',
            type: 'date',
        },
    ]

    readonly identityPhoneColumns: ListField[] = [
        {
            id: 'data',
            label: 'Phone Number',
        },
        {
            id: 'type',
            label: 'Type',
        },
        {
            id: 'primary',
            label: 'Primary',
            type: 'check',
        },
        {
            id: 'created',
            label: 'Created',
            type: 'date',
        },
    ]

    readonly microdepositVerificationColumns: ListField[] = [
        {
            id: 'created',
            label: 'Created',
            type: 'timestamp',
        },
        {
            id: 'updated',
            label: 'Updated',
            type: 'timestamp',
        },
        {
            id: 'id',
            label: 'Id',
        },
        {
            id: 'initiatedBy',
            label: 'Initiated By',
        },
        {
            id: 'instructedAmount1',
            label: 'Amount1',
        },
        {
            id: 'instructedAmount2',
            label: 'Amount2',
        },
        {
            id: 'payment1Status',
            label: 'Payment1 Status',
        },
        {
            id: 'payment2Status',
            label: 'Payment2 Status',
        },
        {
            id: 'debitStatus',
            label: 'Withdrawal Status',
        },
        {
            id: 'complete',
            label: 'Complete',
            type: 'check',
        },
    ]

    readonly microdepositVerificationAttemptsColumns: ListField[] = [
        {
            id: 'created',
            label: 'Created',
            type: 'timestamp',
        },
        {
            id: 'verificationId',
            label: 'Verification Id',
        },
        {
            id: 'attemptedBy',
            label: 'Attempted By',
        },
        {
            id: 'amount1',
            label: 'Amount1',
        },
        {
            id: 'amount2',
            label: 'Amount2',
        },
    ]

    addresses$: Observable<Array<PlaidIdentity>>
    emails$: Observable<Array<PlaidIdentity>>
    names$: Observable<Array<PlaidIdentity>>
    microdepositVerifications$: Observable<Array<MicrodepositVerification>>
    microdepositVerificationAttempts$: Observable<Array<MicrodepositVerificationAttempt>>
    phones$: Observable<Array<PlaidIdentity>>

    constructor(
        private dialogRef: MatDialogRef<PaymentDetailsDialogComponent>,
        private addressesService: AddressService,
        @Inject(MAT_DIALOG_DATA) data: AdminPaymentAccount,
        private paymentAccounts: PaymentAccountService,
    ) {
        this.account = data
        this.addresses = this.account.plaidIdentityAddresses?.sort((a, b) => a.created - b.created)
        this.created = new Date(this.account.createdTimestamp)
        this.emails = this.account.plaidIdentityEmails?.sort((a, b) => a.created - b.created)
        this.hasMicrodeposits = !!this.account.microdepositVerificationId
        this.names = this.account.plaidIdentityNames?.sort((a, b) => a.created - b.created)
        this.phones = this.account.plaidIdentityPhoneNumbers?.map(num => ({ ...num, data: this.formatPhoneNumber(num.data) })).sort((a, b) => a.created - b.created)
        this.isCC = this.account.paymentAccountSuperType === 'CC'
        this.isLiquidBankAccount = this.account.paymentAccountType === PaymentAccountType.LiquidBankAccount
        this.isOtherBankAccount = this.account.paymentAccountType === PaymentAccountType.OtherBankAccount
        this.isPlaidAccount = this.account.paymentAccountType === PaymentAccountType.PlaidBank
        this.isUsaBankAccount = this.account.country === this.addressesService.usa
        this.microdepositVerifications = this.account.microdepositDetails?.verifications?.sort((a, b) => a.created - b.created) || []
        this.microdepositVerificationAttempts = this.account.microdepositDetails?.verificationAttempts?.sort((a, b) => a.created - b.created) || []

        this.verificationMethod = this.getVerificationMethod()

        if (this.account.currentVerificationStatus) {
            this.account.status = this.account.currentVerificationStatus
        }

        this.addresses$ = of(this.addresses)
        this.emails$ = of(this.emails)
        this.microdepositVerifications$ = of(this.microdepositVerifications)
        this.microdepositVerificationAttempts$ = of(this.microdepositVerificationAttempts)
        this.names$ = of(this.names)
        this.phones$ = of(this.phones)
    }

    ngOnInit(): void {
        this.paymentAccounts.getOtherBankAccountProps(this.account.id)
            .pipe(
                tap(props => this.details = props),
            )
            .subscribe()
    }

    getVerificationMethod(): string {
        const instantAuth: string = 'Instant Auth'
        const microdepositsViaLiquid: string = 'Microdeposits via Liquid JPM'
        const microdepositsViaLiquidOrInstanAuth: string = !!this.account.microdepositVerificationId ? microdepositsViaLiquid : instantAuth
        switch (this.account.initialVerificationStatus) {
            case this.VERIFICATION_STATUS_PENDING_AUTOMATIC_VERIFICATION:
                return 'Auto Microdeposit'
            case this.VERIFICATION_STATUS_PENDING_MANUAL_VERIFICATION:
                return 'Manual Microdeposit'
            default:
                return microdepositsViaLiquidOrInstanAuth
        }
    }

    formatPhoneNumber(num: string): string {
        return `(${num.slice(0, 3)}) ${num.slice(3, 6)}-${num.slice(6)}`
    }

}
