export enum TableId {
    banks = 'banks',
    bannedDomains = 'banned-domains',
    bannedIPAddresses = 'banned-ip-addresses',
    beneficialOwners = 'beneficial-owners',
    beneficialOwnersLogs = 'beneficial-owners-logs',
    businessLogs = 'business-logs',
    businessUsers = 'business-users',
    charges = 'transaction-charges',
    countries = 'countries',
    clientTransactionHistory = 'client-transaction-history',
    clientVendorTransactionHistory = 'client-vendor-transaction-history',
    dashboardBusinesses = 'dashboard-businesses',
    dashboardReviewTransactions = 'dashboard-review-transactions',
    dashboardTransactions = 'dashboard-transactions',
    dashboardUsers = 'dashboard-users',
    foreignBankReview = 'foreign-bank-review',
    foreignPending = 'foreign-pending-accounts',
    fxRates = 'fx-rates',
    jpmTxLogsId = 'jpm-tx-logs',
    kycDocuments = 'kyc-documents',
    microdepositRelease = "micro-deposit-requests-review",
    notifications = 'notifications',
    paymentAccounts = 'payment-accounts',
    paymentChecks = 'payment-checks',
    payoutChecks = 'payout-checks',
    processingEvents = 'processing-events',
    transactionLogs = 'transaction-logs',
    trackAndTraces = 'track-and-traces',
    unassociatedJpmTransactions = 'unassociated-jpm-txs',
    unverifiedLiquidAccounts = 'unverified-liquid-accounts',
    vendorTransactionHistory = 'client-vendor-transaction-history',
}
