<mat-card>
    <mat-card-header>
        <mat-card-title>
            <h3>
                Set KYC / Payment Status
            </h3>
        </mat-card-title>
    </mat-card-header>

    <form (submit)="submit()">

        <mat-card-content>
            <div class="column">
                <mat-form-field *ngIf="showBiz">
                    <mat-label>Business ID</mat-label>
                    <input
                        matInput
                        [formControl]="businessId"
                        required
                    >
                </mat-form-field>
                <div class="row">
                    <mat-form-field>
                        <mat-label>Payment Status</mat-label>
                        <mat-select [formControl]="paymentStatus">
                            <mat-option
                                *ngFor="let option of paymentStatusOptions"
                                [value]="option.value"
                            >
                                {{option.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="showKyc">
                        <mat-label>Kyc Status</mat-label>
                        <mat-select [formControl]="kycStatus">
                            <mat-option
                                *ngFor="let option of kycStatusOptions"
                                [value]="option.value"
                            >
                                {{option.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <mat-form-field>
                    <mat-label>Comments</mat-label>
                    <textarea
                        matInput
                        required
                        [formControl]="comments"
                        [errorStateMatcher]="errorMatcher"
                    ></textarea>
                </mat-form-field>
                <div *ngIf="!!updatedBusiness">

                    <h6>{{updatedBusiness.name}} UPDATED!</h6>

                    <p *ngIf="showKyc">KYC Status: {{updatedBusiness.kycStatus}}</p>
                    <p>Payment Status: {{updatedBusiness.paymentStatus}}</p>

                </div>
            </div>
        </mat-card-content>

        <mat-card-actions>

            <button
                type="button"
                mat-stroked-button
                (click)="reset()"
                [disabled]="!form.dirty"
            >
                Reset
            </button>

            <button
                type="submit"
                mat-flat-button
                [disabled]="form.invalid || (!kycStatus.dirty && !paymentStatus.dirty && !comments.dirty)"
                color="primary"
            >
                Set
            </button>

        </mat-card-actions>
    </form>


</mat-card>