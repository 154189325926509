import { Component, OnInit } from '@angular/core'
import { PageEvent } from '@angular/material/paginator'
import { Sort } from '@angular/material/sort'
import { BehaviorSubject, Observable } from 'rxjs'
import { catchError, take, tap } from 'rxjs/operators'

import { MicrodepositVerificationRequest } from '../../core/models/microdeposit-verification-request.interface'
import { BusinessService, TabService, UrlService } from '../../core/services'
import { DialogService } from '../../dialogs'
import { ListField, PageRequest } from '../../table/models'
import { TableId, Tab } from '../../core/models'
import { ReviewMicrodepositRequestDialogComponent } from '../review-micro-deposit-request-dialog/review-micro-deposit-request-dialog.component'

@Component({
  selector: 'lqd-micro-deposits-requests-review',
  templateUrl: './micro-deposits-requests-review.component.html',
  styleUrls: ['./micro-deposits-requests-review.component.scss']
})
export class MicrodepositRequestsReviewComponent {

  private results: BehaviorSubject<Array<MicrodepositVerificationRequest>> = new BehaviorSubject([])

  readonly columns: Array<ListField> = [
    {
      id: 'businessName',
      label: 'Business Name'
    },
    {
      id: 'businessType',
      label: 'Business Type'
    },
    {
      id: 'emailAddress',
      label: 'Email Address'
    },
    {
      id: 'vendorBusinessName',
      label: 'Vendor Business Name'
    },
    {
      id: 'requested',
      label: 'Requested',
      notSortable: false
    },
    {
      id: 'status',
      label: 'Review',
      notSortable: true,
      checkButtonIcon: 'edit',
      type: 'check-button',
    }
  ]
  length: number
  pageRequest: PageRequest;
  get rows$(): Observable<Array<MicrodepositVerificationRequest>> { return this.results.asObservable() }
  readonly tableId: string = TableId.microdepositRelease

  constructor(
    private businesses: BusinessService,
    private dialogs: DialogService,
    private readonly tabService: TabService,
    private readonly urls: UrlService,
  ) {

  }

  onPageChange(event: { page: PageEvent, sort: Sort }): void {
    if (!event?.page?.pageSize || event?.page?.pageIndex < 0 || !event?.sort?.active || !event?.sort?.direction) {
      return
    }

    const page: PageEvent = event.page
    const sort: Sort = event.sort
    this.pageRequest = {
      page: page.pageIndex + 1,
      pageSize: page.pageSize,
      sortBy: `${sort.active} ${sort.direction}`,
    }

    this.refreshCurrentPage();
  }

  refreshCurrentPage() {
    this.businesses.getUnreviewedMicrodepositVerificationRequests(this.pageRequest)
      .pipe(
        take(1),
        tap(data => {
          this.length = data.rowCount
          this.results.next(data.results.map(mdvr => {
            return {
              ...mdvr,
              status: true
            }
          }))
        }),
      )
      .subscribe()
  }

  onRowClick(microdepositVerifcationRequest: MicrodepositVerificationRequest): void {
    const tab: Tab = {
      label: `${microdepositVerifcationRequest.businessName}`,
      removable: true,
      route: this.urls.route.businessDetails(microdepositVerifcationRequest.businessOrganizationId),
    }
    this.tabService.addTab(tab)
  }

  onCheckButtonClick(event: { row: MicrodepositVerificationRequest }): void {
    const microdepositRequest: MicrodepositVerificationRequest = event.row

    let dialogConfig = {
      data: {
        microdepositVerificationRequest: microdepositRequest
      },
    };

    this.dialogs.open(ReviewMicrodepositRequestDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(
        tap(reviewedResult => {
          if (reviewedResult !== undefined) {
            this.refreshCurrentPage();
          }
        }),
        catchError(error => this.dialogs.error(error)),
      )
      .subscribe()
  }

}
