import { Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { User } from '@auth0/auth0-angular'
import { AuthService as Auth0Service } from '@auth0/auth0-angular'
import { of, Subject } from 'rxjs'
import { map, skipWhile, switchMap, take, takeUntil, tap } from 'rxjs/operators'

import { environment } from '../environments/environment'

import { AuthService, UserRole } from './auth/services'
import { Tab } from './core/models'
import { TabService } from './core/services'

@Component({
    selector: 'lqd-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
    private unsubscribe$: Subject<void> = new Subject<void>()

    tabs: Array<Tab>

    constructor(
        private auth: AuthService,
        private auth0: Auth0Service,
        private router: Router,
        private tabService: TabService,
    ) { }

    ngOnInit(): void {

        const env: { host: string } = environment
        if (!window.location.port && env.host !== window.location.origin) {
            window.location.href = window.location.href.replace(window.location.origin, env.host)
        }

        this.auth0.user$
            .pipe(
                take(1),
                switchMap((user: User) => {
                    if (!user) {
                        return this.auth0.loginWithRedirect({
                            // doItAs: 'auth0|e08c6f0f-bfba-4ab4-da28-08d860c2feb0',
                            // doItVer: 'MYVERIFICATIONCODE',
                        })
                    } else {
                        return of(user)
                    }
                }),
                skipWhile(user => !user),
                switchMap((user: User) => this.auth.getUserRoles(user)),
                map((userRoles: UserRole[]) => {
                    if (userRoles.includes(UserRole.ToolsAdmin)) {
                        this.tabService.initAdminTabs()
                    } else if (userRoles.includes(UserRole.ToolsSupport)) {
                        this.tabService.initSupportTabs()
                    } else {
                        window.location.href = environment.liquidAppHost
                    }
                }),
            ).subscribe()

        // this.auth.userLoggedIn$
        //     .pipe(
        //         skipWhile(user => !user),
        //         take(1),
        //         takeUntil(this.unsubscribe$),
        //         switchMap((user: User) => this.auth.getUserRoles(user)),
        //         map((userRoles: UserRole[]) => {
        //             if (userRoles.includes(UserRole.ToolsAdmin)) {
        //                 this.tabService.initAdminTabs()
        //             } else if (userRoles.includes(UserRole.ToolsSupport)) {
        //                 this.tabService.initSupportTabs()
        //             } else {
        //                 window.location.href = environment.liquidAppHost
        //             }
        //         }),
        //     )
        //     .subscribe()

        this.tabService.tabs$
            .pipe(
                takeUntil(this.unsubscribe$),
                tap(tabs => {
                    this.tabs = tabs
                }),
            )
            .subscribe()
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next()
        this.unsubscribe$.unsubscribe()
    }

    logOut(): void {
        this.auth.logOut()
        this.router.navigateByUrl('/')
    }

    removeTab(index: number): void {
        this.tabService.removeTab(index)
    }
}
