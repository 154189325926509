import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { take } from 'rxjs/operators'

import { AdminPaymentAccount } from '../../core/models'
import {
    BankAccountPropertyDef,
    CountryBankAccountInfo,
    CountryBankAccountProperty,
    CountryCurrencyConfiguration,
    ForeignUSBankAccountRequest,
    OtherBankAccountProperties,
    PropertyDataType
} from '../../country-bank-account-config'
import { CountryBankAccountInfoRequest } from '../models'

import { CurrencyStore } from './currency.store'

@Injectable({
    providedIn: 'root',
})
export class CurrencyService {

    readonly usd: string = 'USD'

    constructor(
        private store: CurrencyStore,
    ) { }

    canEditDataType(type: string): boolean {
        return type !== PropertyDataType.BOOLEAN && type !== PropertyDataType.HEADER
    }

    createOrUpdate(countryId: string, enabled: boolean, enabledCurrencyIds: Array<string>, stableCurrencyIds: Array<string>,
        properties: Array<CountryBankAccountProperty>, configured: boolean): Observable<CountryBankAccountInfo> {

        const request: CountryBankAccountInfoRequest = {
            countryId,
            enabled,
            enabledCurrencyIds,
            stableCurrencyIds,
            properties,
        }

        const request$: Observable<CountryBankAccountInfo> = configured ? this.store.update(request) : this.store.create(request)

        return request$
            .pipe(
                take(1),
            )
    }

    createPropertyDefault(request: BankAccountPropertyDef): Observable<BankAccountPropertyDef> {
        return this.store.createPropertyDefault(request)
            .pipe(
                take(1),
            )
    }

    deleteBankAccountPropertyDef(propertyId: string): Observable<void> {
        return this.store.deleteBankAccountPropertyDef(propertyId)
            .pipe(
                take(1),
            )
    }

    simulateCreateLiquidBankAccount(properties: ForeignUSBankAccountRequest): Observable<AdminPaymentAccount> {
        return this.store.simulateCreateLiquidBankAccount(properties)
            .pipe(
                take(1),
            )
    }

    simulateCreateOtherBankAccount(countryId: string, currencyId: string, properties: OtherBankAccountProperties): Observable<AdminPaymentAccount> {
        return this.store.simulateCreateOtherBankAccount(countryId, currencyId, properties)
            .pipe(
                take(1),
            )
    }

    get(countryId: string): Observable<CountryBankAccountInfo> {
        return this.store.get(countryId)
            .pipe(
                take(1),
            )
    }

    getCountries(): Observable<Array<CountryCurrencyConfiguration>> {
        return this.store.getCountries()
            .pipe(
                take(1),
            )
    }

    getPropertyDefaults(): Observable<Array<BankAccountPropertyDef>> {
        return this.store.getPropertyDefaults()
            .pipe(
                take(1),
            )
    }

    updatePropertyDefaults(request: Array<BankAccountPropertyDef>): Observable<Array<BankAccountPropertyDef>> {
        return this.store.updatePropertyDefaults(request)
            .pipe(
                take(1),
            )
    }
}
