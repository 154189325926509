import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { AdminPaymentAccount } from '../../core/models'
import { UrlService } from '../../core/services'
import {
    BankAccountPropertyDef,
    CountryBankAccountInfo,
    CountryCurrencyConfiguration,
    ForeignUSBankAccountRequest,
    OtherBankAccountProperties,
    OtherBankAccountRequest
} from '../../country-bank-account-config'
import { CountryBankAccountInfoRequest } from '../models'

@Injectable({
    providedIn: 'root',
})
export class CurrencyStore {

    constructor(
        private http: HttpClient,
        private urls: UrlService,
    ) { }

    create(request: CountryBankAccountInfoRequest): Observable<CountryBankAccountInfo> {
        return this.http.post<CountryBankAccountInfo>(this.urls.api.countryCurrencyConfigurationSave(), request)
    }

    createPropertyDefault(request: BankAccountPropertyDef): Observable<BankAccountPropertyDef> {
        return this.http.post<BankAccountPropertyDef>(this.urls.api.propertyDefaults(), request)
    }

    deleteBankAccountPropertyDef(propertyId: string): Observable<void> {
        return this.http.delete<void>(this.urls.api.deletePropertyDefault(propertyId))
    }

    get(countryId: string): Observable<CountryBankAccountInfo> {
        return this.http.get<CountryBankAccountInfo>(this.urls.api.countryCurrencyConfigurationGet(countryId))
    }

    getCountries(): Observable<Array<CountryCurrencyConfiguration>> {
        return this.http.get<Array<CountryCurrencyConfiguration>>(this.urls.api.countryCurrencyConfigurations())
    }

    getAllCurrencyCodes(): Observable<Array<string>> {
        return this.http.get<Array<string>>(this.urls.api.allCurrencies())
    }

    getAllCurrencyCodesWithoutDecimals(): Observable<Array<string>> {
        return this.http.get<Array<string>>(this.urls.api.currenciesWithoutDecimals())
    }

    getPropertyDefaults(): Observable<Array<BankAccountPropertyDef>> {
        return this.http.get<Array<BankAccountPropertyDef>>(this.urls.api.propertyDefaults())
    }

    // ForeignUSBankAccountRequest
    simulateCreateLiquidBankAccount(request: ForeignUSBankAccountRequest): Observable<AdminPaymentAccount> {
        return this.http.post<AdminPaymentAccount>(this.urls.api.liquidBankAccount(), request)
    }

    simulateCreateOtherBankAccount(countryId: string, currencyId: string, properties: OtherBankAccountProperties): Observable<AdminPaymentAccount> {

        const request: OtherBankAccountRequest = {
            bankId: properties.bankId,
            countryId,
            currencyId,
            organizationId: null, // fake guid
            overrideBankInformation: properties.overrideBankInformation,
            properties: properties.properties,
        }

        return this.http.post<AdminPaymentAccount>(this.urls.api.otherBankAccount(), request)
    }

    update(request: CountryBankAccountInfoRequest): Observable<CountryBankAccountInfo> {
        return this.http.put<CountryBankAccountInfo>(this.urls.api.countryCurrencyConfigurationSave(), request)
    }

    updatePropertyDefaults(request: Array<BankAccountPropertyDef>): Observable<Array<BankAccountPropertyDef>> {
        return this.http.put<Array<BankAccountPropertyDef>>(this.urls.api.propertyDefaults(), request)
    }
}
