import { Injectable } from '@angular/core'

import { environment } from '../../../environments/environment'
import { KycDocument } from '../models'

@Injectable({
    providedIn: 'root',
})
export class UrlService {
    readonly api: Api = new Api()
    readonly external: ExternalUrls = new ExternalUrls()
    readonly route: Route = new Route()
}

// tslint:disable-next-line: max-classes-per-file
class Api {

    private readonly currencies: string = 'currencies'
    private readonly countryBankAccount: string = 'country-bank-accounts'

    // keep in alpha order
    autoShutOffConfig(path?: string): string {
        return this.getSupportFullUrl(`configurations/${!!path ? path : ''}`)
    }

    avatarUrl(avatarUrl: string): string {
        return `${environment.liquidApiSettings.orgAvatarPrefix}/${avatarUrl}`
    }

    bankBySwiftCode(swiftCode: string): string {
        return this.getUrl(`banks/${swiftCode}`)
    }

    bankCreate(): string {
        return this.getFullUrl(`banks`)
    }

    bankDetails(id: string): string {
        return this.getFullUrl(`banks/${id}/details`)
    }

    bankDetailsBySwift(swiftCode: string): string {
        return this.getFullUrl(`banks/bySwift/${swiftCode}/details`)
    }

    banks(): string {
        return this.getFullUrl(`banks`)
    }

    bankAcceptance(): string {
        return this.getFullUrl('banks/paymentAccountsForReview/acceptance')
    }

    bannedDomains(): string {
        return this.getSupportFullUrl('domains')
    }

    bannedIPAddress(): string {
        return this.getSupportFullUrl('ipAddress')
    }

    businesses(): string {
        return this.getFullUrl('businesses')
    }

    businessById(businessId: string): string {
        return this.getFullUrl(`businesses/${businessId}`)
    }

    businessFlags(): string {
        return this.getFullUrl('businesses/flags')
    }

    businessProperties(): string {
        return this.getFullUrl('businesses/properties')
    }

    businessUnmaskField(): string {
        return this.getFullUrl('businesses/unmask')
    }

    businessUnmaskPaymentAccountField(): string {
        return this.getFullUrl('businesses/unmask-account')
    }

    cancelPayout(payoutId: string): string {
        return this.getFullUrl(`client-invoices/payouts/${payoutId}`)
    }

    clientInvoiceDetails(clientInvoiceId: string): string {
        return this.getFullUrl(`client-invoices/${clientInvoiceId}`)
    }

    clientInvoices(): string {
        return this.getFullUrl('client-invoices')
    }

    countries(): string {
        return this.getUrl('public/utils/countries')
    }

    countryCurrencyConfigurationGet(countryId: string): string {
        return this.getUrl(`${this.countryBankAccount}/${countryId}`)
    }

    countryCurrencyConfigurationSave(): string {
        return this.getFullUrl(`${this.currencies}/${this.countryBankAccount}`)
    }

    countryCurrencyConfigurations(): string {
        return this.getFullUrl(`${this.currencies}/countries`)
    }

    allCurrencies: () => string = () => this.getUrl('public/utils/currencies')

    currenciesWithoutDecimals: () => string = () => this.getUrl('public/utils/currencies-without-decimals')

    deleteBannedDomains(domainId: string): string {
        return `${this.getSupportFullUrl('domains')}/${domainId}`
    }

    deleteBannedIPAddress(ipAddressId: string): string {
        return `${this.getSupportFullUrl('ipAddress')}/${ipAddressId}`
    }

    deleteOwner(ownerId: string): string {
        return this.getFullUrl(`businesses/beneficial-owner/${ownerId}/delete`)
    }

    deleteNotification(notificationId: string): string {
        return this.getFullUrl(`notifications/${notificationId}/delete`)
    }

    deletePropertyDefault(propertyId: string): string {
        return this.getFullUrl(`${this.currencies}/propertyDefaults/${propertyId}/`)
    }

    dismissJPMTransactionDetail(): string {
        return this.getFullUrl('client-invoices/jpmTransactionDetails/dismissals')
    }

    downloadDoc(doc: KycDocument): string {
        return this.getFullUrl(`businesses/kyc-document/download/${doc.id}/${doc.originalFilename}`)
    }

    dummyAccount(): string {
        return this.getFullUrl(`businesses/dummyPayments`)
    }

    foreignBankReviewAccounts(): string {
        return this.getFullUrl(`banks/paymentAccountsForReview`)
    }

    foreignPendingAccounts(): string {
        return this.getFullUrl(`payoutAccounts/pendingOtherAccounts`)
    }

    fxRateSheets(): string {
        return this.getFullUrl(`currencies/fx/rateSheets`)
    }

    fxTransactionReports(): string {
        return this.getFullUrl(`currencies/fx/reports/transactions`)
    }

    intermediaryBankCreate(): string {
        return this.getFullUrl(`banks/intermediaryBankSetups`)
    }

    intermediaryBankDelete(id: string): string {
        return this.getFullUrl(`banks/intermediaryBankSetups/${id}`)
    }

    jpmReqForPayout(payoutId: string): string {
        return this.getFullUrl(`client-invoices/jpmReqs/payouts/${payoutId}`)
    }

    jpmTransactionDetails(jpmTransactionDetailId: string): string {
        return this.getFullUrl(`client-invoices/jpmTransactionDetails/${jpmTransactionDetailId}`)
    }

    kycDoc(): string {
        return this.getFullUrl('businesses/kyc-document')
    }

    kycStatus(): string {
        return this.getFullUrl('kyc-status')
    }

    kycUpload(businessId: string, originalFilename: string): string {
        return this.getFullUrl(`businesses/kyc-document/${businessId}/upload/${originalFilename}`)
    }

    maintenancePage(): string {
        return this.getFullUrl(`siteStatuses/maintenance`)
    }

    manualUSBankAccounts(): string {
        return this.getFullUrl(`payoutAccounts/manualLiquidBankAccounts`)
    }

    notifications(): string {
        return this.getFullUrl('notifications')
    }

    liquidBankAccount(): string {
        return this.getFullUrl(`${this.currencies}/liquid-bank-account`)
    }

    otherBankAccount(): string {
        return this.getFullUrl(`${this.currencies}/other-bank-account`)
    }

    otherBankAccountProperties(paymentAccountId: string): string {
        return `${this.otherBankAccount()}/${paymentAccountId}/properties`
    }

    owner(): string {
        return this.getFullUrl('businesses/beneficial-owner')
    }

    partialBusiness(): string {
        return this.getFullUrl('businesses/partials')
    }

    paymentStatus(): string {
        return this.getFullUrl('payment-status')
    }

    paymentRequestCheckPage(clientInvoiceId: string): string {
        return this.getFullUrl(`client-invoices/paymentChecks/${clientInvoiceId}`)
    }

    paymentTnT(e2eId: string): string {
        return this.getFullUrl(`client-invoices/jpmTnTs/${e2eId}`)
    }

    payoutAccounts(vendorId: string): string {
        return this.getFullUrl(`payoutAccounts/${vendorId}`)
    }

    payoutRequestCheckPage(clientInvoiceId: string): string {
        return this.getFullUrl(`client-invoices/payoutChecks/${clientInvoiceId}`)
    }

    pendingReleaseTotal(orgId: string): string {
        return this.getFullUrl(`businesses/${orgId}/pending-balance`)
    }

    plaidBankAccountBalance(orgId: string): string {
        return this.getFullUrl(`banks/${orgId}/plaidBankAccountBalance`)
    }

    processingEvents(clientInvoiceId: string): string {
        return this.getFullUrl(`client-invoices/processingEvents`)
    }

    propertyDefaults(): string {
        return this.getFullUrl(`${this.currencies}/propertyDefaults`)
    }

    resendPayout(): string {
        return this.getFullUrl('client-invoices/payouts/resends')
    }

    resendPayment(): string {
        return this.getFullUrl('client-invoices/payments/resends')
    }

    reviewClientInvoices(): string {
        return this.getFullUrl('client-invoices/review')
    }

    roles(): string {
        return this.getUrl('organizations/roles/serviceOrganizations')
    }

    scheduledClientInvoices(): string {
        return this.getFullUrl('client-invoices/scheduled')
    }

    subscriptionBillingRetries(): string {
        return this.getFullUrl(`businesses/subscriptionBillingRetries`)
    }

    supportLiquidProfileByOpenId(openId: string): string {
        return this.getSupportFullUrl(`profiles/${openId}`)
    }

    supportOrganizationByUserId(userId: string): string {
        return this.getSupportFullUrl(`organizations?userId=${userId}`)
    }

    supportOrganizationInfoById(id: string): string {
        return this.getSupportFullUrl(`organizations/${id}`)
    }

    unassociatedJPMTransactions(): string {
        return this.getFullUrl(`client-invoices/unassociated-jpm-transactions`)
    }

    unreviewedMicrodepositVerificationRequests(): string {
        return this.getFullUrl('payoutAccounts/unreviewedMicrodepositRequests')
    }

    unverifiedLiquidAccounts(): string {
        return this.getFullUrl('payoutAccounts/unverifiedLiquidAccounts')
    }

    users(): string {
        return this.getFullUrl('users')
    }

    getImpersonationLinkUrl(): string {
        return this.getFullUrl('impersonations')
    }

    verifyEmail(): string {
        return this.getFullUrl('users/emails/verifications')
    }

    verifyOtherAccount(): string {
        return this.getFullUrl('payoutAccounts/verifications')
    }

    readonly youUp: () => string = () => this.getUrl(`youUp`)

    private getFullUrl(path: string): string {
        return `${environment.liquidApiSettings.apiServicePrefix}/admin/${path}`
    }

    private getSupportFullUrl(path: string): string {
        return `${environment.liquidApiSettings.apiServicePrefix}/support/${path}`
    }

    private getUrl(path: string): string {
        return `${environment.liquidApiSettings.apiServicePrefix}/${path}`
    }
}

// tslint:disable-next-line: max-classes-per-file
class ExternalUrls {

    // keep in alpha order
    get createEin(): string { return 'https://goliquid.io/blog/ein-employer-identification-number/' }
}

// tslint:disable-next-line: max-classes-per-file
class Route {

    // keep in alpha order
    bankDetails(id: string): string {
        return `/banks/${id}`
    }

    businessDetails(id: string): string {
        return `/businesses/${id}`
    }

    clientInvoiceDetails(clientInvoiceId: string): string {
        return `/clientInvoice/${clientInvoiceId}`
    }

    countryDetails(countryId: string): string {
        return `${this.currencyTools()}/${countryId}`
    }

    currencyTools(): string {
        return `/currencies`
    }

    editDefaults(): string {
        return `${this.currencyTools()}/defaults`
    }

    home(): string {
        return ``
    }

    simulateCountryBankAccountConfig(countryId: string): string {
        return `${this.currencyTools()}/simulate/${countryId}`
    }

    support(): string {
        return '/support'
    }
}
