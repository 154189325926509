import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { PagedSet, PageRequest } from '../../table/models'
import { TransactionFactory } from '../factories'
import { AdminClientInvoiceDetails, AdminJPMTransactionDetails, AdminTransaction, ClientInvoiceProcessingEvent, JPMTransactionCheck } from '../models'

import { UrlService } from './url.service'

@Injectable({
    providedIn: 'root',
})
export class ClientInvoiceStore {
    private readonly clientInvoicesUrl: string = this.urls.api.clientInvoices()

    constructor(
        private http: HttpClient,
        private factory: TransactionFactory,
        private urls: UrlService,
    ) { }

    dismissJPMTransactionDetail(jpmTransactionDetailId: string, dismissalNote: string): Observable<AdminJPMTransactionDetails> {
        return this.http.post<AdminJPMTransactionDetails>(
            this.urls.api.dismissJPMTransactionDetail(),
            {
                jpmTransactionDetailId,
                dismissalNote,
            },
        )
    }

    get(req: PageRequest): Observable<PagedSet<AdminTransaction>> {

        const request: PageRequest = this.factory.CreatePageRequest(req)

        let params: HttpParams = new HttpParams()
        Object.keys(request).filter(key => req.hasOwnProperty(key)).forEach(key => params = params.set(key, request[key]))

        return this.http.get<PagedSet<AdminTransaction>>(this.clientInvoicesUrl, { params })
    }

    getInReview(req: PageRequest): Observable<PagedSet<AdminTransaction>> {
        let params: HttpParams = new HttpParams()
        Object.keys(req).filter(key => req.hasOwnProperty(key)).forEach(key => params = params.set(key, req[key]))

        return this.http.get<PagedSet<AdminTransaction>>(this.urls.api.reviewClientInvoices(), { params })
    }

    getScheduled(req: PageRequest): Observable<PagedSet<AdminTransaction>> {
        let params: HttpParams = new HttpParams()
        Object.keys(req).filter(key => req.hasOwnProperty(key)).forEach(key => params = params.set(key, req[key]))

        return this.http.get<PagedSet<AdminTransaction>>(this.urls.api.scheduledClientInvoices(), { params })
    }

    getPaymentRequestCheckPage(clientInvoiceId: string, page: PageRequest): Observable<PagedSet<JPMTransactionCheck>> {
        let params: HttpParams = new HttpParams()
        Object.keys(page).filter(key => page.hasOwnProperty(key)).forEach(key => params = params.set(key, page[key]))

        return this.http.get<PagedSet<JPMTransactionCheck>>(this.urls.api.paymentRequestCheckPage(clientInvoiceId), { params })
    }

    getPayoutRequestCheckPage(clientInvoiceId: string, page: PageRequest): Observable<PagedSet<JPMTransactionCheck>> {
        let params: HttpParams = new HttpParams()
        Object.keys(page).filter(key => page.hasOwnProperty(key)).forEach(key => params = params.set(key, page[key]))

        return this.http.get<PagedSet<JPMTransactionCheck>>(this.urls.api.payoutRequestCheckPage(clientInvoiceId), { params })
    }

    getProcessingEvents(clientInvoiceId: string, page: PageRequest): any {
        let params: HttpParams = new HttpParams()
        params = params.set('clientInvoiceId', clientInvoiceId)
        Object.keys(page).filter(key => page.hasOwnProperty(key)).forEach(key => params = params.set(key, page[key]))

        return this.http.get<PagedSet<ClientInvoiceProcessingEvent>>(this.urls.api.processingEvents(clientInvoiceId), { params })
    }

    getInvoiceDetails(clientInvoiceId: string): Observable<AdminClientInvoiceDetails> {
        return this.http.get<AdminClientInvoiceDetails>(this.urls.api.clientInvoiceDetails(clientInvoiceId))
    }

    getJPMReqForPayout(payoutId: string): Observable<any> {
        return this.http.get<any>(this.urls.api.jpmReqForPayout(payoutId))
    }

    getJPMTransactionDetails(jpmTransactionDetailId: string): Observable<AdminJPMTransactionDetails> {
        return this.http.get<AdminJPMTransactionDetails>(this.urls.api.jpmTransactionDetails(jpmTransactionDetailId))
    }

    getPaymentTnT(e2eId: string): Observable<any> {
        return this.http.get<any>(this.urls.api.paymentTnT(e2eId))
    }

    getUnassociatedJPMTransactions(page: PageRequest): Observable<PagedSet<AdminJPMTransactionDetails>> {
        let params: HttpParams = new HttpParams()
        Object.keys(page).filter(key => page.hasOwnProperty(key)).forEach(key => params = params.set(key, page[key]))

        return this.http.get<PagedSet<AdminJPMTransactionDetails>>(this.urls.api.unassociatedJPMTransactions(), { params })
    }

    setScheduledPaymentReviewAction(clientInvoiceId: string, action: string, message: string): Observable<AdminClientInvoiceDetails> {
        return this.http.put<AdminClientInvoiceDetails>(
            this.urls.api.reviewClientInvoices(),
            {
                clientInvoiceId,
                action,
                message,
            },
        )
    }

    cancelPayout(payoutId: string): Observable<Object> {
        return this.http.delete(
            this.urls.api.cancelPayout(payoutId),
        )
    }

    resendPayout(clientInvoiceId: string): Observable<boolean> {
        return this.http.post<boolean>(
            this.urls.api.resendPayout(),
            {
                clientInvoiceId,
            },
        )
    }

    resendPayment(clientInvoiceId: string, bypassPayout: boolean): Observable<boolean> {
        return this.http.post<boolean>(
            this.urls.api.resendPayment(),
            {
                clientInvoiceId,
                bypassPayout,
            },
        )
    }
}
