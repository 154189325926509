import { Component, Inject } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { tap } from 'rxjs/operators'

import { AdminBusinessDetail, AdminPaymentAccount, DefaultLabel, PaymentAccountType } from '../../core/models'
import { BusinessService } from '../../core/services'

@Component({
    selector: 'lqd-unmask-dialog',
    templateUrl: './unmask-dialog.component.html',
    styleUrls: ['./unmask-dialog.component.scss'],
})
export class UnmaskDialogComponent {

    biz: AdminBusinessDetail
    paymentAccount: AdminPaymentAccount
    form: FormGroup
    message: FormControl
    unmasking: boolean

    constructor(
        private business: BusinessService,
        @Inject(MAT_DIALOG_DATA) data: { biz?: AdminBusinessDetail, paymentAccount?: AdminPaymentAccount },
        public dialogRef: MatDialogRef<UnmaskDialogComponent>,
    ) {
        this.biz = data.biz
        this.paymentAccount = data.paymentAccount
        this.message = new FormControl(undefined)
        this.form = new FormGroup({
            message: this.message,
        })
    }

    submitUnmaskRequest(): void {
        this.unmasking = true
        if (!!this.biz) {
            this.business.unmaskField(this.biz.id, 'taxId', this.message.value)
                .pipe(
                    tap(unmasked => this.close(unmasked)),
                )
                .subscribe()
        } else if (!!this.paymentAccount) {
            if (this.paymentAccount.paymentAccountType === PaymentAccountType.LiquidBankAccount
                || this.paymentAccount.paymentAccountType === PaymentAccountType.PlaidBank) {
                this.business.unmaskPaymentAccountFields(this.paymentAccount.organizationId, this.paymentAccount.id, this.paymentAccount.paymentAccountType, ['accountNumber', 'routingNumber'], this.message.value)
                    .pipe(
                        tap(unmasked => this.close(unmasked)),
                    )
                    .subscribe()
            } else if (this.paymentAccount.paymentAccountType === PaymentAccountType.OtherBankAccount) {
                this.business.unmaskPaymentOtherBankAccountFields(this.paymentAccount.organizationId, this.paymentAccount.id, [DefaultLabel.BankAccountNumber, DefaultLabel.Iban], this.message.value)
                    .pipe(
                        tap(unmasked => this.close(unmasked)),
                    )
                    .subscribe()
            }
        }
    }

    close(unmasked?: string | { [key: string]: string }): void {
        this.dialogRef.close(unmasked)
        this.unmasking = false
    }

}
