import { Component, Inject } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { tap } from 'rxjs/operators'

import { AddressService } from '../../address'
import { AdminBusinessDetail, PaymentAccountDto, PaymentAccountStatus, PaymentAccountType, SelectOption } from '../../core/models'
import { BusinessService } from '../../core/services'
import { OtherBankAccountProperty } from '../../country-bank-account-config'

@Component({
    selector: 'lqd-verify-dialog',
    templateUrl: './verify-dialog.component.html',
    styleUrls: ['./verify-dialog.component.scss'],
})
export class VerifyDialogComponent {

    readonly statusOptions: Array<SelectOption<string>> = [
        {
            label: 'In Review',
            value: PaymentAccountStatus.InReview,
        },
        {
            label: 'Verify',
            value: PaymentAccountStatus.Verified,
        },
        {
            label: 'Reject',
            value: PaymentAccountStatus.Rejected,
        },
    ]

    business: AdminBusinessDetail
    details: Array<OtherBankAccountProperty>
    displayAddress: string
    form: FormGroup
    isMessageSet: boolean
    isVerifiable: boolean
    readonly message: FormControl = new FormControl(undefined, Validators.required)
    paymentAccount: PaymentAccountDto
    readonly status: FormControl
    wasRejected: boolean

    constructor(
        private addresses: AddressService,
        private businesses: BusinessService,
        @Inject(MAT_DIALOG_DATA) data: {
            account: PaymentAccountDto,
            business: AdminBusinessDetail,
            details: Array<OtherBankAccountProperty>,
        },
        private dialogRef: MatDialogRef<VerifyDialogComponent>,
    ) {
        this.business = data.business
        this.status = new FormControl(data.account.currentVerificationStatus, Validators.required)
        this.form = new FormGroup({
            'message': this.message,
            'status': this.status,
        })
        this.message.valueChanges.pipe(tap((newMessage: string) => this.isMessageSet = !!newMessage)).subscribe()
        this.details = data.details
        this.displayAddress = this.addresses.getAddressString(this.business.address)
        this.paymentAccount = data.account
        this.wasRejected = data.account.currentVerificationStatus === PaymentAccountStatus.Rejected
        this.isVerifiable = data.account.paymentAccountType !== PaymentAccountType.PlaidBank
    }

    handleClose(): void {
        this.dialogRef.close()
    }

    submitVerify(): void {
        this.businesses.verifyOtherAccount(this.business.id, this.paymentAccount.id, this.status.value, this.message.value)
            .pipe(
                tap(account => {
                    this.dialogRef.close(account)
                }),
            )
            .subscribe()
    }
}
