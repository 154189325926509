<h3>Unassociated Transactions From Liquid CMA</h3>
<app-table
    [id]="tableId"
    class="data-table"
    [noStick]="true"
    [columns]="columns"
    [length]="length"
    [defaultSort]="{ active: 'created', direction: 'desc' }"
    [data$]="rows$"
    (rowClick)="onRowClick($event)"
    [customPaging]="true"
    [persistPage]="true"
    (pageChange)="onPageChange($event)"
    readonlyModeContainerClasses="overflow-x-scroll"
></app-table>