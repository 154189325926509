<h5 mat-dialog-title>Transaction</h5>
<mat-dialog-content class="dialog-with-desc">
    <div *ngIf="dismissable">
        <h6>Dismiss</h6>
        <div>
            <mat-form-field>
                <mat-label>Dismissal Note</mat-label>
                <textarea
                    matInput
                    required
                    [formControl]="dismissalNote"
                ></textarea>
            </mat-form-field>

            <mat-dialog-actions>
                <div class="button-container">
                    <button
                        mat-flat-button
                        color="primary"
                        [disabled]="form.invalid"
                        (click)="submitDismissal()"
                    >
                        Dismiss Transaction
                    </button>
                </div>
            </mat-dialog-actions>
        </div>
        <div style="min-height: 30px;">&nbsp;</div>
    </div>
    <div *ngFor="let item of details | keyvalue">
        <ng-container *ngIf="item.key !== 'narrativeTextJson' && !excludeKey(item.key)">
            {{item.key}}: <b>{{item.value}}</b>
        </ng-container>
    </div>
    <ng-container *ngIf="narrativeTextObj">
        <h6>Narrative Text:</h6>
        <div *ngFor="let nt of narrativeTextObj | keyvalue">
            {{nt.key}}: <b>{{nt.value}}</b>
        </div>
    </ng-container>
</mat-dialog-content>