import { Component, Input, OnInit } from '@angular/core'

import { Address, AddressService } from '../../address'
import { OtherBankAccountProperty, PropertyDataType } from '../../country-bank-account-config'

@Component({
    selector: 'lqd-other-bank-account-details',
    templateUrl: './other-bank-account-details.component.html',
    styleUrls: ['./other-bank-account-details.component.scss'],
})
export class OtherBankAccountDetailsComponent implements OnInit {

    @Input() details: Array<OtherBankAccountProperty>

    readonly PropertyDataType: typeof PropertyDataType = PropertyDataType

    constructor(
        private addresses: AddressService,
    ) { }

    ngOnInit(): void {
        this.details = this.details.sort((a, b) => a.countryBankAccountProperty.order - b.countryBankAccountProperty.order)
            .map(deets => {

                // if this a child, don't display
                if (!!deets.countryBankAccountProperty.parentId) {
                    return undefined
                }

                // if this isn't a parent, just return the property
                if (!this.details.some(d => d.countryBankAccountProperty.parentId === deets.countryBankAccountProperty.id)) {
                    return deets
                }

                // if this is a parent address, populate it from its children
                if (deets.countryBankAccountProperty.bankAccountPropertyDef.dataType === PropertyDataType.ADDRESS) {
                    deets.value = this.addresses.getAddressString(this.getAddress(deets.countryBankAccountProperty.id, this.details))
                    return deets
                }

            })
            .filter(deets => deets !== undefined)
    }

    getLabel(prop: OtherBankAccountProperty): string {
        return prop.countryBankAccountProperty.label === 'Business or Personal' ? 'Is Personal Account' : prop.countryBankAccountProperty.label // TODO: make bools more flexible
    }

    getValue(prop: OtherBankAccountProperty): string {
        const value: string = prop.value
        if (prop.countryBankAccountProperty.bankAccountPropertyDef.dataType !== PropertyDataType.ADDRESS || value === undefined) {
            return value
        }

        try {
            const address: Address = JSON.parse(value)
            return this.addresses.getAddressString(address)
        } catch {
            return value
        }
    }

    private getAddress(parentId: string, properties: Array<OtherBankAccountProperty>): Address {
        const addressChildProps: Array<OtherBankAccountProperty> = properties.filter(prop => !!prop.countryBankAccountProperty.parentId && prop.countryBankAccountProperty.parentId === parentId)
        const address: Address = {
            street1: addressChildProps.find(p => p.countryBankAccountProperty.bankAccountPropertyDef.defaultLabel.toLowerCase() === 'street1')?.value,
            street2: addressChildProps.find(p => p.countryBankAccountProperty.bankAccountPropertyDef.defaultLabel.toLowerCase() === 'street2')?.value,
            city: addressChildProps.find(p => p.countryBankAccountProperty.bankAccountPropertyDef.defaultLabel.toLowerCase() === 'city')?.value,
            state: addressChildProps.find(p => p.countryBankAccountProperty.bankAccountPropertyDef.defaultLabel.toLowerCase() === 'state')?.value,
            country: addressChildProps.find(p => p.countryBankAccountProperty.bankAccountPropertyDef.defaultLabel.toLowerCase() === 'country')?.value,
            postalCode: addressChildProps.find(p => p.countryBankAccountProperty.bankAccountPropertyDef.defaultLabel.toLowerCase() === 'postalcode')?.value,
        }
        return address
    }

}
