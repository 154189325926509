<mat-form-field>

    <mat-label>{{label}}</mat-label>

    <input
        class="fs-mask"
        matInput
        [formControl]="taxId"
        autocomplete="off"
        (blur)="formatEinControl()"
        [required]="!required$ || (required$ | async)"
        [errorStateMatcher]="errorMatcher"
    >

    <mat-hint *ngIf="isSsnOrItin && !hideHint">
        <div>
            We highly recommend you <a
                [href]="createEinUrl"
                target="_blank"
                tabindex="-1"
            >apply for an EIN</a>.
        </div>
        <i-feather
            name="info"
            matTooltip="Individuals can apply for EINs, which are safer than SSNs."
        ></i-feather>
    </mat-hint>
    <mat-hint *ngIf="!isSsnOrItin && !hideHint">
        <a (click)="popEINInfo()">Where can I find my EIN?</a>
    </mat-hint>

    <mat-error *ngIf="taxId.errors?.required">
        You must enter the {{label}} for your business
    </mat-error>

    <mat-error *ngIf="taxId.errors?.invalid">
        Enter a valid {{label}} (e.g. {{isSsnOrItin ? ssnOrItinHint : einHint}})
    </mat-error>

</mat-form-field>