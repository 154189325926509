import { DragDropModule } from '@angular/cdk/drag-drop'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatChipsModule } from '@angular/material/chips'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatTabsModule } from '@angular/material/tabs'
import { MatTooltipModule } from '@angular/material/tooltip'
import { FeatherModule } from 'angular-feather'
import { Eye, EyeOff } from 'angular-feather/icons'

import { AddressModule } from '../address'
import { BankAccountCardModule } from '../bank-account-card'
import { BeneficialOwnerFormComponent } from '../beneficial-owner-form/beneficial-owner-form.component'
import { BusinessFunctionFormComponent } from '../business-function-form/business-function-form.component'
import { BusinessesComponent } from '../businesses/businesses.component'
import { ChipListModule } from '../chip-list/chip-list.module'
import { ClientInvoiceAssociatedInfoComponent } from '../client-invoice-associated-info/client-invoice-associated-info.component'
import { JsonDisplayDialogComponent } from '../client-invoice-associated-info/json-display-dialog/json-display-dialog.component'
import { ClientInvoiceComponent } from '../client-invoice/client-invoice.component'
import { ClientInvoicesListComponent } from '../client-invoices-list/client-invoices-list.component'
import { CountryBankAccountConfigModule } from '../country-bank-account-config/country-bank-account-config.module'
import { KycFormComponent } from '../kyc-form/kyc-form.component'
import { LogDialogComponent } from '../log-dialog/log-dialog.component'
import { LogsComponent } from '../logs/logs.component'
import { PipesModule } from '../pipes'
import { ReviewDialogComponent } from '../review-client-invoices/review-dialog/review-dialog.component'
import { StatusFormComponent } from '../status-form/status-form.component'
import { TableModule } from '../table/table.module'
import { TaxIdFieldModule } from '../tax-id-field'
import { UserTableComponent } from '../users/users.component'

import { BusinessRoutingModule } from './business-routing.module'
import { BusinessComponent } from './business.component'
import { EditFlagsDialogComponent } from './edit-flags-dialog/edit-flags-dialog.component'
import { EditOwnerDialogComponent } from './edit-owner-dialog/edit-owner-dialog.component'
import { KycDocumentDetailsDialogComponent } from './kyc-document-details-dialog/kyc-document-details-dialog.component'
import { ManualUSBankAccountFormComponent } from './manual-usbank-account-form/manual-usbank-account-form.component'
import { PaymentDetailsDialogComponent } from './payment-details-dialog/payment-details-dialog.component'
import { UnmaskDialogComponent } from './unmask-dialog/unmask-dialog.component'

const icons: { [key: string]: string } = {
    Eye,
    EyeOff,
}

@NgModule({
    declarations: [
        BusinessComponent,
        BeneficialOwnerFormComponent,
        BusinessFunctionFormComponent,
        BusinessesComponent,
        ClientInvoiceAssociatedInfoComponent,
        ClientInvoiceComponent,
        ClientInvoicesListComponent,
        EditFlagsDialogComponent,
        EditOwnerDialogComponent,
        JsonDisplayDialogComponent,
        KycDocumentDetailsDialogComponent,
        KycFormComponent,
        LogsComponent,
        LogDialogComponent,
        ManualUSBankAccountFormComponent,
        PaymentDetailsDialogComponent,
        ReviewDialogComponent,
        StatusFormComponent,
        UnmaskDialogComponent,
        UserTableComponent,
    ],
    imports: [
        AddressModule,
        BankAccountCardModule,
        BusinessRoutingModule,
        ChipListModule,
        CommonModule,
        CountryBankAccountConfigModule,
        DragDropModule,
        FeatherModule.pick(icons),
        FormsModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatTooltipModule,
        PipesModule,
        ReactiveFormsModule,
        TableModule,
        TaxIdFieldModule,
    ],
})
export class BusinessModule { }
