<div class="nav-search">
    <mat-form-field
        class="full-width search-field"
        appearance="fill"
    >
        <input
            class="search-input"
            matInput
            placeholder="Search"
            [matAutocomplete]="auto"
            [formControl]='searchInput'
            autocomplete="off"
        >
        <i-feather
            name="search"
            matSuffix
        ></i-feather>
        <mat-autocomplete
            #auto="matAutocomplete"
            (optionSelected)="searchResultSelected($event)"
            [displayWith]="searchResultDisplayFn"
        >
            <ng-container *ngIf="!isSearching && searchResults">
                <mat-optgroup
                    *ngFor="let group of searchResultGroups"
                    [label]="group.groupName"
                >
                    <mat-option
                        *ngFor="let result of group.searchResults ; let i = index"
                        [value]="group.searchResults[i]"
                    >
                        <div class="result">
                            <img
                                class="avatar"
                                [src]="result.iconUrl"
                                *ngIf="result.iconUrl && result.label === 'Organization'"
                            >
                            <i-feather
                                name="briefcase"
                                class="placeholder site-icon"
                                *ngIf="!result.iconUrl  && result.label === 'Organization'"
                            ></i-feather>
                            <i
                                class="material-icons placeholder"
                                *ngIf="result.label === 'Work Order'"
                            >pages</i>
                            <span class="name">
                                {{result.name}}
                            </span>
                        </div>
                    </mat-option>
                </mat-optgroup>
            </ng-container>
        </mat-autocomplete>
    </mat-form-field>
</div>
<lqd-liquid-accounts></lqd-liquid-accounts>
<lqd-foreign-accounts></lqd-foreign-accounts>
<lqd-foreign-banks-review></lqd-foreign-banks-review>
<lqd-review-client-invoices></lqd-review-client-invoices>
<lqd-unassociated-jpm-transactions></lqd-unassociated-jpm-transactions>
<lqd-micro-deposits-requests-review></lqd-micro-deposits-requests-review>