<mat-card-content>

    <h5>Admin Logs</h5>

    <app-table
        [id]="id"
        class="data-table"
        [noStick]="true"
        [columns]="logColumns"
        [length]="logs.length"
        [defaultSort]="{ active: 'requestDateTime', direction: 'desc' }"
        [data$]="logs$"
        (rowClick)="onRowClick($event)"
        readonlyModeContainerClasses="overflow-x-scroll"
    ></app-table>

</mat-card-content>