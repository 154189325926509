export enum LogEvent {
    BeneficialOwnerAdded = 'Beneficial Owner Added',
    BeneficialOwnerDeleted = 'Beneficial Owner Deleted',
    BeneficialOwnerUpdate = 'Beneficial Owner Updated',
    BusinessFieldUnmask = 'Business Field Unmasked',
    BusinessFlagUpdate = 'Business Flags Updated',
    DummyAccountAdded = 'Dummy Payment Accounts Added',
    KycDocumentAdded = 'Kyc Document Added',
    KycStatusUpdate = 'Kyc Status Updated',
    KycStatusUpdatePreview = 'KYC Status Update Preview',
    NotFound = 'Log Event Not Found',
    PaymentAccountFieldUnmask = 'Payment Account Field Unmasked',
    PaymentStatusUpdate = 'Payment Status Updated',
    PaymentStatusUpdatePreview = 'Payment Status Update Preview',
    TransactionStatusUpdate = 'Transaction Status Updated',
}
