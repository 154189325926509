import { Component, OnInit } from '@angular/core'
import { PageEvent } from '@angular/material/paginator'
import { Sort } from '@angular/material/sort'
import { Observable } from 'rxjs'
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject'
import { catchError, filter, map, take, tap } from 'rxjs/operators'

import { AddressService } from '../../address'
import { PaymentAccountDto, PaymentAccountStatus, Tab, TableId } from '../../core/models'
import { BusinessService, TabService, UrlService } from '../../core/services'
import { DialogService } from '../../dialogs'
import { ListField, PageRequest } from '../../table/models'
import { VerifyAccountService } from '../services/verify-account.service'

@Component({
    selector: 'lqd-liquid-accounts',
    templateUrl: './liquid-accounts.component.html',
    styleUrls: ['./liquid-accounts.component.scss'],
})
export class LiquidAccountsComponent implements OnInit {

    readonly columns: Array<ListField> = [
        {
            id: 'createdTimestamp',
            label: 'Created',
            type: 'timestamp-long',
        },
        {
            id: 'accountHolderName',
            label: 'Account Holder Name',
        },
        {
            id: 'country',
            label: 'Country',
        },
        {
            id: 'currentVerificationStatus',
            label: 'In Review',
            notSortable: true,
            checkButtonIcon: 'check-circle',
            type: 'check-button',
        },
        {
            id: 'status',
            label: 'Verify',
            notSortable: true,
            checkButtonIcon: 'check',
            type: 'check-button',
        },
    ]
    length: number
    results: BehaviorSubject<Array<PaymentAccountDto>> = new BehaviorSubject([])
    readonly tableId: TableId = TableId.unverifiedLiquidAccounts

    get rows$(): Observable<Array<PaymentAccountDto>> { return this.results.asObservable() }

    constructor(
        private readonly addresses: AddressService,
        private readonly businesses: BusinessService,
        private readonly dialogs: DialogService,
        private readonly tabService: TabService,
        private readonly urls: UrlService,
        private readonly verifyAccounts: VerifyAccountService,
    ) { }

    ngOnInit(): void {
        this.addresses.initialize().subscribe()
    }

    onPageChange(event: { page: PageEvent, sort: Sort }): void {

        if (!event?.page?.pageSize || event?.page?.pageIndex < 0 || !event?.sort?.active || !event?.sort?.direction) {
            return
        }

        const page: PageEvent = event.page
        const sort: Sort = event.sort
        const pageRequest: PageRequest = {
            page: page.pageIndex + 1,
            pageSize: page.pageSize,
            sortBy: `${sort.active} ${sort.direction}`,
        }

        this.businesses.getUnverifiedLiquidAccounts(pageRequest)
            .pipe(
                take(1),
                tap(data => {
                    this.length = data.rowCount
                    this.results.next(data.results.map(acct => {
                        return {
                            ...acct,
                            currentVerificationStatus: acct.currentVerificationStatus === PaymentAccountStatus.InReview ? acct.currentVerificationStatus : undefined,
                        }
                    }))
                }),
            )
            .subscribe()
    }

    onRowClick(account: PaymentAccountDto): void {
        const tab: Tab = {
            label: `${account.accountHolderName}`,
            removable: true,
            route: this.urls.route.businessDetails(account.organizationId),
        }
        this.tabService.addTab(tab)
    }

    verifyAccount(event: { row: PaymentAccountDto }): void {
        this.verifyAccounts.verifyAccount(event.row)
            .pipe(
                filter(updatedAccount => !!updatedAccount),
                map(updatedAccount => {
                    const results: Array<PaymentAccountDto> = this.results.value
                    const updatedIndex: number = results.findIndex(account => account.id === updatedAccount.id)
                    const accounts: Array<PaymentAccountDto> = results

                    if ([PaymentAccountStatus.Verified, PaymentAccountStatus.Rejected]
                        .includes(updatedAccount.currentVerificationStatus)) {

                        accounts.splice(updatedIndex, 1)
                    } else {
                        accounts[updatedIndex] = updatedAccount
                    }
                    return accounts
                }),
                tap(accounts => this.results.next(accounts)),
                catchError(error => this.dialogs.error(error)),
            )
            .subscribe()
    }
}
