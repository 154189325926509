
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FeatherModule } from 'angular-feather'

import { AddressModule } from '../address'
import { SharedModule } from '../shared/shared.module'
import { TableModule } from '../table/table.module'

import { UnassociatedJPMTransactionsComponent } from './unassociated-jpm-transactions.component'

@NgModule({
    declarations: [
        UnassociatedJPMTransactionsComponent,
    ],
    imports: [
        AddressModule,
        CommonModule,
        FeatherModule,
        SharedModule,
        TableModule,
    ],
    exports: [
        UnassociatedJPMTransactionsComponent,
    ],
})
export class UnassociatedJPMTransactionsModule { }
