import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete'
import { of, Subject } from 'rxjs'
import { debounceTime, finalize, switchMap, takeUntil, tap } from 'rxjs/operators'

import { environment } from '../../environments/environment'
import { Tab } from '../core/models'
import { TabService, UrlService } from '../core/services'
import { SearchResult } from '../search/models'
import { SearchService } from '../search/services'

@Component({
    selector: 'lqd-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnDestroy, OnInit {

    private unsubscribe$: Subject<void> = new Subject()

    isSearching: boolean
    searchInput: FormControl = new FormControl()
    searchResultGroups: { groupName: string, searchResults: SearchResult[] }[] = []
    searchResults: SearchResult[]

    constructor(
        private searchService: SearchService,
        private tabService: TabService,
        private urls: UrlService,
    ) {
    }

    ngOnInit(): void {
        this.searchInput.valueChanges
            .pipe(
                takeUntil(this.unsubscribe$),
                debounceTime(400),
                tap(() => this.isSearching = true),
                switchMap(value => {
                    if (!value.id && value.trim().length > 0) {
                        return this.searchService.adminSearch(value, 1, 10)
                            .pipe(
                                finalize(() => this.groupResults()),
                            )
                    } else {
                        return of(undefined)
                    }
                }),
            )
            .subscribe(results => this.searchResults = results?.results)
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next()
        this.unsubscribe$.unsubscribe()
    }

    searchResultDisplayFn(result: any): string {
        if (result && result.name) {
            return result.name
        } else {
            return result
        }
    }

    searchResultSelected(event: MatAutocompleteSelectedEvent): void {
        const result: SearchResult = event.option.value
        if (result.label === 'Organization') {
            // go to the organization details:
            const tab: Tab = {
                label: result.name,
                removable: true,
                route: this.urls.route.businessDetails(result.id),
            }
            this.tabService.addTab(tab)
        }
    }

    private groupResults(): void {
        if (this.searchResults) {
            this.searchResultGroups = []
            this.searchResults.forEach(result => {
                let groupName: string = 'Others'
                switch (result.label) {
                    case 'Organization':
                        groupName = 'Also on Liquid'
                        break
                    case 'Work Order':
                        groupName = 'Our Work Orders'
                        break
                    default:
                        groupName = 'Others'
                        break
                }
                result.iconUrl = !!result.iconUrl ? this.urls.api.avatarUrl(result.iconUrl) : undefined
                let group: { groupName: string, searchResults: SearchResult[] } = this.searchResultGroups.find(g => g.groupName === groupName)
                if (!group) {
                    this.searchResultGroups.push(group = { groupName, searchResults: [] })
                }
                group.searchResults.push(result)
            })
        }
        this.isSearching = false
    }
}
