import { Injectable } from '@angular/core'

import { PageRequest } from '../../table/models'

@Injectable({
    providedIn: 'root',
})
export class TransactionFactory {

    public CreatePageRequest(request: PageRequest): PageRequest {

        // handle the property name converstoins for vendors and clients
        // TODO: the sortBy should be an object: { sort: string; direction: 'asc' | 'desc' }
        const sortParts: Array<string> = request.sortBy.split(' ')
        let sortField: string = sortParts[0]
        if (sortField === 'client') {
            sortField = 'ClientName'
        } else if (sortField === 'vendor') {
            sortField = 'InvoicerName'
        }
        const sortDirection: string = sortParts[1]
        request.sortBy = `${sortField} ${sortDirection}`

        return request
    }
}
