import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { catchError, map, switchMap, take, tap } from 'rxjs/operators'

import { environment } from '../../../environments/environment'
import { DialogService } from '../../dialogs'
import { PagedSet, PageRequest } from '../../table/models'
import { AdminUser } from '../models'

import { UserStore } from './user.store'

@Injectable({
    providedIn: 'root',
})
export class UserService {

    constructor(
        private dialogs: DialogService,
        private store: UserStore,
    ) { }

    get(req: PageRequest): Observable<PagedSet<AdminUser>> {
        return this.store.get(req)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    getImpersonationLink(impersonatedLiquidProfileId: string): Observable<string> {
        return this.store.getImpersonationLink(impersonatedLiquidProfileId)
            .pipe(
                take(1),
                map((result: any) => {
                    const prefix: string = environment.liquidAppHost
                    return `${prefix}?doItAs=${result.impersonatedLiquidProfileId}&doItVer=${result.id}`
                }),
                catchError(error => this.dialogs.error(error)),
            )
    }

    verifyEmailAddress(emailId: string): Observable<any> {
        return this.store.verifyEmail(emailId)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

}
