import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { ErrorStateMatcher } from '@angular/material/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ActivatedRoute } from '@angular/router'
import { Observable, of } from 'rxjs'
import { switchMap, tap } from 'rxjs/operators'

import { AdminBusiness, KycStatus, PaymentStatus, SelectOption } from '../core/models'
import { KycStatusService, PaymentStatusService } from '../core/services'
import { DirtyOrTouchedErrorStateMatcher } from '../validation'

@Component({
    selector: 'lqd-status-form',
    templateUrl: './status-form.component.html',
    styleUrls: ['./status-form.component.scss'],
})
export class StatusFormComponent implements OnInit {

    private readonly businessIdField: string = 'businessId'
    private readonly commentsField: string = 'comments'
    private readonly paymentStatusField: string = 'paymentStatus'
    private readonly kycStatusField: string = 'kycStatus'

    readonly errorMatcher: ErrorStateMatcher = new DirtyOrTouchedErrorStateMatcher()

    @Input() id: string
    @Input() showBiz: boolean = false
    @Input() showKyc: boolean = false

    @Output() updated: EventEmitter<void> = new EventEmitter<void>()

    businessId: FormControl = new FormControl(undefined)
    comments: FormControl = new FormControl(undefined)
    kycStatus: FormControl = new FormControl(undefined)
    paymentStatus: FormControl = new FormControl(undefined)
    form: FormGroup
    updatedBusiness: AdminBusiness

    kycStatusOptions: Array<SelectOption<KycStatus>> = [
        {
            label: 'No KYC Status',
            value: undefined,
        },
    ]

    paymentStatusOptions: Array<SelectOption<PaymentStatus>> = [
        {
            label: 'No Payment Status',
            value: undefined,
        },
    ]

    constructor(
        private kycStatuses: KycStatusService,
        private paymentStatuses: PaymentStatusService,
        private route: ActivatedRoute,
        private snackBar: MatSnackBar,
    ) { }

    ngOnInit(): void {
        this.kycStatusOptions = [
            ...this.kycStatusOptions,
            ...this.route.snapshot.data?.kycStatuses?.map((status: KycStatus) => ({
                label: status.description,
                value: status,
            })),
        ]

        this.paymentStatusOptions = [
            ...this.paymentStatusOptions,
            ...this.paymentStatusOptions = this.route.snapshot.data?.paymentStatuses?.map((status: PaymentStatus) => ({
                label: status.description,
                value: status,
            })),
        ]

        this.form = new FormGroup({
            [this.commentsField]: this.comments,
            [this.kycStatusField]: this.kycStatus,
            [this.paymentStatusField]: this.paymentStatus,
        })

        if (this.showBiz) {
            this.form.addControl(this.businessIdField, this.businessId)
        }
    }

    reset(): void {
        this.form.reset()
    }

    submit(): void {
        this.set()
    }

    private set(): void {

        const bizId: string = (this.showBiz) ? this.businessId.value : this.id
        this.snackBar.open('Setting...')

        const kycStatusRequest: Observable<AdminBusiness> = !!this.kycStatus.value || this.kycStatus.dirty
            ? this.kycStatuses.set(bizId, this.kycStatus.value, this.comments.value)
            : of(undefined)

        const paymentStatusRequest: Observable<AdminBusiness> = !!this.paymentStatus.value || this.paymentStatus.dirty
            ? this.paymentStatuses.set(bizId, this.paymentStatus.value, this.comments.value)
            : undefined

        kycStatusRequest
            .pipe(
                switchMap(biz => paymentStatusRequest || of(biz)),
                tap(biz => {
                    this.snackBar.open('Set!')
                    this.reset()
                    this.businessId.setValue(biz.id)
                    this.updatedBusiness = biz
                    this.updated.emit()
                }),
            )
            .subscribe()
    }

}
