import { Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { filter, take, tap } from 'rxjs/operators'

import { Country } from '../../address'
import { PaymentAccountStore } from '../../core/services'
import { Bank } from '../../country-bank-account-config'
import { BankDetails } from '../models/bank-details.interface'

@Component({
    selector: 'lqd-add-intermediary-dialog',
    templateUrl: './add-intermediary-dialog.component.html',
    styleUrls: ['./add-intermediary-dialog.component.scss'],
})
export class AddIntermediaryDialogComponent implements OnInit {

    bank: BankDetails
    intermediaryBank: Bank
    countries: Country[]
    currencyCodes: string[]
    form: FormGroup
    currencySelect: FormControl
    countrySelect: FormControl
    intermediarySwift: FormControl

    selectedCountryId: string
    selectedCurrency: string

    constructor(
        @Inject(MAT_DIALOG_DATA) data: {
            bank: BankDetails,
            countries: Country[],
            currencyCodes: string[]
        },
        public dialogRef: MatDialogRef<AddIntermediaryDialogComponent>,
        public paymentAccountStore: PaymentAccountStore,
    ) {
        this.bank = data.bank
        this.countries = data.countries
        this.currencyCodes = data.currencyCodes
    }

    ngOnInit(): void {

        this.currencySelect = new FormControl(undefined, [Validators.required])
        this.countrySelect = new FormControl(undefined, [Validators.required])
        this.intermediarySwift = new FormControl(undefined, [Validators.required])

        this.form = new FormGroup({
            currency: this.currencySelect,
            country: this.countrySelect,
            intermediarySwift: this.intermediarySwift,
        })

        this.currencySelect.setValue('USD')
        this.selectedCurrency = 'USD'
        this.countrySelect.setValue(this.countries.find(c => c.name === this.bank.countryName)?.id)
        this.selectedCountryId = this.countrySelect.value
        this.currencySelect.disable()
        this.countrySelect.disable()

    }

    close(): void {
        this.dialogRef.close(undefined)
    }

    lookupIntermediarySwift(): void {
        if (!this.intermediarySwift?.value || !this.intermediarySwift.value) {
            return
        }

        this.paymentAccountStore.getBank(this.intermediarySwift.value)
            .pipe(
                take(1),
                filter(bank => !!bank),
                tap(bank => {
                    this.intermediaryBank = bank
                    this.form.updateValueAndValidity()
                }),
            )
            .subscribe()
    }

    onKeyUp(e: InputEvent): void {
        if (!!this.intermediaryBank && this.intermediaryBank.swiftCode !== this.intermediarySwift.value) {
            this.intermediaryBank = undefined
        }
    }

    submitEnabled(): boolean {
        const enabled: boolean =
            !!this.selectedCountryId
            && !!this.selectedCurrency
            && !!this.intermediaryBank

        return enabled
    }

    submit(): void {
        this.dialogRef.close({
            selectedCurrencyCode: this.selectedCurrency,
            selectedCountry: this.selectedCountryId,
            intermediaryBankId: this.intermediaryBank.id,
            bankId: this.bank.id,
        })
    }

}
