import { Injectable } from '@angular/core'
import { PageEvent } from '@angular/material/paginator'
import { Observable, Subject } from 'rxjs'
import { debounceTime, filter } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class TableService {

    paginate<T>(rows: Array<T>, page: PageEvent): Array<T> {
        return rows.slice(page.pageIndex * page.pageSize, page.pageIndex * page.pageSize + page.pageSize)
    }

    stickyState(element: HTMLElement, config: IntersectionObserverInit, debounce: number = 0): Observable<VisibilityState> {
        return new Observable<VisibilityState>(subscriber => {
            const subject$: Subject<{ entry: IntersectionObserverEntry, observer: IntersectionObserver }> = new Subject<{ entry: IntersectionObserverEntry, observer: IntersectionObserver }>()

            subject$.subscribe(() => {
                subscriber.next('hidden')
            })
            const intersectionObserver: IntersectionObserver = new IntersectionObserver((entries, observer) => {
                if (!entries.length) {
                    subject$.next({ entry: undefined, observer })
                }
                for (const entry of entries) {
                    subject$.next({ entry, observer })
                }
            }, config)

            subject$
                .pipe(
                    debounceTime(debounce),
                    filter(Boolean),
                )
                .subscribe(async ({ entry, observer }) => {
                    const isEntryVisible: boolean = await this.isVisible(entry.target as HTMLElement, config)
                    subscriber.next(isEntryVisible ? 'visible' : 'hidden')
                })
            intersectionObserver.observe(element)
            return {
                unsubscribe(): void {
                    intersectionObserver.disconnect()
                    subject$.unsubscribe()
                },
            }
        })
    }

    private async isVisible(element: HTMLElement, config: IntersectionObserverInit): Promise<boolean> {
        return new Promise(resolve => {
            const observer: IntersectionObserver = new IntersectionObserver(([entry]) => {
                resolve(entry.isIntersecting)
                observer.disconnect()
            }, config)

            observer.observe(element)
        })
    }
}
