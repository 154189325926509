import { Component, OnInit } from '@angular/core'
import { MatDialogConfig } from '@angular/material/dialog'
import { PageEvent } from '@angular/material/paginator'
import { Sort } from '@angular/material/sort'
import { BehaviorSubject, Observable } from 'rxjs'
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators'

import { AdminTransaction, KYCStatusLabel, PaymentAccountDto, PaymentAccountStatus, Tab, TableId } from '../core/models'
import { AdminTransactionSortPipe } from '../core/pipes'
import { ClientInvoiceService, TabService, UrlService } from '../core/services'
import { DialogService } from '../dialogs'
import { PaymentAccountStatusColorPipe } from '../pipes/payment-account-status-color.pipe'
import { ListField, PagedSet, PageRequest } from '../table/models'
import { VerifyAccountService } from '../unverified-accounts/services/verify-account.service'

import { ReviewDialogComponent } from './review-dialog/review-dialog.component'

@Component({
    selector: 'lqd-review-client-invoices',
    templateUrl: './review-client-invoices.component.html',
    styleUrls: ['./review-client-invoices.component.scss'],
})
export class ReviewClientInvoicesComponent implements OnInit {

    private reviewResults: BehaviorSubject<AdminTransaction[]> = new BehaviorSubject([])
    private scheduledResults: BehaviorSubject<AdminTransaction[]> = new BehaviorSubject([])

    get reviewRows$(): Observable<AdminTransaction[]> { return this.reviewResults.asObservable() }
    get scheduledRows$(): Observable<AdminTransaction[]> { return this.scheduledResults.asObservable() }

    readonly columns: ListField[] = [
        {
            id: 'created',
            label: 'Invoice Created',
            type: 'date',
        },
        {
            id: 'payOn',
            label: 'Process On',
            type: 'datetime',
            notSortable: true,
        },
        {
            id: 'invoiceNumber',
            label: 'Invoice #',
            type: 'number',
        },
        {
            id: 'amountWithCurrency',
            label: 'Amount',
            notSortable: true,
        },
        {
            id: 'payoutWithCurrency',
            label: 'Payout',
            notSortable: true,
        },
        {
            id: 'client',
            label: 'Client',
        },
        {
            id: 'vendor',
            label: 'Vendor',
        },
        {
            id: 'paymentStatus',
            label: 'Status',
        },
        {
            id: 'inReview',
            label: 'In Review?',
            notSortable: true,
        },
        {
            id: 'isExpressBusiness',
            label: 'Express',
            type: 'check-button',
            checkButtonIcon: 'zap',
            notSortable: true,
            checkButtonClass: (transaction: AdminTransaction, col: ListField): string => {
                if (transaction.isExpressBusiness && transaction.businessKYCStatus !== KYCStatusLabel.STAGE2_PASS) {
                    return 'error'
                } else if (transaction.isExpressBusiness && transaction.businessKYCStatus === KYCStatusLabel.STAGE2_PASS) {
                    return 'success'
                }
                return 'low-contrast'
            },
        },
        {
            id: 'accepted',
            label: 'Accepted?',
            notSortable: true,
            checkButtonIcon: 'check-circle',
            type: 'check-button',
        },
        {
            id: 'rejected',
            label: 'Rejected?',
            notSortable: true,
            checkButtonIcon: 'x-circle',
            type: 'check-button',
        },
        {
            id: 'reviewThis',
            label: 'Review',
            checkButtonClass: (row, column) => new PaymentAccountStatusColorPipe().transform((<AdminTransaction>row).unverifiedPayoutAccount),
            checkButtonIcon: 'edit',
            type: 'check-button',
            notSortable: true,
        },
    ]
    reviewLength: number
    scheduledLength: number
    readonly tableId: string = TableId.dashboardReviewTransactions
    readonly scheduledTableId: string = TableId.dashboardReviewTransactions + '-scheduled'

    constructor(
        private dialogs: DialogService,
        private tabService: TabService,
        private txPipe: AdminTransactionSortPipe,
        private txService: ClientInvoiceService,
        private urls: UrlService,
        private verifyAccounts: VerifyAccountService,
    ) { }

    ngOnInit(): void {

    }

    onPageChange(event: { page: PageEvent, sort: Sort }, wasScheduled: boolean = false): void {
        if (!event?.page?.pageSize || event?.page?.pageIndex < 0 || !event?.sort?.active || !event?.sort?.direction) {
            return
        }

        const page: PageEvent = event.page
        const sort: Sort = event.sort
        const pageRequest: PageRequest = {
            page: page.pageIndex + 1,
            pageSize: page.pageSize,
            sortBy: `${this.txPipe.transform(sort.active)} ${sort.direction}`,
        }

        const txRequest$: Observable<PagedSet<AdminTransaction>> = wasScheduled ? this.txService.getScheduled(pageRequest) : this.txService.getInReview(pageRequest)

        txRequest$
            .pipe(
                take(1),
                tap(data => {
                    if (wasScheduled) {
                        this.scheduledLength = data.rowCount
                        this.scheduledResults.next(data.results.map(trx => {
                            return {
                                ...trx,
                                accepted: !trx.inReview && !trx.rejected,
                                amountWithCurrency: `${trx.amount.toFixed(2)} ${trx.invoiceCurrency}`,
                                payoutWithCurrency: `${trx.payoutAmount.toFixed(2)} ${trx.payoutCurrency} ${trx.payoutTotalLocked ? '*' : ''}`,
                                reviewThis: true,
                                isExpressBusiness: !!trx.isExpressBusiness ? true : undefined,
                            }
                        }))
                    } else {
                        this.reviewLength = data.rowCount
                        this.reviewResults.next(data.results.map(trx => {
                            return {
                                ...trx,
                                accepted: !trx.inReview && !trx.rejected,
                                amountWithCurrency: `${trx.amount.toFixed(2)} ${trx.invoiceCurrency}`,
                                payoutWithCurrency: `${trx.payoutAmount.toFixed(2)} ${trx.payoutCurrency} ${trx.payoutTotalLocked ? '*' : ''}`,
                                reviewThis: true,
                                isExpressBusiness: !!trx.isExpressBusiness ? true : undefined,
                            }
                        }))
                    }

                }),
            )
            .subscribe()
    }

    onRowClick(transaction: AdminTransaction): void {
        const tab: Tab = {
            label: `${transaction.client} - Inv ${transaction.invoiceNumber}`,
            removable: true,
            route: this.urls.route.clientInvoiceDetails(transaction.invoiceId),
        }
        this.tabService.addTab(tab)
    }

    onCheckButtonClick(event: { field: ListField, row: AdminTransaction }, wasScheduled: boolean = false): void {

        if (event.field.id === 'isExpressBusiness') {
            this.navigateToBusinessDetail(event)
            return
        }

        if (event.field.id !== 'reviewThis') {
            return
        }

        // if we don't have to worry about verifying the payout account, open the invoice review dialog
        const unverifiedPayoutAccount: PaymentAccountDto = event.row.unverifiedPayoutAccount
        if (!unverifiedPayoutAccount) {
            this.popReviewDialog(event, wasScheduled)
                .subscribe()
            return
        }

        // open the dialog to verify the payout account
        this.verifyAccounts.verifyAccount(unverifiedPayoutAccount)
            .pipe(
                filter(acct => acct?.currentVerificationStatus === PaymentAccountStatus.Verified),
                // if the account is verified, open the dialog to review the invoice
                switchMap(() => this.popReviewDialog(event, wasScheduled)),
            )
            .subscribe()
    }

    private navigateToBusinessDetail(event: { field: ListField, row: AdminTransaction }): void {
        const tab: Tab = {
            label: event.row.client,
            removable: true,
            route: this.urls.route.businessDetails(event.row.clientId),
        }
        this.tabService.addTab(tab)
    }

    private popReviewDialog(event: { field: ListField, row: AdminTransaction }, wasScheduled: boolean = false): Observable<void> {
        const dialogConfig: MatDialogConfig = new MatDialogConfig()
        dialogConfig.data = {
            description: '',
            title: 'Transaction Review',
            yesButtonText: 'Submit',
            noButtonText: 'Cancel',
            invoiceId: event.row.invoiceId,
            invoiceNumber: event.row.invoiceNumber,
            client: event.row.client,
            vendor: event.row.vendor,
            invoiceAmount: event.row.amount,
            invoiceCurrency: event.row.invoiceCurrency,
            payoutAmount: event.row.payoutAmount,
            payoutCurrency: event.row.payoutCurrency,
            payoutTotalLocked: event.row.payoutTotalLocked,
            created: event.row.created,

        }
        return this.dialogs.open(ReviewDialogComponent, dialogConfig)
            .afterClosed()
            .pipe(
                filter(result => !!result),
                tap((result) => this.refreshTable(result, wasScheduled)),
                catchError(error => this.dialogs.error(error)),
            )
    }

    private refreshTable(result: any, wasScheduled: boolean = false): void {
        const results = wasScheduled ? this.scheduledResults : this.reviewResults
        const idx: number = results.value.findIndex(r => r.invoiceId === result?.invoiceId)
        results.value[idx] = {
            ...result,
            accepted: !result.inReview && !result.rejected,
            reviewThis: true,
        }
        results.next(results.value)
    }
}
