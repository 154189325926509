import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { MatDialogConfig } from '@angular/material/dialog'
import { BehaviorSubject, Observable } from 'rxjs'

import { LogFactory } from '../core/factories'
import { AdminLog } from '../core/models'
import { DialogService } from '../dialogs'
import { LogDialogComponent } from '../log-dialog/log-dialog.component'
import { ListField } from '../table/models'

@Component({
    selector: 'lqd-logs',
    templateUrl: './logs.component.html',
    styleUrls: ['./logs.component.scss'],
})
export class LogsComponent implements OnInit, OnChanges {

    private logsSubject: BehaviorSubject<Array<AdminLog>> = new BehaviorSubject([])

    readonly logs$: Observable<Array<AdminLog>> = this.logsSubject.asObservable()
    readonly logColumns: ListField[] = [
        {
            id: 'requestDateTime',
            label: 'Date',
            type: 'date',
        },
        {
            id: 'requestedBy',
            label: 'Requested By',
        },
        {
            id: 'logEvent',
            label: 'Event',
        },
        {
            id: 'message',
            label: 'Message',
        },
    ]

    @Input() id: string
    @Input() logs: Array<AdminLog>

    constructor(
        private dialogs: DialogService,
        private logFactory: LogFactory,
    ) { }

    ngOnInit(): void {
        this.setLogs()
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setLogs()
    }

    onRowClick(log: AdminLog): void {
        const dialogConfig: MatDialogConfig = new MatDialogConfig()
        dialogConfig.data = log.requestBody
        this.dialogs.open(LogDialogComponent, dialogConfig)
    }

    setLogs(): void {
        this.logs = this.logs.map(log => this.logFactory.create(log))
        this.logsSubject.next(this.logs)
    }

}
