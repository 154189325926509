import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { environment } from '../environments/environment'

import { AuthCallbackComponent } from './auth-callback/auth-callback.component'
import { AdminGuard } from './auth-tools/admin.guard'
import { SupportGuard } from './auth-tools/support.guard'
import { AuthGuard } from './auth/services'
import { ClientInvoiceComponent } from './client-invoice/client-invoice.component'
import { ClientInvoicesListComponent } from './client-invoices-list/client-invoices-list.component'
import { KycStatusResolver, PaymentStatusResolver } from './core/resolvers'
import { HomeComponent } from './home/home.component'
import { UserTableComponent } from './users/users.component'

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: HomeComponent,
        canActivate: [
            // AuthGuard,
            AdminGuard,
        ],
        resolve: {
            kycStatuses: KycStatusResolver,
            paymentStatuses: PaymentStatusResolver,
        },
    },
    {
        path: 'account-management',
        loadChildren: () => import('./account-management/account-management.module').then(m => m.AccountManagementModule),
    },
    {
        path: 'businesses',
        loadChildren: () => import('./business/business.module').then(m => m.BusinessModule),
    },
    {
        path: 'currencies',
        loadChildren: () => import('./currencies/currencies.module').then(m => m.CurrenciesModule),
    },
    {
        path: 'fx',
        loadChildren: () => import('./fx/fx.module').then(m => m.FXModule),
    },
    {
        path: 'banks',
        loadChildren: () => import('./banks/banks.module').then(m => m.BanksModule),
    },
    {
        path: 'maintenance',
        loadChildren: () => import('./maintenance/maintenance.module').then(m => m.MaintenanceModule),
    },
    {
        path: 'notifications',
        loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule),
    },
    {
        path: 'support',
        loadChildren: () => import('./support/support.module').then(m => m.SupportModule),
    },
    {
        path: 'clientInvoices',
        component: ClientInvoicesListComponent,
        canActivate: [
            AuthGuard,
            AdminGuard,
        ],
    },
    {
        path: 'clientInvoice/:invoiceId',
        component: ClientInvoiceComponent,
        canActivate: [
            AuthGuard,
            AdminGuard,
        ],
        resolve: {
            kycStatuses: KycStatusResolver,
            paymentStatuses: PaymentStatusResolver,
        },
    },
    {
        path: 'users',
        component: UserTableComponent,
        canActivate: [
            AuthGuard,
            AdminGuard,
        ],
    },
    {
        path: 'auth-callback',
        component: AuthCallbackComponent,
    },
]

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule { }
