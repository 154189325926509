import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

import { TableService } from './services'

@Directive({
    selector: '[appIntersectionObserver]',
})
export class IntersectionObserverDirective implements OnInit, OnDestroy {

    @Input() intersectionDebounce: number = 0
    @Input() intersectionRootMargin: number = 0
    @Input() intersectionRoot: HTMLElement
    @Input() intersectionThreshold: number | number[] = [0]

    @Output() visibilityChange: EventEmitter<VisibilityState> = new EventEmitter<VisibilityState>()

    private destroy$: Subject<void> = new Subject()

    constructor(
        private element: ElementRef<HTMLElement>,
        private tables: TableService,
    ) { }

    ngOnInit(): void {
        const element: HTMLElement = this.element.nativeElement
        this.tables.stickyState(
            element,
            {
                root: this.intersectionRoot,
                rootMargin: `${this.intersectionRootMargin}px`,
                threshold: this.intersectionThreshold,
            },
            this.intersectionDebounce,
        ).pipe(
            takeUntil(this.destroy$),
        ).subscribe((status) => {
            this.visibilityChange.emit(status)
        })
    }

    ngOnDestroy(): void {
        this.destroy$.next()
    }
}
