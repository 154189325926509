import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
    selector: 'lqd-json-display-dialog',
    templateUrl: './json-display-dialog.component.html',
    styleUrls: ['./json-display-dialog.component.scss'],
})
export class JsonDisplayDialogComponent {

    object: any
    title: string
    constructor(
        private dialogRef: MatDialogRef<JsonDisplayDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
    ) {
        this.object = data?.object
        this.title = data?.title
    }
}
