import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { PagedSet, PageRequest } from '../../table/models'
import { AdminUser } from '../models'

import { UrlService } from './url.service'

@Injectable({
    providedIn: 'root',
})
export class UserStore {
    private readonly userUrl: string = this.urls.api.users()
    private readonly emailVerifyUrl: string = this.urls.api.verifyEmail()
    private readonly getImpersonationLinkUrl: string = this.urls.api.getImpersonationLinkUrl()

    constructor(
        private http: HttpClient,
        private urls: UrlService,
    ) { }

    get(req: PageRequest): Observable<PagedSet<AdminUser>> {
        let params: HttpParams = new HttpParams()
        Object.keys(req).filter(key => req.hasOwnProperty(key)).forEach(key => params = params.set(key, req[key]))

        return this.http.get<PagedSet<AdminUser>>(this.userUrl, { params })
    }

    getImpersonationLink(impersonatedLiquidProfileId: string): Observable<any> {
        const params: HttpParams = new HttpParams()
        return this.http.post<PagedSet<AdminUser>>(this.getImpersonationLinkUrl, { impersonatedLiquidProfileId }, { params })
    }

    verifyEmail(emailId: string): Observable<any> {
        const params: HttpParams = new HttpParams()
        return this.http.post<PagedSet<AdminUser>>(this.emailVerifyUrl, { emailId }, { params })
    }
}
