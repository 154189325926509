<h6 mat-dialog-title>Update Flags</h6>
<mat-dialog-content class="dialog-with-desc">
    <div class="column">
        <mat-checkbox
            [formControl]="testAccount"
            [checked]="!!testAccount.value"
            class="checkbox-top-alignment"
            color="primary"
        >
            <span>Set as Test Account</span>
        </mat-checkbox>
        <mat-checkbox
            [formControl]="qbFlag"
            [checked]="!!qbFlag.value"
            class="checkbox-top-alignment"
            color="primary"
        >
            <span>Enable Quickbooks</span>
        </mat-checkbox>
        <mat-checkbox
            [formControl]="customContractsEnabled"
            [checked]="!!customContractsEnabled.value"
            class="checkbox-top-alignment"
            color="primary"
        >
            <span>Enable Custom Contracts</span>
        </mat-checkbox>
        <mat-checkbox
            [formControl]="manualUSBankAccountEnabledFlag"
            [checked]="!!manualUSBankAccountEnabledFlag.value"
            class="checkbox-top-alignment"
            color="primary"
        >
            <span>Enable Manual US Bank Account</span>
        </mat-checkbox>
        <mat-checkbox
            [formControl]="internationalClient"
            [checked]="!!internationalClient.value"
            class="checkbox-top-alignment"
            color="primary"
        >
            <span>International Client</span>
        </mat-checkbox>
        <mat-checkbox
            [formControl]="internal"
            [checked]="!!internal.value"
            class="checkbox-top-alignment"
            color="primary"
        >
            <span>Internal</span>
        </mat-checkbox>
        <mat-checkbox
            [formControl]="fake"
            [checked]="!!fake.value"
            class="checkbox-top-alignment"
            color="primary"
        >
            <span>Fake</span>
        </mat-checkbox>
        <mat-checkbox
            [formControl]="archive"
            [checked]="!!archive.value"
            class="checkbox-top-alignment"
            color="primary"
        >
            <span>Archive</span>
        </mat-checkbox>
        <mat-form-field>
            <mat-label>Message</mat-label>
            <textarea
                matInput
                required
                [formControl]="message"
            ></textarea>
        </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="button-container">
        <button
            mat-stroked-button
            mat-dialog-close
        >
            Cancel
        </button>
        <button
            mat-flat-button
            color="primary"
            [disabled]="!form.dirty || form.invalid"
            (click)="submitFlags()"
        >
            Update
        </button>
    </div>
</mat-dialog-actions>