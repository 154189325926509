import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatMenuModule } from '@angular/material/menu'
import { FeatherModule } from 'angular-feather'
import { MoreHorizontal } from 'angular-feather/icons'

import { ChipListModule } from '../chip-list/chip-list.module'

import { BankAccountCardComponent } from './bank-account-card/bank-account-card.component'

@NgModule({
    declarations: [
        BankAccountCardComponent,
    ],
    exports: [
        BankAccountCardComponent,
    ],
    imports: [
        ChipListModule,
        CommonModule,
        FeatherModule.pick({ MoreHorizontal }),
        FormsModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatMenuModule,
        ReactiveFormsModule,
    ],
})
export class BankAccountCardModule { }
