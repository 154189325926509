import { Component, EventEmitter, OnInit, Output, Type } from '@angular/core'
import { MatDialogConfig } from '@angular/material/dialog'
import { PageEvent } from '@angular/material/paginator'
import { Sort } from '@angular/material/sort'
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs'
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators'

import { AddressService } from '../address'
import { AdminJPMTransactionDetails, PaymentAccount, PaymentAccountDto, PaymentAccountStatus, Tab, TableId } from '../core/models'
import { BusinessService, ClientInvoiceService, UrlService } from '../core/services'
import { DialogService } from '../dialogs'
import { JPMTransactionLogDialogComponent } from '../shared/jpm-transaction-log-dialog/jpm-transaction-log-dialog.component'
import { ListField, PageRequest } from '../table/models'

@Component({
    selector: 'lqd-unassociated-jpm-transactions',
    templateUrl: './unassociated-jpm-transactions.component.html',
    styleUrls: ['./unassociated-jpm-transactions.component.scss'],
})
export class UnassociatedJPMTransactionsComponent implements OnInit {

    private results: BehaviorSubject<Array<AdminJPMTransactionDetails>> = new BehaviorSubject([])

    get rows$(): Observable<Array<AdminJPMTransactionDetails>> { return this.results.asObservable() }

    readonly columns: Array<ListField> = [
        {
            id: 'created',
            label: 'Pulled',
            type: 'date@time',
        },
        {
            id: 'receivedTimestamp',
            label: 'Received',
            type: 'date@time',
        },
        {
            id: 'debitCreditCode',
            label: 'Debit/Credit',
        },
        {
            id: 'productGroupGroupDescription',
            label: 'Type',
        },
        {
            id: 'baiTypeDescription',
            label: 'Description',
        },
        {
            id: 'postCode',
            label: 'Post Code',
        },
        {
            id: 'repairCode',
            label: 'Repair Code',
        },
        {
            id: 'formattedAmount',
            label: 'Amount',
        },
        {
            id: 'customerReferenceSearchableStandardValue',
            label: 'Cust. Ref',
        },
        {
            id: 'bankReferenceSearchableStandardValue',
            label: 'Bank Ref',
        },
    ]
    length: number
    readonly tableId: string = TableId.unassociatedJpmTransactions

    constructor(
        private clientInvoiceSvc: ClientInvoiceService,
        private dialogs: DialogService,
    ) { }

    ngOnInit(): void {
    }

    onPageChange(event: { page: PageEvent, sort: Sort }): void {
        if (!event?.page?.pageSize || event?.page?.pageIndex < 0 || !event?.sort?.active || !event?.sort?.direction) {
            return
        }

        const page: PageEvent = event.page
        const sort: Sort = event.sort
        const pageRequest: PageRequest = {
            page: page.pageIndex + 1,
            pageSize: page.pageSize,
            sortBy: `${sort.active} ${sort.direction}`,
        }

        this.clientInvoiceSvc.getUnassociatedJPMTransactions(pageRequest)
            .pipe(
                take(1),
                tap(data => {
                    this.length = data.rowCount
                    this.results.next(data.results?.map(d => {
                        d['formattedAmount'] = `${d.amount.toFixed(2)} ${d.currencyCode}`
                        return {
                            ...d,
                        }
                    }))
                }),
            )
            .subscribe()
    }

    onRowClick(event: AdminJPMTransactionDetails): void {
        this.popReviewDialog(event)
    }

    private popReviewDialog(details: AdminJPMTransactionDetails): void {
        const dialogConfig: MatDialogConfig = new MatDialogConfig()
        dialogConfig.data = {}

        this.clientInvoiceSvc.getJPMTransactionDetails(details.id)
            .pipe(
                switchMap(d => {
                    dialogConfig.data = { details: d, dismissable: true }
                    return this.dialogs.open(JPMTransactionLogDialogComponent, dialogConfig)
                        .afterClosed()
                        .pipe(
                            take(1),
                            switchMap(data => {
                                if (!data) {
                                    return of(undefined)
                                }
                                if (!!data?.isDismissed) {
                                    return this.clientInvoiceSvc.dismissJPMTransactionDetail(d.id, data.dismissalNote)
                                        .pipe(
                                            take(1),
                                            tap(detail => {
                                                const idx: number = this.results.value.findIndex(r => r.id === detail?.id)
                                                if (idx >= 0) {
                                                    this.results.value.splice(idx, 1)
                                                    this.results.next(this.results.value)
                                                }
                                            }),
                                        )
                                }
                            }),
                            catchError(error => this.dialogs.error(error)),
                        )
                }),
            )
            .subscribe()
    }
}
