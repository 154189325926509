import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs'
import { map, skipWhile, switchMap, take } from 'rxjs/operators'

import { AuthService, UserRole } from '../auth/services'
import { UrlService } from '../core/services'

@Injectable({
    providedIn: 'root',
})
export class AdminGuard implements CanActivate {

    constructor(
        private auth: AuthService,
        private router: Router,
        private urls: UrlService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        return this.auth.userLoggedIn$
            .pipe(
                skipWhile(user => !user),
                take(1),
                switchMap(user => this.auth.getUserRoles(user)),
                map((userRoles: UserRole[]) => {
                    if (userRoles.includes(UserRole.ToolsAdmin)) {
                        return true
                    }

                    if (userRoles.includes(UserRole.ToolsSupport)) {
                        this.router.navigateByUrl(this.urls.route.support())
                        return true
                    }

                    return false
                }),
            )
    }
}
