import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete'
import { PageEvent } from '@angular/material/paginator'
import { Sort } from '@angular/material/sort'
import { BehaviorSubject, Observable, of, Subject } from 'rxjs'
import { debounceTime, finalize, switchMap, take, takeUntil, tap } from 'rxjs/operators'

import { AdminTransaction, Tab, TableId } from '../core/models'
import { ClientInvoiceService, TabService, UrlService } from '../core/services'
import { SearchResult } from '../search/models'
import { SearchService } from '../search/services'
import { ListField, PageRequest } from '../table/models'

@Component({
    selector: 'lqd-client-invoices-list',
    templateUrl: './client-invoices-list.component.html',
    styleUrls: ['./client-invoices-list.component.scss'],
})
export class ClientInvoicesListComponent implements OnDestroy, OnInit {

    private results: BehaviorSubject<AdminTransaction[]> = new BehaviorSubject([])
    private unsubscribe$: Subject<void> = new Subject<void>()

    get rows$(): Observable<AdminTransaction[]> { return this.results.asObservable() }

    readonly columns: ListField[] = [
        {
            id: 'created',
            label: 'Date Created',
            type: 'date',
        },
        {
            id: 'invoiceNumber',
            label: 'Invoice #',
        },
        {
            id: 'amount',
            label: 'Amount',
            type: 'money',
            notSortable: true,
        },
        {
            id: 'client',
            label: 'Client',
        },
        {
            id: 'vendor',
            label: 'Vendor',
        },
        {
            id: 'paymentStatus',
            label: 'Status',
            notSortable: true,
        },
        {
            id: 'datePaid',
            label: 'Date Paid',
            type: 'date',
        },
        {
            id: 'fxEquivalent',
            label: 'FX Equivalent',
        },
    ]
    invoiceSearch: FormControl = new FormControl()
    invoiceSearchResultGroups: { groupName: string, searchResults: SearchResult[] }[] = []
    invoiceSearchResults: SearchResult[]
    isSearching: boolean
    length: number
    readonly tableId: string = TableId.dashboardTransactions

    constructor(
        private searchService: SearchService,
        private tabService: TabService,
        private txService: ClientInvoiceService,
        private urls: UrlService,
    ) { }

    ngOnInit(): void {
        this.invoiceSearch.valueChanges
            .pipe(
                takeUntil(this.unsubscribe$),
                debounceTime(400),
                switchMap(value => {
                    this.isSearching = true
                    if (value.trim().length > 0) {
                        return this.searchService.clientInvoiceSearch(value, 1, 10)
                            .pipe(
                                tap((results) => this.invoiceSearchResults = results?.results),
                                finalize(() => this.groupResults()),
                            )
                    } else {
                        return of(undefined)
                    }
                }),
            )
            .subscribe()
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next()
        this.unsubscribe$.complete()
    }

    onPageChange(event: { page: PageEvent, sort: Sort }): void {

        if (!event?.page?.pageSize || event?.page?.pageIndex < 0 || !event?.sort?.active || !event?.sort?.direction) {
            return
        }

        const page: PageEvent = event.page
        const sort: Sort = event.sort

        const pageRequest: PageRequest = {
            page: page.pageIndex + 1,
            pageSize: page.pageSize,
            sortBy: `${sort.active} ${sort.direction}`,
        }

        this.txService.get(pageRequest)
            .pipe(
                take(1),
                tap(data => {
                    this.length = data.rowCount
                    this.results.next(data.results)
                }),
            )
            .subscribe()
    }

    onRowClick(transaction: AdminTransaction): void {
        const tab: Tab = {
            label: `${transaction.client} - Inv ${transaction.invoiceNumber}`,
            removable: true,
            route: this.urls.route.clientInvoiceDetails(transaction.invoiceId),
        }
        this.tabService.addTab(tab)
    }

    searchResultDisplayFn(result: any): string {
        if (result && result.name) {
            return result.name
        } else {
            return result
        }
    }

    searchResultSelected(event: MatAutocompleteSelectedEvent): void {
        const result: SearchResult = event.option.value
        const tab: Tab = {
            label: `${result.clientName} - Inv ${result.shortName}`,
            removable: true,
            route: this.urls.route.clientInvoiceDetails(result.id),
        }
        this.tabService.addTab(tab)
    }

    private groupResults(): void {
        if (this.invoiceSearchResults) {
            this.invoiceSearchResultGroups = []
            this.invoiceSearchResults.forEach(result => {
                const groupName: string = 'Invoices'
                let group: { groupName: string, searchResults: SearchResult[] } = this.invoiceSearchResultGroups.find(g => g.groupName === groupName)
                if (!group) {
                    this.invoiceSearchResultGroups.push(group = { groupName, searchResults: [] })
                }
                group.searchResults.push(result)
            })
        }
        this.isSearching = false
    }
}
