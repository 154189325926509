import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
    selector: 'app-dialog-template-plain',
    templateUrl: './dialog-template-plain.component.html',
    styleUrls: ['./dialog-template-plain.component.scss'],
})
export class DialogTemplatePlainComponent {

    @Output() close: EventEmitter<MouseEvent> = new EventEmitter()

    @Input() hideButton: boolean
    @Input() hideCloseButton: boolean = false
    @Input() hideHeader: boolean
    @Input() hideClose: boolean
    @Input() title: string

    emitClose(evt: MouseEvent): void {
        this.close.emit(evt)
    }
}
