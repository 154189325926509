import { DefaultLabel } from '../core/models'

export const ALWAYS_ON_DISPLAY_FIELDS: (DefaultLabel | string)[] = [
    DefaultLabel.SwiftCode,
    DefaultLabel.Iban,
]

export const HIDDEN_ON_MAIN_FORM_FIELDS: (DefaultLabel | string)[] = [
    DefaultLabel.AccountHolderName,
    DefaultLabel.AccountHolderAddress,
]
