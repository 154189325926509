<form
    *ngIf="!!nonUsForm"
    [formGroup]="nonUsForm"
>
    <div
        class="form-container"
        *ngIf="!!initialize"
    >
        <ng-container *ngIf="!editing && !isForManualUSBankAccount && !reviewModeEnabled">
            <p class="subtitle1 primary100">Is your bank located in US?</p>
            <mat-radio-group
                class="radio-group"
                formControlName="usBank"
            >
                <mat-radio-button
                    color="primary"
                    [value]="true"
                >
                    Yes
                </mat-radio-button>
                <mat-radio-button
                    color="primary"
                    [value]="false"
                >
                    No
                </mat-radio-button>
            </mat-radio-group>
        </ng-container>

        <ng-container *ngIf="!isUsOverride;else usBankDetails">
            <ng-container *ngFor="let input of parentProperties">

                <ng-container [ngSwitch]="input.countryBankAccountProperty.bankAccountPropertyDef.dataType">

                    <ng-template ngSwitchCase="header">
                        <p
                            *ngIf="displayHeader(input.countryBankAccountProperty)"
                            class="subtitle1 primary100"
                        >
                            {{input.countryBankAccountProperty.label}}
                        </p>
                        <ng-container
                            *ngIf="input.countryBankAccountProperty.bankAccountPropertyDef.defaultLabel === defaultLabel.BankInformation && !isForManualUSBankAccount && swiftCountryCurrencies?.length !== 2"
                        >
                            <p class="subtitle2 primary100">
                                {{countryName}} {{currencyName}}
                            </p>
                        </ng-container>
                        <ng-container
                            *ngIf="input.countryBankAccountProperty.bankAccountPropertyDef.defaultLabel === defaultLabel.BankInformation && !isForManualUSBankAccount && !reviewModeEnabled && swiftCountryCurrencies?.length === 2"
                        >
                            <p class="subtitle2 primary100">
                                What is your preferred payout currency for your bank account from {{swiftCountry}}?
                            </p>

                            <mat-radio-group class="radio-group">
                                <mat-radio-button
                                    color="primary"
                                    checked="true"
                                    [value]="true"
                                    (click)="preferFirstForPrimaryBanking()"
                                >
                                    {{swiftCountryCurrencies[0]}}
                                </mat-radio-button>
                                <mat-radio-button
                                    color="primary"
                                    [value]="false"
                                    (click)="preferSecondForPrimaryBanking()"
                                >
                                    {{swiftCountryCurrencies[1]}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </ng-container>
                    </ng-template>

                    <div
                        *ngIf="input.countryBankAccountProperty.bankAccountPropertyDef.defaultLabel === defaultLabel.BankInformation && !isForManualUSBankAccount && !reviewModeEnabled"
                        class="mat-form-field-infix column"
                    >
                        <mat-label>Edit&nbsp;</mat-label>
                        <mat-slide-toggle
                            (toggleChange)="toggleOverrideBankInfo()"
                            [checked]="overrideBankInfo"
                        ></mat-slide-toggle>
                    </div>

                    <p
                        *ngSwitchCase="propertyDataType.NOTE"
                        class="primary100"
                    >
                        {{input.countryBankAccountProperty.hint}}
                    </p>

                    <ng-template ngSwitchCase="string">
                        <div
                            *ngIf="displayStringFormControl(input.countryBankAccountProperty)"
                            class="pen-edit-container spacer"
                        >
                            <mat-form-field>
                                <mat-label>{{input.countryBankAccountProperty.label}}</mat-label>
                                <input
                                    #inputElement
                                    matInput
                                    [ngClass]="{'fs-mask': input.countryBankAccountProperty.bankAccountPropertyDef.defaultLabel === defaultLabel.Iban}"
                                    type="text"
                                    [formControlName]="input.countryBankAccountProperty.id"
                                    [placeholder]="input.countryBankAccountProperty.placeholder"
                                    [required]="input.countryBankAccountProperty.required"
                                    autocomplete="off"
                                />
                                <mat-error
                                    *ngIf="input.countryBankAccountProperty.validationMessage && !!form.controls[input.countryBankAccountProperty.id].errors?.pattern"
                                >
                                    {{input.countryBankAccountProperty.validationMessage}}
                                </mat-error>

                                <mat-error
                                    *ngIf="!!form.controls[input.countryBankAccountProperty.id].errors?.required">
                                    {{input.countryBankAccountProperty.label}} is required
                                </mat-error>

                                <mat-error *ngIf="!!form.controls[input.countryBankAccountProperty.id].errors?.iban">
                                    {{input.countryBankAccountProperty.label}} is invalid
                                </mat-error>

                                <mat-error *ngIf="!!form.controls[input.countryBankAccountProperty.id].errors?.swift">
                                    {{input.countryBankAccountProperty.label}} is invalid
                                </mat-error>

                                <mat-error
                                    *ngIf="!!form.controls[input.countryBankAccountProperty.id].errors?.swiftCountry"
                                >
                                    {{input.countryBankAccountProperty.label}} is not from your country
                                </mat-error>

                                <mat-hint *ngIf="input.countryBankAccountProperty.hint">
                                    {{input.countryBankAccountProperty.hint}}
                                </mat-hint>
                            </mat-form-field>
                            <button
                                mat-icon-button
                                *ngIf="input.countryBankAccountProperty.editable && input.countryBankAccountProperty.bankAccountPropertyDef.defaultLabel === defaultLabel.AccountHolderName && reviewModeEnabled"
                                (click)="inputElement.disabled = !inputElement.disabled"
                            >
                                <mat-icon>{{inputElement.disabled ? 'edit' : 'done'}}</mat-icon>
                            </button>
                        </div>
                    </ng-template>

                    <div
                        *ngSwitchCase="propertyDataType.LIST"
                        class="form-field"
                    >
                        <mat-form-field *ngIf="propertySelectOptions[input.countryBankAccountProperty.id]?.length > 1">
                            <mat-label>
                                {{input.countryBankAccountProperty.label}}
                            </mat-label>

                            <mat-select
                                [formControlName]="input.countryBankAccountProperty.id"
                                [aria-label]="input.countryBankAccountProperty.label"
                                [required]="input.countryBankAccountProperty.required"
                            >
                                <mat-option
                                    *ngFor="let option of propertySelectOptions[input.countryBankAccountProperty.id]"
                                    [value]="option.value"
                                    [matTooltip]="propertySelectOptionsTooltips[input.countryBankAccountProperty.id][option.value]"
                                >
                                    {{option.label}}
                                </mat-option>
                            </mat-select>

                            <mat-error
                                *ngIf="input.countryBankAccountProperty.validationMessage && !!form.controls[input.countryBankAccountProperty.id].errors?.pattern"
                            >
                                {{input.countryBankAccountProperty.validationMessage}}
                            </mat-error>

                            <mat-error *ngIf="!!form.controls[input.countryBankAccountProperty.id].errors?.required">
                                {{input.countryBankAccountProperty.label}} is required
                            </mat-error>

                            <mat-hint *ngIf="input.countryBankAccountProperty.hint">
                                {{input.countryBankAccountProperty.hint}}
                            </mat-hint>
                        </mat-form-field>
                    </div>

                    <!-- TODO: make this more dynamic for other bool inputs -->
                    <ng-template ngSwitchCase="bool">
                        <mat-radio-group
                            *ngIf="reviewModeEnabled || isForManualUSBankAccount"
                            class="radio-group"
                            [formControlName]="input.countryBankAccountProperty.id"
                        >
                            <mat-radio-button
                                value="false"
                                color="primary"
                            >
                                Business Account
                            </mat-radio-button>
                            <mat-radio-button
                                value="true"
                                color="primary"
                            >
                                Personal Account
                            </mat-radio-button>
                        </mat-radio-group>
                    </ng-template>

                    <ng-template ngSwitchCase="address">
                        <div
                            class="address-form"
                            [ngStyle]="displayAddressForm(input.countryBankAccountProperty) ? null : {'display': 'none'}"
                        >
                            <mat-checkbox
                                (change)="toggleHolderAddressForm(formGroups[input.countryBankAccountProperty.id], !$event.checked)"
                                [checked]="formGroups[input.countryBankAccountProperty.id].disabled"
                                *ngIf="reviewModeEnabled && input.countryBankAccountProperty.bankAccountPropertyDef.defaultLabel === defaultLabel.AccountHolderAddress"
                            >
                                My address on file with my bank matches my business address.
                            </mat-checkbox>

                            <p class="primary100">
                                {{input.countryBankAccountProperty.label}}
                            </p>

                            <app-address-form
                                *ngIf="input.countryBankAccountProperty.bankAccountPropertyDef.defaultLabel === defaultLabel.AccountHolderAddress"
                                [disabled]="!addressFormEditable"
                                [addressForm]="formGroups[input.countryBankAccountProperty.id]"
                                [addressInput$]="prefillAddress(input)"
                                [defaultCountry]="input.countryBankAccountProperty.disableCountry ? countryName : undefined"
                                [disableCountry]="input.countryBankAccountProperty.disableCountry"
                                [narrow]="true"
                                [optionalFields]="optionalChildFields(input)"
                                [requiredFields]="requiredChildFields(input)"
                            ></app-address-form>
                            <app-address-form
                                *ngIf="input.countryBankAccountProperty.bankAccountPropertyDef.defaultLabel !== defaultLabel.AccountHolderAddress"
                                [addressForm]="formGroups[input.countryBankAccountProperty.id]"
                                [addressInput$]="prefillAddress(input)"
                                [defaultCountry]="input.countryBankAccountProperty.disableCountry ? countryName : undefined"
                                [disableCountry]="input.countryBankAccountProperty.disableCountry"
                                [narrow]="true"
                                [optionalFields]="optionalChildFields(input)"
                                [requiredFields]="requiredChildFields(input)"
                            ></app-address-form>
                        </div>
                    </ng-template>

                </ng-container>

            </ng-container>
        </ng-container>

        <ng-template #usBankDetails>
            <p class="subtitle1 primary100">Bank Account Type</p>
            <mat-radio-group
                class="radio-group"
                name="usAccountBankIsBusiness"
                [disabled]="reviewModeEnabled"
                [formControl]="usAccountBankIsBusiness"
            >
                <mat-radio-button
                    color="primary"
                    [value]="true"
                >
                    Business Account
                </mat-radio-button>
                <mat-radio-button
                    color="primary"
                    [value]="false"
                >
                    PersonalAccount
                </mat-radio-button>
            </mat-radio-group>

            <div
                class="pen-edit-container"
                *ngIf="reviewModeEnabled"
            >
                <mat-form-field class="spacer">
                    <mat-label>
                        Account Holder Name
                    </mat-label>

                    <input
                        matInput
                        type="text"
                        autocomplete="off"
                        [disabled]=""
                        [formControl]="usAccountHolderName"
                        [placeholder]="'Account Holder Name'"
                        [required]="true"
                    />

                    <mat-error *ngIf="usAccountHolderName.hasError('required')">
                        You must enter the account holder name.
                    </mat-error>
                </mat-form-field>

                <button
                    mat-icon-button
                    (click)="usAccountHolderName.enabled ? usAccountHolderName.disable() : usAccountHolderName.enable()"
                >
                    <mat-icon>{{usAccountHolderName.enabled ? 'done' : 'edit'}}</mat-icon>
                </button>
            </div>
            <mat-form-field class="">
                <mat-label>
                    Bank Name
                </mat-label>

                <input
                    matInput
                    type="text"
                    name="usAccountBankName"
                    [formControl]="usAccountBankName"
                    [placeholder]="'Bank Name'"
                    [required]="true"
                    autocomplete="off"
                />
                <mat-error *ngIf="usAccountBankName.hasError('required')">
                    You must enter the bank name.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="">
                <mat-label>
                    Account Number
                </mat-label>

                <input
                    matInput
                    type="text"
                    name="usAccountBankAccountNumber"
                    [formControl]="usAccountBankAccountNumber"
                    [placeholder]="'Account Number'"
                    [required]="true"
                    autocomplete="off"
                />
                <mat-error *ngIf="usAccountBankAccountNumber.hasError('required')">
                    You must enter the bank account number.
                </mat-error>
                <mat-error *ngIf="usAccountBankAccountNumber.hasError('minlength')">
                    Bank account must have at least 4 digits.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="">
                <mat-label>
                    ACH Routing Number
                </mat-label>

                <input
                    matInput
                    type="text"
                    name="usAccountRouting"
                    [formControl]="usAccountRouting"
                    [placeholder]="'ACH Routing Number'"
                    [required]="true"
                    autocomplete="off"
                    minlength="9"
                    maxlength="9"
                />
                <mat-error *ngIf="usAccountRouting.hasError('required')">
                    You must enter a routing number.
                </mat-error>
                <mat-error *ngIf="usAccountRouting.hasError('minlength')">
                    Routing number must have 9 digits
                </mat-error>
            </mat-form-field>
            <p class="primary100">Bank Address</p>
            <mat-form-field class="">
                <mat-label>
                    Street Address
                </mat-label>

                <input
                    matInput
                    type="text"
                    name="usAccountAddress"
                    [formControl]="usAccountAddress"
                    [placeholder]="'Street Address'"
                    [required]="true"
                    autocomplete="off"
                />
                <mat-error *ngIf="usAccountAddress.hasError('required')">
                    You must enter the street address.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="">
                <mat-label>
                    Street Address 2
                </mat-label>

                <input
                    matInput
                    type="text"
                    name="usAccountAddress2"
                    [formControl]="usAccountAddress2"
                    [placeholder]="'Street Address 2'"
                    [required]="false"
                    autocomplete="off"
                />
            </mat-form-field>
            <mat-form-field class="">
                <mat-label>
                    City
                </mat-label>

                <input
                    matInput
                    type="text"
                    name="usAccountCity"
                    [formControl]="usAccountCity"
                    [placeholder]="'City'"
                    [required]="true"
                    autocomplete="off"
                />
                <mat-error *ngIf="usAccountCity.hasError('required')">
                    You must enter the city.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="">
                <mat-label>State</mat-label>
                <mat-select
                    name="usAccountState"
                    [formControl]="usAccountState"
                    [placeholder]="'State'"
                    [required]="true"
                    autocomplete="off"
                >
                    <mat-option
                        *ngFor="let option of stateOptions"
                        [value]="option.value"
                    >
                        {{option.label}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="usAccountState.hasError('required')">
                    You must select a state.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="">
                <mat-label>
                    Zip
                </mat-label>

                <input
                    matInput
                    type="text"
                    name="usAccountZip"
                    [formControl]="usAccountZip"
                    [placeholder]="'Zip'"
                    [required]="true"
                    autocomplete="off"
                    minlength="5"
                    maxlength="10"
                />
                <mat-error *ngIf="usAccountZip.hasError('required')">
                    You must select a ZIP.
                </mat-error>
                <mat-error *ngIf="usAccountZip.hasError('minlength')">
                    Zip must have at least 5 digits
                </mat-error>
            </mat-form-field>
            <mat-form-field class="">
                <mat-label>
                    Country
                </mat-label>

                <input
                    matInput
                    type="text"
                    name="usAccountCountry"
                    [formControl]="usAccountCountry"
                    [placeholder]="'Country'"
                    autocomplete="off"
                />
            </mat-form-field>
        </ng-template>

    </div>
</form>