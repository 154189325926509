<h6 mat-dialog-title>Unmask Values</h6>
<mat-dialog-content class="dialog-with-desc">
    <div class="column">
        <mat-form-field>
            <mat-label>Message</mat-label>
            <textarea
                matInput
                required
                [formControl]="message"
            ></textarea>
        </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="button-container">
        <button
            mat-stroked-button
            mat-dialog-close
        >
            Cancel
        </button>
        <button
            mat-flat-button
            color="primary"
            [disabled]="!form.dirty || form.invalid || unmasking"
            (click)="submitUnmaskRequest()"
        >
            Unmask
        </button>
    </div>
</mat-dialog-actions>
