<div class="header">
    <h5>Review Microdeposit Verification Request</h5>
    <button
        type="button"
        class="x-button"
        mat-icon-button
        mat-dialog-close
    >
        <i-feather name="x">
        </i-feather>
    </button>
</div>
<mat-dialog-content class="dialog-with-desc">
    <p>
        <strong>Business Name:</strong>
        <a
            href="javascript:void(0)"
            (click)="openBusinessTab()"
        >
            {{ microdepositVerificationRequest?.businessName }}
        </a>
    </p>
    <p><strong>Business Type:</strong> {{ microdepositVerificationRequest?.businessType}}</p>
    <p><strong>Email Address:</strong> {{ microdepositVerificationRequest?.emailAddress }}</p>
    <p><strong>Vendor Business Name:</strong> {{ microdepositVerificationRequest?.vendorBusinessName }}</p>
    <p>
        <strong>EIN:</strong>

        <ng-container *ngIf="!unmaskedEin">
            {{ microdepositVerificationRequest?.maskedEin }}
        </ng-container>
        <ng-container *ngIf="!!unmaskedEin">
            {{unmaskedEin}}
        </ng-container>
        <button
            type="button"
            mat-icon-button
            [matTooltip]="!unmaskedEin ? 'View unmasked value' : 'Hide'"
            (click)="maskUnmaskEin()"
        >
            <i-feather [name]="unmaskedEin ? 'eye' : 'eye-off'"></i-feather>
        </button>
    </p>
    <p><strong>Requested:</strong> {{ microdepositVerificationRequest?.requested }}</p>
    <div class='column'>
        <mat-form-field>
            <mat-label>Action</mat-label>
            <mat-select [formControl]="action">
                <mat-option
                    *ngFor="let option of actionOptions"
                    [value]="option.value"
                >
                    {{option.label}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

</mat-dialog-content>
<div class="button-row">
    <button
        mat-flat-button
        color="primary"
        [disabled]="form.invalid"
        (click)="submitAcceptance()"
    >Submit</button>
</div>