import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
    selector: 'lqd-log-dialog',
    templateUrl: './log-dialog.component.html',
    styleUrls: ['./log-dialog.component.scss'],
})
export class LogDialogComponent implements OnInit {

    dataStr: string
    keys: any
    logJson: any

    constructor(
        private dialogRef: MatDialogRef<LogDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
    ) {
        this.dataStr = data
    }

    ngOnInit(): void {
        this.logJson = JSON.parse(this.dataStr)
        this.keys = Object.keys(this.logJson) 
    }

}
