import { Component, Inject } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { tap } from 'rxjs/operators'

import { SelectOption } from '../../core/models'
import { ClientInvoiceService } from '../../core/services'

@Component({
    selector: 'lqd-review-dialog',
    templateUrl: './review-dialog.component.html',
    styleUrls: ['./review-dialog.component.scss'],
})
export class ReviewDialogComponent {

    actionOptions: Array<SelectOption<string>> = [
        {
            label: 'No Action',
            value: undefined,
        },
        {
            label: 'Accept',
            value: 'accepted',
        },
        {
            label: 'Reject',
            value: 'rejected',
        },
        {
            label: 'Set Back To Review',
            value: 'review',
        },
    ]
    title: string = 'DIALOG TITLE'
    description: string = ''
    yesButtonText: string = 'Yes'
    noButtonText: string = 'No'
    disableNoButton: boolean = false

    action: FormControl = new FormControl(undefined)
    invoiceId: string
    invoiceNumber: string
    client: string
    vendor: string
    invoiceAmount: number
    invoiceCurrency: string
    payoutAmount: number
    payoutCurrency: string
    payoutTotalLocked: boolean
    created: string
    workOrderName: string
    message: FormControl = new FormControl(undefined)
    form: FormGroup

    constructor(
        private dialogRef: MatDialogRef<ReviewDialogComponent>,
        private txService: ClientInvoiceService,
        @Inject(MAT_DIALOG_DATA) data: any,
    ) {
        if (!data) {
            return
        }

        if (data.title) {
            this.title = data.title
        }
        if (data.description) {
            this.description = data.description
        }
        if (data.yesButtonText) {
            this.yesButtonText = data.yesButtonText
        }
        if (data.noButtonText) {
            this.noButtonText = data.noButtonText
        }
        if (data.disableNoButton) {
            this.disableNoButton = data.disableNoButton
        }

        this.invoiceId = data.invoiceId

        this.invoiceNumber = data.invoiceNumber
        this.client = data.client
        this.vendor = data.vendor
        this.invoiceAmount = data.invoiceAmount
        this.invoiceCurrency = data.invoiceCurrency
        this.payoutAmount = data.payoutAmount
        this.payoutCurrency = data.payoutCurrency
        this.payoutTotalLocked = data.payoutTotalLocked
        this.created = data.created
        this.workOrderName = data.workOrderName

        this.form = new FormGroup({
            'action': this.action,
            'message': this.message,
        })
    }

    submitReviewAction(): void {
        this.txService.setScheduledPaymentReviewAction(this.invoiceId, this.action.value, this.message.value)
            .pipe(
                tap((tx) => this.dialogRef.close(tx)),
            )
            .subscribe()
    }

}
