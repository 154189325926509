<div>
    <h5>Other Bank Account Properties</h5>

    <p *ngFor="let val of details">

        <span
            *ngIf="val.countryBankAccountProperty.bankAccountPropertyDef.dataType === PropertyDataType.HEADER"
            class="primary100"
        >
            {{val.countryBankAccountProperty.label}}
        </span>

        <span *ngIf="val.countryBankAccountProperty.bankAccountPropertyDef.dataType !== PropertyDataType.HEADER">
            <strong>{{getLabel(val)}}:</strong> {{getValue(val)}}
        </span>

    </p>

</div>
