<mat-card>

    <mat-card-header>

        <mat-card-title>
            <h3>Business Table</h3>
        </mat-card-title>
        <button
            mat-button
            color="primary"
            (click)="addPartial()"
        >
            + Partial Business
        </button>

    </mat-card-header>

    <mat-card-content>
        <app-table
            [id]="tableId"
            class="data-table"
            [noStick]="true"
            [columns]="columns"
            [length]="length"
            [defaultSort]="{ active: 'dateCreated', direction: 'desc' }"
            [customPaging]="true"
            [defaultPageSize]="100"
            [persistPage]="true"
            [data$]="rows$"
            (rowClick)="onRowClick($event)"
            (pageChange)="onPageChange($event)"
            readonlyModeContainerClasses="overflow-x-scroll"
        >
        </app-table>
    </mat-card-content>
</mat-card>