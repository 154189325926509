import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { ErrorStateMatcher } from '@angular/material/core'
import { Observable } from 'rxjs'
import { switchMap, tap } from 'rxjs/operators'

import { KycDocument, KycUploadResponse } from '../core/models'
import { BusinessService } from '../core/services'
import { DirtyOrTouchedErrorStateMatcher } from '../validation'

@Component({
    selector: 'app-kyc-form',
    templateUrl: './kyc-form.component.html',
    styleUrls: ['./kyc-form.component.scss'],
})
export class KycFormComponent implements OnInit {

    private readonly kycDocField: string = 'kycDocument'
    private readonly kycDescriptionField: string = 'kycDescription'
    private readonly kycMessageField: string = 'kycMessage'

    @Input() businessId: string

    @Output() add: EventEmitter<KycDocument> = new EventEmitter()

    readonly errorMatcher: ErrorStateMatcher = new DirtyOrTouchedErrorStateMatcher()
    form: FormGroup
    kycControl: FormControl = new FormControl(undefined)
    kycDescription: FormControl = new FormControl(undefined)
    kycFile: File
    kycMessage: FormControl = new FormControl(undefined)

    constructor(
        private business: BusinessService,
    ) {
        this.form = new FormGroup({
            [this.kycDocField]: this.kycControl,
            [this.kycDescriptionField]: this.kycDescription,
            [this.kycMessageField]: this.kycMessage,
        })
    }

    ngOnInit(): void {
    }

    fileChange($event: any): void {
        this.kycFile = $event.target.files[0]
    }

    submitKycDoc(): void {

        const kycUploadRequest: Observable<KycUploadResponse> = !!this.kycControl.value || this.kycControl.dirty
            ? this.business.uploadKycDoc(this.businessId, this.kycFile)
            : undefined

        kycUploadRequest
            .pipe(
                switchMap(res => {
                    return this.business.createKycDoc({
                        organizationId: this.businessId,
                        description: this.kycDescription.value,
                        message: this.kycMessage.value,
                        originalFileName: this.kycFile.name,
                        storedFileName: res.storedFileName,
                    })
                        .pipe(
                            tap(doc => {
                                this.add.emit(doc)
                                this.resetKyc()
                            }),
                        )
                }),
            )
            .subscribe()
    }

    resetKyc(): void {
        this.kycFile = undefined
        this.form.reset()
    }

}
