import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { AdminBusiness, KycStatus } from '../models'

import { UrlService } from './url.service'

@Injectable({
    providedIn: 'root',
})
export class KycStatusStore {

    private readonly statusUrl: string = this.urls.api.kycStatus()

    constructor(
        private http: HttpClient,
        private urls: UrlService,
    ) { }

    get(): Observable<Array<KycStatus>> {
        return this.http.get<Array<KycStatus>>(this.statusUrl)
    }

    preview(businessId: string, statusId: string, message: string): Observable<AdminBusiness> {
        const request: any = {
            organizationId: businessId,
            kycStatusId: statusId,
            isPreview: true,
            message,
        }
        return this.http.patch<AdminBusiness>(this.statusUrl, request)
    }

    set(businessId: string, statusId: string, message: string): Observable<AdminBusiness> {
        const request: any = {
            organizationId: businessId,
            kycStatusId: statusId,
            isPreview: false,
            message,
        }
        return this.http.patch<AdminBusiness>(this.statusUrl, request)
    }
}
