import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { Bank, OtherBankAccountProperty } from '../../country-bank-account-config'
import { BankInfoReviewDecision, PaymentAccount, PaymentAccountDto } from '../models'

import { UrlService } from './url.service'

@Injectable({
    providedIn: 'root',
})
export class PaymentAccountStore {

    constructor(
        private http: HttpClient,
        private urls: UrlService,
    ) { }

    acceptBank(bank: Bank, paymentAccountId: string, decision: BankInfoReviewDecision): Observable<PaymentAccount> {
        const request: any = {
            bankInformation: bank,
            paymentAccountId,
            acceptanceResponse: decision,
        }
        return this.http.post<PaymentAccount>(this.urls.api.bankAcceptance(), request)
    }

    getBank(swiftCode: string): Observable<Bank> {
        return this.http.get<Bank>(this.urls.api.bankBySwiftCode(swiftCode))
    }

    getLiquidBankAccount(vendorId: string): Observable<PaymentAccountDto> {
        return this.http.get<PaymentAccountDto>(this.urls.api.payoutAccounts(vendorId))
    }

    getOtherBankAccountProps(paymentAccount: string): Observable<Array<OtherBankAccountProperty>> {
        return this.http.get<Array<OtherBankAccountProperty>>(this.urls.api.otherBankAccountProperties(paymentAccount))
    }

    getPlaidBankAccountBalance(orgId: string): Observable<number> {
        return this.http.get<any>(this.urls.api.plaidBankAccountBalance(orgId))
    }
}
