import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { AdminGuard } from '../auth-tools/admin.guard'
import { AuthGuard } from '../auth/services'
import { BusinessesComponent } from '../businesses/businesses.component'
import { KycStatusResolver, PaymentStatusResolver } from '../core/resolvers'

import { BusinessComponent } from './business.component'

const routes: Routes = [
    {
        path: '',
        canActivate: [
            AuthGuard,
            AdminGuard,
        ],
        children: [
            {
                path: '',
                component: BusinessesComponent,
            },
            {
                path: ':businessId',
                component: BusinessComponent,
                resolve: {
                    kycStatuses: KycStatusResolver,
                    paymentStatuses: PaymentStatusResolver,
                },
            },
        ],
    },
]

@NgModule({
    imports: [
        RouterModule.forChild(routes),
    ],
    exports: [
        RouterModule,
    ],
})
export class BusinessRoutingModule { }
