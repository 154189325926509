import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { ErrorHandler, NgModule } from '@angular/core'
import { MatDialogConfig, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog'
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AuthHttpInterceptor, AuthModule as Auth0AuthModule } from '@auth0/auth0-angular'
import { FeatherModule } from 'angular-feather'

import { BusinessTokenInterceptor } from '../../../../src/app/auth/interceptors/business-token.interceptor'
import { Auth0Environment } from '../environments/environment'

import { AddressModule } from './address'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { AuthCallbackComponent } from './auth-callback/auth-callback.component'
import { AdminToolInterceptor } from './auth-tools/admin-tool.interceptor'
import { AuthModule } from './auth/auth.module'
import { LoggingService } from './auth/services'
import { BusinessModule } from './business/business.module'
import { AddPartialBusinessDialogComponent } from './businesses/add-partial-business-dialog/add-partial-business-dialog.component'
import { ChipListModule } from './chip-list/chip-list.module'
import { CoreModule } from './core/core.module'
import { AdminTransactionSortPipe } from './core/pipes'
import { DialogModule } from './dialogs'
import { HomeComponent } from './home/home.component'
import { ReviewClientInvoicesComponent } from './review-client-invoices/review-client-invoices.component'
import { SearchService } from './search/services'
import { SharedModule } from './shared/shared.module'
import { TableModule } from './table/table.module'
import { TaxIdFieldModule } from './tax-id-field'
import { UnassociatedJPMTransactionsModule } from './unassociated-jpm-transactions/unassociated-jpm-transactions.module'
import { UnverifiedAccountsModule } from './unverified-accounts/unverified-accounts.module'

@NgModule({
    bootstrap: [
        AppComponent,
    ],
    declarations: [
        AddPartialBusinessDialogComponent,
        AppComponent,
        AuthCallbackComponent,
        HomeComponent,
        ReviewClientInvoicesComponent,
    ],
    imports: [
        AddressModule,
        AppRoutingModule,
        AuthModule,
        Auth0AuthModule.forRoot(Auth0Environment),
        BrowserAnimationsModule,
        BrowserModule,
        BusinessModule,
        ChipListModule,
        CoreModule,
        DialogModule,
        FeatherModule,
        SharedModule,
        TableModule,
        TaxIdFieldModule,
        UnassociatedJPMTransactionsModule,
        UnverifiedAccountsModule,
    ],
    providers: [
        AdminTransactionSortPipe,
        SearchService,
        { provide: ErrorHandler, useFactory: LoggingService.initialize },
        { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
        // { provide: HTTP_INTERCEPTORS, useClass: AdminToolInterceptor, multi: true },
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { panelClass: 'lqd-snack-bar', duration: 2000 } },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                ...new MatDialogConfig(),
                autoFocus: true,
                minWidth: '300px',
                maxWidth: '800px',
                width: '90%',
                panelClass: 'responsive-dialog',
            },
        },
    ],
})
export class AppModule { }
