<!-- CREATE KYC DOC FOR BIZ -->
<mat-card>
    <form (submit)="submitKycDoc()">
        <mat-card-header>
            <mat-card-title>
                <h3>
                    Add KYC Document
                </h3>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="column">
                <input
                    type="file"
                    required
                    [formControl]="kycControl"
                    (change)="fileChange($event)"
                >
                <mat-form-field>
                    <mat-label>Description</mat-label>
                    <input
                        matInput
                        required
                        [errorStateMatcher]="errorMatcher"
                        [formControl]="kycDescription"
                    >
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Comments</mat-label>
                    <textarea
                        matInput
                        required
                        [errorStateMatcher]="errorMatcher"
                        [formControl]="kycMessage"
                    ></textarea>
                </mat-form-field>
            </div>

        </mat-card-content>

        <mat-card-actions>

            <button
                type="button"
                mat-stroked-button
                (click)="resetKyc()"
                [disabled]="!form.dirty"
            >
                Reset
            </button>

            <button
                type="submit"
                mat-flat-button
                color="primary"
                [disabled]="form.invalid || !kycMessage.dirty || !kycDescription.dirty"
            >
                Add
            </button>

        </mat-card-actions>

    </form>

</mat-card>
