import { ThrowStmt } from '@angular/compiler'
import { Component, Input, OnInit } from '@angular/core'
import { MatDialogConfig } from '@angular/material/dialog'
import { PageEvent } from '@angular/material/paginator'
import { Sort } from '@angular/material/sort'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { catchError, switchMap, take, tap } from 'rxjs/operators'

import { AdminClientInvoiceDetails, AdminJPMTransactionDetails, AdminTransactionHistory, ClientInvoiceProcessingEvent, JPMTransactionCheck, Tab, TableId } from '../core/models'
import { AdminJPMAchPaymentRequest } from '../core/models/admin-jpm-jpm-payment-request.interface'
import { AdminJPMWirePaymentRequest } from '../core/models/admin-jpm-wire-payment-request.interface'
import { AdminJPMAchDebitRequest } from '../core/models/jpm-ach-debit-request.interface'
import { ClientInvoiceService, TabService, UrlService } from '../core/services'
import { DialogService } from '../dialogs'
import { JPMTransactionLogDialogComponent } from '../shared/jpm-transaction-log-dialog/jpm-transaction-log-dialog.component'
import { ListField, PageRequest } from '../table/models'

import { JsonDisplayDialogComponent } from './json-display-dialog/json-display-dialog.component'

@Component({
    selector: 'lqd-client-invoice-associated-info',
    templateUrl: './client-invoice-associated-info.component.html',
    styleUrls: ['./client-invoice-associated-info.component.scss'],
})
export class ClientInvoiceAssociatedInfoComponent implements OnInit {

    private processingEvents: BehaviorSubject<ClientInvoiceProcessingEvent[]> = new BehaviorSubject([])

    @Input() invoiceDetails: AdminClientInvoiceDetails

    clientHistory$: Observable<Array<AdminTransactionHistory>>
    readonly clientHistoryId: string = TableId.clientTransactionHistory
    readonly clientVendorHistoryId: string = TableId.clientVendorTransactionHistory
    clientVendorHistory$: Observable<Array<AdminTransactionHistory>>
    readonly invoiceHistoryColumns: Array<ListField> = [
        {
            id: 'date',
            label: 'Date',
            type: 'date',
        },
        {
            id: 'invoiceNumber',
            label: 'Invoice Number',
        },
        {
            id: 'client',
            label: 'Client',
        },
        {
            id: 'vendor',
            label: 'Vendor',
        },
        {
            id: 'status',
            label: 'Status',
        },
        {
            id: 'amount',
            label: 'Amount',
            type: 'money',
        },
    ]
    jpmTransactionDetails$: Observable<Array<AdminJPMTransactionDetails>>
    readonly jpmTxColumns: ListField[] = [
        {
            id: 'receivedTimestamp',
            label: 'Received',
            type: 'date@time',
        },
        {
            id: 'debitCreditCode',
            label: 'Debit/Credit',
        },
        {
            id: 'productGroupGroupDescription',
            label: 'Type',
        },
        {
            id: 'baiTypeDescription',
            label: 'Description',
        },
        {
            id: 'postCode',
            label: 'Post Code',
        },
        {
            id: 'repairCode',
            label: 'Repair Code',
        },
        {
            id: 'formattedAmount',
            label: 'Amount',
        },
        {
            id: 'customerReferenceSearchableStandardValue',
            label: 'Cust. Ref',
        },
        {
            id: 'bankReferenceSearchableStandardValue',
            label: 'Bank Ref',
        },
        {
            id: 'payoutAmount',
            label: 'FX Amount',
        },
    ]
    readonly jpmTxLogsId: string = TableId.jpmTxLogsId

    processingEventsLength: number
    readonly processingEventsRows$: Observable<Array<ClientInvoiceProcessingEvent>> = this.processingEvents.asObservable()
    readonly processingEventsTableId: string = TableId.processingEvents
    readonly processingEventsColumns: ListField[] = [
        {
            id: 'invoiceNumber',
            label: 'Invoice Number',
            type: 'number',
        },
        {
            id: 'actionDate',
            label: 'Action Date',
            type: 'date@time',
        },
        {
            id: 'action',
            label: 'Action',
        },
        {
            id: 'invoicerName',
            label: 'Invoicer Name',
        },
        {
            id: 'clientName',
            label: 'Client Name',
        },
        {
            id: 'actionTakenBy',
            label: 'Action Taken By',
        },
        {
            id: 'invoiceTotal',
            label: 'Invoice Total',
            type: 'money',
        },
        {
            id: 'invoiceCurrency',
            label: 'Invoice Currency',
        },

        {
            id: 'payoutTotal',
            label: 'Payout Total',
            type: 'money',
        },
        {
            id: 'payoutCurrency',
            label: 'Payout Currency',
        },
        {
            id: 'paymentScheduledAmount',
            label: 'Payment Scheduled Amount',
            type: 'money',
        },
        {
            id: 'paymentScheduledCurrency',
            label: 'Payment Scheduled Currency',
        },
        {
            id: 'chargeAmount',
            label: 'Charge Amount',
            type: 'money',
        },
        {
            id: 'chargeCurrency',
            label: 'Charge Currency',
        },
        {
            id: 'chargeResponse',
            label: 'Charge Response',
        },
        {
            id: 'debitTransactionStatus',
            label: 'Debit Transaction Status',
        },
        {
            id: 'payoutTransactionStatus',
            label: 'Payout Transaction Status',
        },
    ]

    readonly tntDetailsColumns: ListField[] = [
        {
            id: 'sentOnDate',
            label: 'Instruction Sent',
            type: 'date@time',
        },
        {
            id: 'payoutType',
            label: 'Payout Type',
        },
        {
            id: 'amount',
            label: 'Amount',
        },
        {
            id: 'e2eId',
            label: 'EndToEndId',
        },
        {
            id: 'statusCode',
            label: 'Status Code',
        },
        {
            id: 'statusText',
            label: 'Status Text',
        },
        {
            id: 'lastStatus',
            label: 'Last Status',
        },
        {
            id: 'cmaDirection',
            label: 'CMA Direction',

        },
        {
            id: 'tnt',
            label: 'TnT',
            type: 'check-button',
            checkButtonIcon: 'zap',
            notSortable: true,
            checkButtonClass: (payout: any, col: ListField): string => {
                return 'success'
            },
        },
        {
            id: 'viewReq',
            label: 'Req',
            type: 'check-button',
            checkButtonIcon: 'eye',
            notSortable: true,
            checkButtonClass: (payout: any, col: ListField): string => {
                return 'success'
            },
        },
        {
            id: 'viewPC',
            label: 'Purpose Code',
            type: 'check-button',
            checkButtonIcon: 'eye',
            notSortable: true,
            checkButtonClass: (payout: any, col: ListField): string => {
                return 'success'
            },
        },
    ]
    readonly tntDetailsTableId: string = TableId.trackAndTraces
    trackAndTraces$: Observable<Array<any>>

    readonly transactionLogId: string = TableId.transactionLogs
    vendorHistory$: Observable<Array<AdminTransactionHistory>>
    readonly vendorHistoryId: string = TableId.vendorTransactionHistory

    constructor(
        private dialogs: DialogService,
        private clientInvoiceSvc: ClientInvoiceService,
        private tabService: TabService,
        private urls: UrlService,
    ) { }

    ngOnInit(): void {
        this.clientHistory$ = of(this.invoiceDetails.clientHistory)
        this.clientVendorHistory$ = of(this.invoiceDetails.clientVendorHistory)
        this.vendorHistory$ = of(this.invoiceDetails.vendorHistory)
        this.invoiceDetails?.jpmTransactionDetails.forEach(d => {
            d['formattedAmount'] = `${d.amount.toFixed(2)} ${d.currencyCode}`
        })
        this.jpmTransactionDetails$ = of(this.invoiceDetails.jpmTransactionDetails)
        const trackAndTraces: Array<any> = []
        this.setTrackAndTraces(trackAndTraces)
    }

    onProcessingEventsPageChange(event: { page: PageEvent, sort: Sort }): void {
        if (!event?.page?.pageSize || event?.page?.pageIndex < 0 || !event?.sort?.active || !event?.sort?.direction) {
            return
        }

        const page: PageEvent = event.page
        const sort: Sort = event.sort
        const pageRequest: PageRequest = {
            page: page.pageIndex + 1,
            pageSize: page.pageSize,
            sortBy: `${sort.active} ${sort.direction}`,
        }

        this.clientInvoiceSvc.getInvoiceProcessingEvents(this.invoiceDetails.invoiceId, pageRequest)
            .pipe(
                tap(data => {
                    this.processingEventsLength = data.rowCount
                    this.processingEvents.next(data.results.map(c => c))
                }),
            )
            .subscribe()
    }

    onRowClick(event: AdminTransactionHistory): void {
        const tab: Tab = {
            label: `${event.client} - Inv ${event.invoiceNumber}`,
            removable: true,
            route: this.urls.route.clientInvoiceDetails(event.id),
        }
        this.tabService.addTab(tab)
    }

    jpmTxRowClick(event: AdminJPMTransactionDetails): void {
        this.popReviewDialog(event)
    }

    onCheckButtonClick(event: { field: ListField, row: any }): void {
        const dialogConfig: MatDialogConfig = new MatDialogConfig()
        dialogConfig.data = {}

        if (event?.field?.id === 'tnt' && !!event?.row?.e2eId) {
            this.clientInvoiceSvc.getPaymentTnT(event?.row?.e2eId)
                .pipe(
                    switchMap(tnt => {
                        dialogConfig.data = { object: tnt, title: `Payment Track And Trace: ${event?.row?.e2eId}` }
                        return this.dialogs.open(JsonDisplayDialogComponent, dialogConfig)
                            .afterClosed()
                            .pipe(
                                take(1),
                                tap(),
                                catchError(error => this.dialogs.error(error)),
                            )
                    }),
                )
                .subscribe()
        } else if (event?.field?.id === 'viewReq' && !!event?.row?.id) {
            this.clientInvoiceSvc.getJPMReqForPayout(event?.row?.id)
                .pipe(
                    switchMap(tnt => {
                        dialogConfig.data = { object: tnt, title: `Payout Request: ${event?.row?.e2eId}` }
                        return this.dialogs.open(JsonDisplayDialogComponent, dialogConfig)
                            .afterClosed()
                            .pipe(
                                take(1),
                                tap(),
                                catchError(error => this.dialogs.error(error)),
                            )
                    }),
                )
                .subscribe()
        } else if (event?.field?.id === 'viewPC' && !!event?.row?.id) {
            dialogConfig.data = {
                object: {
                    purposeCode: event?.row?.purposeCode,
                    purposeCodeIsForDebtorAgent: event?.row?.purposeCodeIsForDebtorAgent,
                },
                title: `Purpose Code: ${event?.row?.purposeCode}`,
            }
            this.dialogs.open(JsonDisplayDialogComponent, dialogConfig)
                .afterClosed()
                .pipe(
                    take(1),
                    tap(),
                    catchError(error => this.dialogs.error(error)),
                )
                .subscribe()
        }
    }

    private popReviewDialog(details: AdminJPMTransactionDetails): void {
        const dialogConfig: MatDialogConfig = new MatDialogConfig()
        dialogConfig.data = {}

        this.clientInvoiceSvc.getJPMTransactionDetails(details.id)
            .pipe(
                switchMap(d => {
                    dialogConfig.data = { details: d }
                    return this.dialogs.open(JPMTransactionLogDialogComponent, dialogConfig)
                        .afterClosed()
                        .pipe(
                            take(1),
                            tap(),
                            catchError(error => this.dialogs.error(error)),
                        )
                }),
            )
            .subscribe()
    }

    private setTrackAndTraces(trackAndTrace: any[]): void {
        if (!!this.invoiceDetails.jpmAchDebitRequests) {
            this.invoiceDetails.jpmAchDebitRequests.forEach((debitRequest: AdminJPMAchDebitRequest) => {
                const row: any = {}
                row.id = debitRequest.id
                row.cmaDirection = 'Payment to CMA'
                row.tnt = true
                row.viewReq = !!debitRequest.responseReceivedOn
                row.payoutType = 'JPM Ach Debit'
                row.sentOnDate = debitRequest.responseReceivedOn
                row.e2eId = debitRequest.endToEndId
                row.lastStatus = debitRequest.lastStatus
                row.amount = `${debitRequest.debitTransactionInstructedAmount.toFixed(2)} ${debitRequest.debitTransactionInstructedCurrency}`
                const wr: any = debitRequest.jpmAchDebitResponse
                if (!!wr) {
                    row.statusCode = wr.statusCode
                    row.statusText = wr.statusText
                }

                trackAndTrace.push(row)

            })
        }

        if (!!this.invoiceDetails.jpmAchPaymentRequests) {
            this.invoiceDetails.jpmAchPaymentRequests.forEach((paymentRequest: AdminJPMAchPaymentRequest) => {
                const row: any = {}
                row.id = paymentRequest.id
                row.cmaDirection = 'Payment from CMA'
                row.tnt = true
                row.viewReq = !!paymentRequest.responseReceivedOn
                row.viewPC = !!paymentRequest.responseReceivedOn
                row.payoutType = 'JPM Ach Payment'
                row.sentOnDate = paymentRequest.responseReceivedOn
                row.e2eId = paymentRequest.endToEndId
                row.lastStatus = paymentRequest.lastStatus
                row.amount = `${paymentRequest.creditTransferInstructedAmount.toFixed(2)} ${paymentRequest.creditTransferInstructedCurrency}`
                const wr: any = paymentRequest.jpmAchPaymentResponse
                if (!!wr) {
                    row.statusCode = wr.statusCode
                    row.statusText = wr.statusText
                }

                trackAndTrace.push(row)
            })
        }

        if (!!this.invoiceDetails.jpmWirePaymentRequests) {
            this.invoiceDetails.jpmWirePaymentRequests.forEach((paymentRequest: AdminJPMWirePaymentRequest) => {
                const row: any = {}
                row.id = paymentRequest.id
                row.cmaDirection = 'Payout from CMA'
                row.tnt = true
                row.viewReq = !!paymentRequest.responseReceivedOn
                row.viewPC = !!paymentRequest.responseReceivedOn
                row.payoutType = 'JPM Wire'
                row.sentOnDate = paymentRequest.responseReceivedOn
                row.e2eId = paymentRequest.endToEndId
                row.purposeCode = paymentRequest.purposeCode
                row.purposeCodeIsForDebtorAgent = paymentRequest.purposeCodeIsForDebtorAgent
                row.lastStatus = paymentRequest.lastStatus
                row.amount = `${paymentRequest.creditTransferInstructedAmount.toFixed(2)} ${paymentRequest.creditTransferInstructedCurrency}`
                const wr: any = paymentRequest.jpmWirePaymentResponse
                if (!!wr) {
                    row.statusCode = wr.statusCode
                    row.statusText = wr.statusText
                }

                trackAndTrace.push(row)
            })
        }

        this.trackAndTraces$ = of(trackAndTrace)
    }
}
