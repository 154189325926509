import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { take } from 'rxjs/operators'

import { Bank, ForeignUSBankAccountRequest, OtherBankAccountProperty } from '../../country-bank-account-config'
import { PaymentAccountDto } from '../models'
import { BankInfoReviewDecision, PaymentAccount } from '../models'

import { PaymentAccountStore } from './payment-account.store'

@Injectable({
    providedIn: 'root',
})
export class PaymentAccountService {

    constructor(
        private store: PaymentAccountStore,
    ) { }

    acceptBank(bank: Bank, paymentAccountId: string, decision: BankInfoReviewDecision): Observable<PaymentAccount> {
        return this.store.acceptBank(bank, paymentAccountId, decision)
            .pipe(
                take(1),
            )
    }

    createForeignVendorUSABankAccount(request: any): Observable<any> {
        const requestObject: ForeignUSBankAccountRequest = <ForeignUSBankAccountRequest>request
        return of(requestObject)
    }

    getBank(swiftCode: string): Observable<Bank> {
        return this.store.getBank(swiftCode)
            .pipe(
                take(1),
            )
    }

    getLiquidBankAccount(vendorId: string): Observable<PaymentAccountDto> {
        return this.store.getLiquidBankAccount(vendorId)
            .pipe(
                take(1),
            )
    }

    getOtherBankAccountProps(paymentAccountId: string): Observable<Array<OtherBankAccountProperty>> {
        return this.store.getOtherBankAccountProps(paymentAccountId)
            .pipe(
                take(1),
            )
    }

    getPlaidBankAccountBalance(orgId: string): Observable<number> {
        return this.store.getPlaidBankAccountBalance(orgId)
            .pipe(
                take(1),
            )
    }
}
