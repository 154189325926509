<div class="container">

    <h1> Liquid Admin Tool </h1>

    <nav mat-tab-nav-bar>
        <div
            *ngFor="let tab of tabs; let tabIndex = index"
            class="tab"
        >
            <a
                mat-tab-link
                [routerLink]="tab.route"
                routerLinkActive
                #rla="routerLinkActive"
                [active]="rla.isActive"
                [routerLinkActiveOptions]="{exact: true}"
            >{{tab.label}}
            </a>

            <i-feather
                *ngIf="tab.removable"
                name='x'
                (click)="removeTab(tabIndex)"
            ></i-feather>
        </div>
    </nav>

    <div class="main-content">
        <router-outlet></router-outlet>
    </div>
    <p>
        <a (click)="logOut()">
            log out
        </a>
    </p>
</div>