import { Component, Input } from '@angular/core'
import { FormControl } from '@angular/forms'
import { ErrorStateMatcher } from '@angular/material/core'
import { Observable } from 'rxjs'
import { take } from 'rxjs/operators'

import { UrlService } from '../core/services'
import { DialogService } from '../dialogs'
import { DirtyOrTouchedErrorStateMatcher, formatEin, formatSsn } from '../validation'

import { TaxIdType } from './tax-id-type.enum'

@Component({
    selector: 'app-tax-id-field',
    templateUrl: './tax-id-field.component.html',
    styleUrls: ['./tax-id-field.component.scss'],
})
export class TaxIdFieldComponent {

    @Input() hideHint: boolean
    @Input() maskedEin: string
    @Input() maskedSsn: string
    @Input() required$: Observable<boolean>
    @Input() taxId: FormControl
    @Input() taxIdType: TaxIdType
    @Input() ssnItinLabel: boolean

    readonly einHint: string = '00-0000000'
    readonly errorMatcher: ErrorStateMatcher = new DirtyOrTouchedErrorStateMatcher()
    readonly ssnOrItinHint: string = '000-00-0000'
    readonly createEinUrl: string = this.urls.external.createEin

    get isSsnOrItin(): boolean { return this.taxIdType === TaxIdType.Ssn || this.taxIdType === TaxIdType.Itin }
    get label(): string { return this.ssnItinLabel && this.isSsnOrItin ? `SSN or ITIN` : (this.taxIdType || 'Tax ID') }

    constructor(
        private dialogs: DialogService,
        private urls: UrlService,
    ) { }

    formatEinControl(isSsn?: boolean): void {

        isSsn = isSsn !== undefined && isSsn !== null ? isSsn : this.isSsnOrItin

        // if this is the original masked ein/ssn, don't do anything
        if ((isSsn && this.taxId.value === this.maskedSsn)
            || !isSsn && this.taxId.value === this.maskedEin) {
            return
        }

        isSsn ? formatSsn(this.taxId) : formatEin(this.taxId)
    }

    popEINInfo(): void {
        this.dialogs.inform(`<p>Your EIN (Employer Identification Number) is a unique 9-digit number that is issued by the IRS to identify your business.</p>
        <p>This number will be recorded on any of your previous federal tax return, official tax notices from the IRS, or listed as your Tax ID on any business licenses and permits.
        It will also be on the official letter you should have received from the IRS when initially applying for your EIN, or you can
        <a href="https://www.irs.gov/businesses/small-businesses-self-employed/lost-or-misplaced-your-ein">contact the IRS directly</a> if you have misplaced all of these records. </p>
        <p>If you are self-employed, or working with a business located in the US but your business is incorporated and located outside the US, you are not required to have an EIN.</p>`, 'Where can I find my EIN?')
            .pipe(
                take(1),
            )
            .subscribe()
    }
}
