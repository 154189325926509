<div class="select-country-header">
    <h5>Add Partial Business</h5>
</div>
<div>
    <div class='column'>
        <mat-form-field>
            <mat-label>Business Name</mat-label>
            <input
                matInput
                [formControl]="businessName"
                required
            />
        </mat-form-field>
        <mat-form-field>
            <mat-label>Initial Invitee Email Address</mat-label>
            <input
                matInput
                [formControl]="inviteeEmailAddress"
                required
            />
        </mat-form-field>
        <mat-form-field>
            <mat-label>Invitee Name</mat-label>
            <input
                matInput
                [formControl]="toName"
                required
            />
        </mat-form-field>
        <mat-form-field>
            <mat-label>Message</mat-label>
            <textarea
                matInput
                required
                [formControl]="message"
            ></textarea>
        </mat-form-field>
    </div>
</div>

<div class="dialog-button">
    <button
        type="button"
        mat-stroked-button
        (click)="cancel()"
    >
        Cancel
    </button>
    <button
        mat-flat-button
        color="primary"
        (click)="createBusiness()"
    >
        Create Business
    </button>
</div>