import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { FeatherModule } from 'angular-feather'

import { TaxIdFieldComponent } from './tax-id-field.component'

@NgModule({
    declarations: [
        TaxIdFieldComponent,
    ],
    exports: [
        TaxIdFieldComponent,
    ],
    imports: [
        CommonModule,
        FeatherModule,
        FormsModule,
        MatInputModule,
        MatFormFieldModule,
        ReactiveFormsModule,
    ],
})
export class TaxIdFieldModule { }
