import { Pipe, PipeTransform } from '@angular/core'

import { PaymentAccountDto, PaymentAccountStatus } from '../core/models'

@Pipe({
    name: 'paymentAccountStatusColor',
})
export class PaymentAccountStatusColorPipe implements PipeTransform {

    transform(paymentAccount: PaymentAccountDto): string {

        if (!paymentAccount?.currentVerificationStatus) {
            return 'primary'
        }

        switch (paymentAccount.currentVerificationStatus) {
            case PaymentAccountStatus.InReview:
                return 'warn'
            case PaymentAccountStatus.Verified:
                return 'success'
            default:
                return 'error'
        }
    }
}
