import { Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { catchError, take, tap } from 'rxjs/operators'

import { SelectOption } from '../../core/models'
import { BusinessService } from '../../core/services'
import { CountryCurrencyConfiguration } from '../../country-bank-account-config'
import { DialogService } from '../../dialogs'

@Component({
    selector: 'lqd-add-partial-business',
    templateUrl: './add-partial-business-dialog.component.html',
    styleUrls: ['./add-partial-business-dialog.component.scss'],
})
export class AddPartialBusinessDialogComponent implements OnInit {

    businessName: FormControl = new FormControl(undefined, Validators.required)
    inviteeEmailAddress: FormControl = new FormControl(undefined, Validators.required)
    toName: FormControl = new FormControl(undefined, Validators.required)
    message: FormControl = new FormControl('Hello, we have set up your business for you.', Validators.required)

    constructor(
        private businesses: BusinessService,
        private dialogs: DialogService,
        @Inject(MAT_DIALOG_DATA) data: {},
    ) { }

    ngOnInit(): void { }

    cancel(): void {
        this.dialogs.close()
    }

    createBusiness(): void {
        const req: any = {
            businessName: this.businessName.value,
            inviteeEmailAddress: this.inviteeEmailAddress.value,
            toName: this.toName.value,
            message: this.message.value,
            isHirer: true,
            isWorker: false,
        }
        this.businesses.createPartialBusiness(req)
            .pipe(
                tap(newOrg => {
                    this.dialogs.inform(`Created new business: ${newOrg.name} [${newOrg.shortName}]`, `Partial Business Created`, 'OK')
                        .pipe(
                            tap(() => {
                                this.dialogs.close()
                            }),
                        )
                        .subscribe()
                }),
                catchError(error => this.dialogs.error(error)),
            )
            .subscribe()
    }
}
