export enum AdminRequestType {
    BeneficialOwner = 'AdminBeneficialOwnerRequest',
    BusinessFlag = 'AdminBusinessFlagsUpdateRequest',
    BusinessUnmask = 'AdminBusinessUnmaskFieldRequest',
    DummyAccount = 'AdminDummyPaymentAccountSetupRequest',
    KycDocument = 'AdminKycDocumentRequest',
    KycStatus = 'AdminApiSetKycStatusRequest',
    PaymentAccountUnmask = 'AdminPaymentAccountUnmaskFieldRequest',
    PaymentStatus = 'AdminApiSetPaymentStatusRequest',
    TransactionStatus = 'AdminTransactionStatusRequest',
}
