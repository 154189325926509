import { Clipboard } from '@angular/cdk/clipboard'
import { Component } from '@angular/core'
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog'
import { PageEvent } from '@angular/material/paginator'
import { Sort } from '@angular/material/sort'
import { BehaviorSubject, Observable } from 'rxjs'
import { filter, switchMap, take, tap } from 'rxjs/operators'

import { OrganizationRole } from '../auth/models'
import { RolesService } from '../auth/services'
import { BusinessRouteFactory } from '../core/factories'
import { AdminUserRow, BusinessRoute, SelectOption, Tab, TableId } from '../core/models'
import { BusinessService, TabService, UrlService, UserService } from '../core/services'
import { DialogTemplateType } from '../dialogs'
import { ConfirmComponent } from '../dialogs/confirm/confirm.component'
import { DialogService } from '../dialogs/dialog.service'
import { ListField, PageRequest } from '../table/models'

@Component({
    selector: 'lqd-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
})
export class UserTableComponent {

    private results: BehaviorSubject<AdminUserRow[]> = new BehaviorSubject([])

    readonly columns: ListField[] = [
        {
            id: 'name',
            label: 'Name',
        },
        {
            id: 'email',
            label: 'Email',
        },
        {
            id: 'emailVerified',
            label: 'Verified?',
            type: 'check-button',
        },
        {
            id: 'bizRoles',
            label: 'Businesses - Roles',
            type: 'list-nav',
            notSortable: true,
        },
        {
            id: 'getImpLink',
            label: 'Impersonate',
            checkButtonIcon: 'link',
            type: 'check-button',
            notSortable: true,
        },
    ]
    length: number
    orgRoles: OrganizationRole[]
    readonly rows$: Observable<AdminUserRow[]> = this.results.asObservable()
    readonly tableId: string = TableId.dashboardUsers

    constructor(
        private business: BusinessService,
        private clipboard: Clipboard,
        private dialogs: DialogService,
        private roles: RolesService,
        private tabService: TabService,
        private user: UserService,
        private factory: BusinessRouteFactory,
        private urls: UrlService,
    ) { }

    onRowClick(item: any): void {
        const tab: Tab = {
            label: item.value.businessName,
            removable: true,
            route: item.value.route,
        }
        this.tabService.addTab(tab)
    }

    onCheckButtonClick(item: any): void {
        if (item.field.id === 'getImpLink') {
            this.getImpersonationLink(item)
        } else if (item.row.emailVerified) {
            return
        } else {
            this.confirmEmailVerification(item)
        }
    }

    confirmEmailVerification(item: any): void {
        const dialogConfig: MatDialogConfig = new MatDialogConfig()
        dialogConfig.disableClose = true
        dialogConfig.data = {
            description: `Are you sure you want to verify ${item.row.email}?`,
            title: 'Email Verification',
            yesButtonText: 'Yes',
        }
        const dialogRef: MatDialogRef<ConfirmComponent> = this.dialogs.open(ConfirmComponent, dialogConfig, DialogTemplateType.medium)
        dialogRef.afterClosed()
            .pipe(
                filter(result => !!result),
                tap(() => {
                    this.user.verifyEmailAddress(item.row.emailId)
                        .pipe(
                            tap((res) => {
                                item.row.emailVerified = res.successful
                            }),
                        ).subscribe()
                }),
            )
            .subscribe()
    }

    getImpersonationLink(item: any): void {
        const liquidProfileId = item.row.id
        this.user.getImpersonationLink(liquidProfileId)
            .pipe(
                take(1),
                filter(result => !!result),
                tap((linkResponse: any) => {
                    const dialogConfig: MatDialogConfig = new MatDialogConfig()
                    dialogConfig.disableClose = true
                    dialogConfig.data = {
                        description: `${linkResponse}`,
                        title: 'Use this link in a private browsing window, and log in as yourself.',
                        yesButtonText: 'Copy To Clipboard',
                    }
                    const dialogRef: MatDialogRef<ConfirmComponent> = this.dialogs.open(ConfirmComponent, dialogConfig, DialogTemplateType.medium)
                    dialogRef.afterClosed()
                        .pipe(
                            filter(result => !!result),
                            tap((result) => {
                                if (result) {
                                    this.clipboard.copy(linkResponse)
                                }
                            }),
                        )
                        .subscribe()
                }),
            ).subscribe()
    }

    onPageChange(event: { page: PageEvent, sort: Sort }): void {

        if (!event?.page?.pageSize || event?.page?.pageIndex < 0 || !event?.sort?.active || !event?.sort?.direction) {
            return
        }

        const page: PageEvent = event.page
        const sort: Sort = event.sort

        const sortBy: string = sort.active === 'name'
            ? `firstName ${sort.direction} lastName ${sort.direction}`
            : `${sort.active} ${sort.direction}`

        const pageRequest: PageRequest = {
            page: page.pageIndex + 1,
            pageSize: page.pageSize,
            sortBy,
        }

        this.business.getRoles()
            .pipe(
                tap(roles => this.orgRoles = roles),
                switchMap(() => this.user.get(pageRequest)),
                tap(user => {
                    this.length = user.rowCount
                    const rows: AdminUserRow[] = user.results
                        .map(userResult => ({
                            name: userResult.name,
                            email: userResult.email,
                            emailId: userResult.emailId,
                            emailVerified: userResult.emailVerified,
                            id: userResult.userId,
                            getImpLink: 'link',
                            bizRoles: userResult.businesses
                                .map(bizRole => ({
                                    label: `${bizRole.businessName} - ${this.roles.getHighestRoleAssigned(bizRole.roles, this.roles.structureRoles(this.orgRoles))}`,
                                    value: this.factory.create(bizRole, this.orgRoles),
                                })),
                        }))
                    this.results.next(rows)
                }),
            )
            .subscribe()
    }
}
