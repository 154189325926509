import { Injectable } from '@angular/core'
import { Resolve } from '@angular/router'
import { Observable } from 'rxjs'

import { KycStatus } from '../models'
import { KycStatusService } from '../services'

@Injectable({
    providedIn: 'root',
})
export class KycStatusResolver implements Resolve<Array<KycStatus>> {

    constructor(
        private kycStatuses: KycStatusService,
    ) { }

    resolve(): Observable<Array<KycStatus>> {
        return this.kycStatuses.get()
    }
}
