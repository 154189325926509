import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { UrlService } from '../../core/services'
import { DialogService } from '../../dialogs'
import { PagedSet, PageRequest } from '../../table/models'
import { BankRow } from '../models'
import { BankDetails, IntermediaryBank } from '../models/bank-details.interface'

@Injectable({
    providedIn: 'root',
})
export class BankService {

    constructor(
        private dialogs: DialogService,
        private readonly http: HttpClient,
        private urls: UrlService,
    ) { }

    createBank(req: any): Observable<BankDetails> {
        return this.http.post<BankDetails>(this.urls.api.bankCreate(), req)
    }

    updateBank(req: any): Observable<BankDetails> {
        return this.http.patch<BankDetails>(this.urls.api.bankCreate(), req)
    }

    createIntermediaryBankSetup(req: any): Observable<IntermediaryBank> {
        return this.http.post<IntermediaryBank>(this.urls.api.intermediaryBankCreate(), req)
    }

    deleteIntermediaryBankSetup(id: string): Observable<IntermediaryBank> {
        return this.http.delete<IntermediaryBank>(this.urls.api.intermediaryBankDelete(id))
    }

    getBanks(page: PageRequest, swiftFilter: string): Observable<PagedSet<BankRow>> {
        let params: HttpParams = new HttpParams()
        params = params.appendAll({
            page: String(page.page),
            pageSize: String(page.pageSize),
            sortString: page.sortBy,
        })

        if (!!swiftFilter) {
            params = params.append('swiftFilter', swiftFilter)
        }

        return this.http.get<PagedSet<BankRow>>(this.urls.api.banks(), { params })
    }

    getBankDetail(bankId: string): Observable<BankDetails> {
        return this.http.get<BankDetails>(this.urls.api.bankDetails(bankId), {})
    }

    getBankDetailBySwift(swiftCode: string): Observable<BankDetails> {
        return this.http.get<BankDetails>(this.urls.api.bankDetailsBySwift(swiftCode), {})
    }
}
