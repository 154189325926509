<app-dialog-template-plain
    [title]="'Review Payment Account'"
    (close)="handleClose()"
>

    <div class="dialog-content">

        <div
            *ngIf="wasRejected"
            class="inline-error"
        >
            <p>This Payment Account was Rejected.</p>
            <p>You will need to Accept it in order to Approve any Invoices associated w/this Vendor.</p>
        </div>

        <h5>Payment Account Details</h5>
        <p><strong>Type:</strong> {{ paymentAccount?.paymentAccountType }}</p>
        <p><strong>Created By:</strong> {{ paymentAccount?.createdBy }}</p>
        <p><strong>Country:</strong>: {{ paymentAccount?.country}}</p>
        <p><strong>Currency:</strong> {{ paymentAccount?.currency }}</p>
        <p><strong>Account Status:</strong> {{ paymentAccount?.status }}</p>
        <p *ngIf="paymentAccount?.accountNumber">
            <strong>Account Number:</strong> {{ paymentAccount?.accountNumber }}
        </p>
        <!-- if there is an account number, display the routing number as well, even if the routing number is null -->
        <p *ngIf="paymentAccount?.accountNumber">
            <strong>Routing Number:</strong> {{ paymentAccount?.routingNumber }}
        </p>

        <lqd-other-bank-account-details
            *ngIf="!!details"
            [details]="details"
        >
        </lqd-other-bank-account-details>

        <h5>Business Details</h5>

        <div *ngIf="!!business">
            <p><strong>Business Name:</strong> {{ business.name }}</p>
            <p><strong>Business Address:</strong> {{ displayAddress }}</p>
            <p><strong>KYC Status:</strong> {{ business.kycStatus }}</p>
            <p><strong>Payment Status:</strong> {{ business.paymentStatus }}</p>
            <p><strong>Admin Logs:</strong></p>
            <div class='logs-container'>
                <p *ngFor="let log of business.logs">{{log.requestedBy ? log.requestedBy : 'Unknown'}}
                    ({{log.requestDateTime | date : 'short'}}): {{log.message}}</p>
            </div>
        </div>

        <div
            *ngIf="!isVerifiable"
            class="inline-warning"
        >
            <p>Plaid Bank Accounts cannot be verified manually.</p>
        </div>

        <div class='column'>
            <mat-form-field>
                <mat-label>Status</mat-label>
                <mat-select
                    [formControl]="status"
                    [disabled]="!isVerifiable"
                >
                    <mat-option
                        *ngFor="let option of statusOptions"
                        [value]="option.value"
                    >
                        {{option.label}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Message</mat-label>
                <textarea
                    matInput
                    required
                    [formControl]="message"
                    [disabled]="!isVerifiable"
                ></textarea>
            </mat-form-field>
        </div>

    </div>

    <div class="dialog-button">
        <button
            mat-flat-button
            color="primary"
            [disabled]="form.invalid"
            (click)="submitVerify()"
        >
            Submit</button>
    </div>

</app-dialog-template-plain>