<h3>
    {{invoiceDetails?.client}} - Invoice # {{invoiceDetails?.invoiceNumber}}
</h3>
<mat-card>
    <div
        *ngIf="isRejectedVendorBank"
        class="inline-warning"
    >
        This vendor's <a (click)="openVerifyVendorAccountDialog()">payout account</a> was rejected, so the Invoice
        cannot be Reviewed.
    </div>
    <mat-card-header>
        <mat-card-title>
            <h4>
                Invoice Details
            </h4>
        </mat-card-title>
        <button
            *ngIf="inReview"
            mat-flat-button
            color="primary"
            (click)="popReviewDialog()"
            [disabled]="!!isPendingVendorBankVerification || !!isRejectedVendorBank"
        >
            Review
        </button>
        <button
            *ngIf="isPendingVendorBankVerification"
            mat-flat-button
            color="primary"
            (click)="openVerifyVendorAccountDialog()"
        >
            Verify Vendor Account
        </button>
    </mat-card-header>
    <div>
        <div class="detail">
            <div>
                <span class="k">In Review:</span>
                <span class="v">{{inReview ? 'Yes' : 'No'}}</span>
            </div>
        </div>
        <div class="detail">
            <div>
                <span class="k">Client:</span>
                <span
                    class="primary clickable"
                    (click)="navigateToBiz(true)"
                >{{invoiceDetails?.client}}</span>
            </div>
            <div>
                <span class="k">Payment Instruction:</span>
                <span class="v">{{invoiceDetails?.paymentAndPayoutSummary?.paymentAmount?.toFixed(2)}}
                    {{invoiceDetails?.paymentAndPayoutSummary?.paymentCurrency}}
                    via {{paymentRequestType}} on
                    {{invoiceDetails?.paymentAndPayoutSummary?.paymentSentOn ?
                    (addTimezone(invoiceDetails?.paymentAndPayoutSummary?.paymentSentOn) | date: 'shortDate') + ' @ ' +
                    (addTimezone(invoiceDetails?.paymentAndPayoutSummary?.paymentSentOn) | date: 'shortTime') : '-'
                    }}</span>
            </div>
            <div>
                <a
                    (click)="showResendOptions = true"
                    *ngIf="!showResendOptions"
                >Resend Payment</a>
                <div
                    *ngIf="showResendOptions"
                    class="div-resend"
                    [formGroup]="resendForm"
                >
                    <span class="k">Resend Payment:</span>
                    <br><br>
                    <mat-checkbox
                        color="primary"
                        formControlName="bypassPayout"
                    >Bypass Payouts</mat-checkbox>
                    <br> <br>
                    <button
                        class="button-resend"
                        mat-raised-button
                        color="primary"
                        (click)="resendPayment()"
                    >Re-send Payment</button>
                    <button
                        class="button-resend"
                        mat-raised-button
                        color="warn"
                        (click)="showResendOptions = false"
                    >Cancel</button>
                </div>
            </div>
        </div>
        <div class="detail">
            <div>
                <span class="k">Vendor:</span>
                <span
                    class="primary clickable"
                    (click)="navigateToBiz(false)"
                >{{invoiceDetails?.vendor}}</span>
            </div>
            <div>
                <span class="k">Payout Instruction:</span>
                <span class="v">{{invoiceDetails?.paymentAndPayoutSummary?.payoutAmount?.toFixed(2)}}
                    {{invoiceDetails?.paymentAndPayoutSummary?.payoutCurrency}}
                    via {{payoutRequestType}} on
                    {{invoiceDetails?.paymentAndPayoutSummary?.payoutSentOn ?
                    (addTimezone(invoiceDetails?.paymentAndPayoutSummary?.payoutSentOn) | date: 'shortDate') + ' @ ' +
                    (addTimezone(invoiceDetails?.paymentAndPayoutSummary?.payoutSentOn) | date: 'shortTime') : '-' }}
                </span>
            </div>
            <div *ngIf='invoiceDetails?.estimatedFXRateQuote'>
                <div>
                    <span class="k">Vendor FX Country: </span>
                    <span class="v">{{invoiceDetails.estimatedFXRateQuote.quotedCurrencyCountryName}}</span>
                </div>
                <div>
                    <span class="k">Quoted FX Rate Estimate: </span>
                    <span class="v">1 {{invoiceDetails.estimatedFXRateQuote.sourceCurrencyCode}} =
                        {{invoiceDetails.estimatedFXRateQuote.quotedCurrencyAmountMultiplier.toFixed(4)}}
                        {{invoiceDetails.estimatedFXRateQuote.quotedCurrencyCode}}</span>
                </div>
                <div>
                    <span class="k">JPM FX Rate Estimate: </span>
                    <span class="v">1 {{invoiceDetails.estimatedFXRateQuote.sourceCurrencyCode}} =
                        {{invoiceDetails.estimatedFXRateQuote.baseMultiplier.toFixed(4)}}
                        {{invoiceDetails.estimatedFXRateQuote.quotedCurrencyCode}}</span>
                </div>
                <div>
                    <span class="k">Quoted Payout Total Estimate: </span>
                    <span class="v">{{invoiceDetails.estimatedFXRateQuote.quotedCurrencyAmount.toFixed(2)}}
                        {{invoiceDetails.estimatedFXRateQuote.quotedCurrencyCode}}</span>
                </div>
                <div>
                    <span class="k">Liquid FX Fee: </span>
                    <span class="v">{{invoiceDetails.estimatedFXRateQuote.fxFeeAmount.toFixed(2)}} USD
                        ({{invoiceDetails.estimatedFXRateQuote.fxFeeRate * 100}}%)</span>
                </div>
            </div>
            <div *ngIf="cancellablePayoutE2EId">
                <a (click)="cancelPayout()">Cancel Payout {{cancellablePayoutE2EId}}</a>
            </div>
            <div *ngIf="!cancellablePayoutE2EId">
                <a (click)="resendPayout()">Resend Payout</a>
            </div>
        </div>
    </div>
</mat-card>

<lqd-client-invoice-associated-info
    *ngIf="!!invoiceDetails"
    [invoiceDetails]="invoiceDetails"
>
</lqd-client-invoice-associated-info>