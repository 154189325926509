import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'

import { PaymentAccountDto } from '../../core/models'
import { BusinessService } from '../../core/services'
import { DialogService } from '../../dialogs'
import { VerifyDialogComponent } from '../verify-dialog/verify-dialog.component'

@Injectable({
    providedIn: 'root',
})
export class VerifyAccountService {

    constructor(
        private businesses: BusinessService,
        private dialogs: DialogService,
    ) { }

    verifyAccount(paymentAccount: PaymentAccountDto): Observable<PaymentAccountDto> {

        this.dialogs.wait()
        return this.businesses.getBusiness(paymentAccount.organizationId)
            .pipe(
                map(business => ({
                    data: {
                        account: paymentAccount,
                        business: business,
                    },
                })),
                switchMap(config => this.dialogs.open(VerifyDialogComponent, config).afterClosed()),
            )
    }
}
