<h6 mat-dialog-title>Payment Account Details</h6>
<mat-dialog-content class="dialog-with-desc">
    <div
        *ngIf="isOtherBankAccount && !isUsaBankAccount && !account.bankId"
        class="inline-warning"
    >
        Note: this bank account has SWIFT code which is not in the database.
        Please be careful verifying it.
    </div>
    <div
        *ngIf="isOtherBankAccount && !isUsaBankAccount && account.hasBankInfoOverride"
        class="inline-warning"
    >
        Note: this bank account has SWIFT bank overrides.
        Please check 'Foreign Bank Review' section on Reef homepage.
    </div>
    <div class="row">
        <div class='column'>
            <h5>Created: </h5>
            <p>{{ created | date }}</p>
        </div>
        <div class='column'>
            <h5>Account Type: </h5>
            <p>{{ account?.paymentAccountType }}</p>
        </div>
        <div class='column'>
            <h5>Status: </h5>
            <p>{{ account?.status }} {{account?.deleted ? '(deleted)': ''}}</p>
        </div>
    </div>
    <div class="row">
        <div class='column'>
            <h5>Account Name: </h5>
            <p>{{ account?.name || 'NA'}}</p>
        </div>
        <div class='column'>
            <h5>Account Holder Name: </h5>
            <p>{{ account?.accountHolderName || 'NA' }}</p>
        </div>
        <div class='column'>
            <h5>Account Holder Type: </h5>
            <p>{{ account?.accountHolderType || 'NA' }}</p>
        </div>
    </div>
    <div class='row'>
        <div class='column'>
            <h5>Address: </h5>
            <p>{{ account?.fullAddress || 'NA' }}</p>
        </div>
        <div class='column'>
            <h5>Last 4 Digits: </h5>
            <p>{{ account?.last4 }}</p>
        </div>
        <div class='column'>
            <h5>Verification Method: </h5>
            <p>{{ verificationMethod }}</p>
        </div>
        <div
            *ngIf="isCC"
            class='column'
        >
            <h5>Exp. Month/Year: </h5>
            <p>{{account?.expMonth}}/{{account?.expYear}}</p>
        </div>
    </div>
    <lqd-other-bank-account-details
        *ngIf="!!details"
        [details]="details"
    ></lqd-other-bank-account-details>
    <mat-tab-group *ngIf="isPlaidAccount">
        <mat-tab label="Names">

            <div *ngIf="!!names?.length">
                <h5>Plaid Identity Names: </h5>
                <app-table
                    id="identityNames"
                    class="data-table"
                    [noStick]="true"
                    [columns]="identityNameColumns"
                    [length]="names.length"
                    [defaultSort]="{ active: 'created', direction: 'desc' }"
                    [data$]="names$"
                    readonlyModeContainerClasses="overflow-x-scroll"
                ></app-table>
            </div>
        </mat-tab>
        <mat-tab label="Phone Numbers">
            <div *ngIf="!!phones?.length">
                <h5>Plaid Identity Phone Numbers: </h5>
                <app-table
                    id="identityPhones"
                    class="data-table"
                    [noStick]="true"
                    [columns]="identityPhoneColumns"
                    [length]="phones.length"
                    [defaultSort]="{ active: 'primary', direction: 'desc' }"
                    [data$]="phones$"
                    readonlyModeContainerClasses="overflow-x-scroll"
                ></app-table>
            </div>
        </mat-tab>
        <mat-tab label="Emails">
            <div *ngIf="!!emails?.length">
                <h5>Plaid Identity Emails: </h5>
                <app-table
                    id="identityEmails"
                    class="data-table"
                    [noStick]="true"
                    [columns]="identityEmailColumns"
                    [length]="emails.length"
                    [defaultSort]="{ active: 'primary', direction: 'desc' }"
                    [data$]="emails$"
                    readonlyModeContainerClasses="overflow-x-scroll"
                ></app-table>
            </div>
        </mat-tab>

        <mat-tab label="Addresses">
            <div *ngIf="!!addresses?.length">
                <h5>Plaid Identity Addresses: </h5>
                <app-table
                    id="identityAddresses"
                    class="data-table"
                    [noStick]="true"
                    [columns]="identityAddressColumns"
                    [length]="addresses.length"
                    [defaultSort]="{ active: 'primary', direction: 'desc' }"
                    [data$]="addresses$"
                    readonlyModeContainerClasses="overflow-x-scroll"
                ></app-table>
            </div>
        </mat-tab>
    </mat-tab-group>

    <mat-tab-group *ngIf="isLiquidBankAccount && hasMicrodeposits">
        <mat-tab label="ACH Microdeposits Status & History">
            <div class='row'>
                <div class='column'>
                    <div>
                        <h5>Verifications: </h5>
                        <app-table
                            id="microdepositVerifications"
                            class="data-table"
                            [noStick]="true"
                            [columns]="microdepositVerificationColumns"
                            [length]="microdepositVerifications.length"
                            [defaultSort]="{ active: 'created', direction: 'desc' }"
                            [data$]="microdepositVerifications$"
                            readonlyModeContainerClasses="overflow-x-scroll"
                        ></app-table>
                    </div>
                </div>
            </div>
            <div class='row'>
                <div class='column'>
                    <div>
                        <h5>Verification Attempts: </h5>
                        <app-table
                            id="microdepositVerificationAttempts"
                            class="data-table"
                            [noStick]="true"
                            [columns]="microdepositVerificationAttemptsColumns"
                            [length]="microdepositVerificationAttempts.length"
                            [defaultSort]="{ active: 'created', direction: 'desc' }"
                            [data$]="microdepositVerificationAttempts$"
                            readonlyModeContainerClasses="overflow-x-scroll"
                        ></app-table>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>

</mat-dialog-content>