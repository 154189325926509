import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { AdminBusiness, PaymentStatus } from '../models'

import { UrlService } from './url.service'

@Injectable({
    providedIn: 'root',
})
export class PaymentStatusStore {

    constructor(
        private http: HttpClient,
        private urls: UrlService,
    ) { }

    get(): Observable<Array<PaymentStatus>> {
        return this.http.get<Array<PaymentStatus>>(this.urls.api.paymentStatus())
    }

    preview(businessId: string, statusId: string, message: string): Observable<AdminBusiness> {
        const request: any = {
            organizationId: businessId,
            paymentStatusId: statusId,
            isPreview: true,
            message,
        }
        return this.http.patch<AdminBusiness>(this.urls.api.paymentStatus(), request)
    }

    set(businessId: string, statusId: string, message: string): Observable<AdminBusiness> {
        const request: any = {
            organizationId: businessId,
            paymentStatusId: statusId,
            isPreview: false,
            message,
        }
        return this.http.patch<AdminBusiness>(this.urls.api.paymentStatus(), request)
    }
}
