import { Component, Input } from '@angular/core'

import { Address } from '../models/address.interface'

@Component({
    selector: 'app-address-display',
    templateUrl: './address-display.component.html',
    styleUrls: ['./address-display.component.scss'],
})
export class AddressDisplayComponent {

    @Input() address: Address

}
