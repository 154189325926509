import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

import { AdminKycDocument } from '../../core/models'

@Component({
    selector: 'kyc-document-details-dialog',
    templateUrl: './kyc-document-details-dialog.component.html',
    styleUrls: ['./kyc-document-details-dialog.component.scss'],
})
export class  KycDocumentDetailsDialogComponent implements OnInit {

    kycDoc: AdminKycDocument
    
    constructor(
        private dialogRef: MatDialogRef<KycDocumentDetailsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: AdminKycDocument,
    ) {
        this.kycDoc = data
    }

    ngOnInit(): void {
    }

}
