<h3>Microdeposit Release</h3>
<app-table
    class="data-table"
    [columns]="columns"
    [customPaging]="true"
    [defaultSort]="{ active: 'requested', direction: 'desc' }"
    [data$]="rows$"
    [id]="tableId"
    [length]="length"
    [noStick]="true"
    [persistPage]="true"
    (checkButtonClick)="onCheckButtonClick($event)"
    (pageChange)="onPageChange($event)"
    (rowClick)="onRowClick($event)"
    readonlyModeContainerClasses="overflow-x-scroll"
></app-table>