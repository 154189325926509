import { SelectionModel } from '@angular/cdk/collections'
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, PipeTransform, ViewChild } from '@angular/core'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { MatSort, MatSortHeader, Sort, SortDirection } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs'
import { map, takeUntil, tap } from 'rxjs/operators'

import { SelectOption } from '../core/models'

import { ListField, ListFieldAction, ListFieldType, TablePreferenceStorageKey } from './models'
import { PagingSettings } from './models/paging-settings'
import { TableViewPreferencesService } from './services/table-view-preferences.service'

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
})
export class TableComponent<T extends { id: string }> implements OnInit, OnDestroy, AfterViewInit {

    private currentPage: { page: PageEvent, sort: Sort }
    private ngUnsubscribe: Subject<void> = new Subject()

    private pageIndexSubject: BehaviorSubject<number> = new BehaviorSubject(undefined)
    private pageSizeSubject: BehaviorSubject<number> = new BehaviorSubject(undefined)
    private sortActiveSubject: BehaviorSubject<string> = new BehaviorSubject(undefined)
    private sortDirectionSubject: BehaviorSubject<SortDirection> = new BehaviorSubject(undefined)

    private readonly numberTypes: Array<ListFieldType> = ['money', 'number']
    private readonly buttonTypes: Array<ListFieldType> = ['actions', 'check-button', 'download', 'remove']

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
    @ViewChild(MatSort, { static: true }) sort: MatSort

    @Output() download: EventEmitter<T> = new EventEmitter()
    @Output() callbackLinkClick: EventEmitter<{ row: T, field: ListField }> = new EventEmitter<{ row: T, field: ListField }>()
    @Output() checked: EventEmitter<any[]> = new EventEmitter<any[]>()
    @Output() pageChange: EventEmitter<{ page: PageEvent, sort: Sort }> = new EventEmitter()
    @Output() remove: EventEmitter<string> = new EventEmitter()
    @Output() rowClick: EventEmitter<T> = new EventEmitter()
    @Output() actionClick?: EventEmitter<{ option: ListFieldAction, row: T }> = new EventEmitter()
    @Output() chipClick?: EventEmitter<{ option: SelectOption<T>, row: T, field: ListField }> = new EventEmitter()
    @Output() checkButtonClick?: EventEmitter<{ row: T, field: ListField }> = new EventEmitter()
    @Output() stringOrButtonClick?: EventEmitter<{ row: T, field: ListField }> = new EventEmitter()

    @Input() disableSort: boolean = false
    @Input() customPaging: boolean
    @Input() data$: Observable<Array<T>>
    @Input() defaultSort?: Sort
    @Input() defaultPageSize: number = 20
    @Input() id?: string
    @Input() length: number
    @Input() noStick: boolean = false
    @Input() notClickable: boolean = false
    @Input() persistPage: boolean = false
    @Input() readonlyModeContainerClasses: string | string[]
    @Input() selected: any[] = []
    @Input() showPaging: boolean = true
    @Input() statusPipe: PipeTransform
    @Input() stickyColumns: number
    @Input() getCellClass?: (listField: ListField, row: T) => string

    @Input() set columns(cols: ListField[]) {
        if (cols.length !== this._columns.length) {
            this.selection.clear()
        }

        this._columns = cols
    }

    @Input() set pageIndex(pageIndex: number) {
        const currentPageIndex: number = this.pageIndexSubject.value
        if (currentPageIndex && currentPageIndex !== pageIndex) {
            this.pageIndexSubject.next(pageIndex)
        }
    }

    _columns: Array<ListField> = []
    topVisible: boolean = true
    bottomVisible: boolean = false
    columnMap: { [field: string]: ListField } = {}
    dataTable: MatTableDataSource<T> = new MatTableDataSource([])

    readonly pageIndex$: Observable<number> = this.pageIndexSubject.asObservable()
    readonly pageSize$: Observable<number> = this.pageSizeSubject.asObservable()
    readonly sortStartField$: Observable<string> = this.sortActiveSubject.asObservable()
    readonly sortDirection$: Observable<SortDirection> = this.sortDirectionSubject.asObservable()

    selection: SelectionModel<any> = new SelectionModel(true, this.selected)

    get allSelected(): boolean {
        return this.selection.selected.length === this.dataTable.filteredData.length
    }
    get columnIds(): Array<string> { return this._columns?.map(column => column.id) || [] }
    get sort$(): Observable<Sort> { return this.getSortObs() }
    get sortId$(): Observable<string> { return this.getSortId() }

    static defaultStoredSort(defaultSortId: string, tableId: string): string {
        const currentSavedSortKeys: { [key: string]: string } = JSON.parse(localStorage.getItem(TablePreferenceStorageKey.tableSortActive) || '{}')
        const currentSavedSortDirectionKeys: { [key: string]: string } = JSON.parse(localStorage.getItem(TablePreferenceStorageKey.tableSortDirection) || '{}')
        const currentSavedSort: string = currentSavedSortKeys[tableId]
        const currentSavedSortDirection: string = currentSavedSortDirectionKeys[tableId]
        if (!currentSavedSort || !currentSavedSortDirection) {
            return defaultSortId
        }
        return `${currentSavedSort}-${currentSavedSortDirection}`
    }

    constructor(
        private readonly cd: ChangeDetectorRef,
        private readonly tableService: TableViewPreferencesService,
    ) { }

    ngOnInit(): void {
        const { pageSize, pageIndex, sort: savedSort }: { pageSize?: number, pageIndex?: number, sort?: Sort } = this.getPersistedOpts(this.defaultSort?.direction) || {}

        let active: string = savedSort?.active || this.defaultSort?.active
        if (!active) {
            active = this._columns.find(c => !c?.notSortable)?.id || this.columnIds[0]
        }
        let direction: SortDirection = savedSort?.direction || this.defaultSort?.direction
        if (!direction) {
            direction = 'desc'
        }

        this.sortActiveSubject.next(active)
        this.sortDirectionSubject.next(direction)
        this.pageSizeSubject.next(pageSize)
        this.pageIndexSubject.next(pageIndex)

        if (this.persistPage) {
            this.paginator.pageIndex = pageIndex
            this.paginator.pageSize = pageSize
        }

        this.data$
            .pipe(
                takeUntil(this.ngUnsubscribe),
                tap(data => this.dataTable.data = data),
            )
            .subscribe()

        // create a map of the colums so we know how to sort
        this._columns?.forEach(column => this.columnMap[column.id] = column)

        this.sort.sortChange
            .pipe(
                takeUntil(this.ngUnsubscribe),
                tap(sort => {
                    this.sortActiveSubject.next(sort.active)
                    this.sortDirectionSubject.next(sort.direction)
                    this.paginator.pageIndex = 0
                    this.emitPageChange(sort)
                    this.scrollToTop()
                }),
            )
            .subscribe()

        this.paginator.page
            .pipe(
                takeUntil(this.ngUnsubscribe),
                tap(page => {
                    this.pageSizeSubject.next(page.pageSize)
                    this.pageIndexSubject.next(page.pageIndex)
                    if (this.currentPage?.page?.pageSize !== page.pageSize && !this.persistPage) {
                        this.paginator.pageIndex = 0
                        page.pageIndex = 0
                    }
                    this.emitPageChange(this.sort)
                    this.scrollToTop()
                }),
            )
            .subscribe()

        // don't set up sorting if we have custom paging
        if (this.customPaging) {
            return
        }

        this.dataTable.paginator = this.paginator
        this.dataTable.sort = this.sort

        this.selection.clear()
        this.selection.select(...this.selected)

        this.dataTable.sortData = (data: Array<T>, sort: MatSort): Array<T> => {

            // if the field is missing, it's prob b/c there was a sort saved locally for a field that's no longer in the table,
            // so just use the first column
            const field: ListField = this.columnMap[sort.active] || this._columns[0]

            switch (field?.type) {
                case 'chips':
                    return sort.direction === 'asc' ? data.sort((a, b) => {
                        return a[field.id][0]?.label?.localeCompare(b[field.id][0]?.label)
                    })
                        : data.sort((a, b) => {
                            return b[field.id][0]?.label?.localeCompare(a[field.id][0]?.label)
                        })

                case 'date':
                    return data.sort((a, b) => {
                        const aTimestamp: number = !!a[field.id] ? new Date(a[field.id]).getTime() : 0
                        const bTimestamp: number = !!b[field.id] ? new Date(b[field.id]).getTime() : 0
                        return sort.direction === 'asc' ? aTimestamp - bTimestamp : bTimestamp - aTimestamp
                    })

                case 'timestamp':
                case 'timestamp-long':
                case 'money':
                case 'number':
                    return sort.direction === 'asc' ? data.sort((a, b) => a[field.id] - b[field.id]) : data.sort((a, b) => b[field.id] - a[field.id])

                case 'chips':
                    return sort.direction === 'asc'
                        ? data.sort((a, b) => (<string>a[field.id].value)?.localeCompare(b[field.id].value))
                        : data.sort((a, b) => (<string>b[field.id].value)?.localeCompare(a[field.id].value))

                default:
                    return sort.direction === 'asc'
                        ? data.sort((a, b) => {
                            if (typeof (a[field.id]) === 'boolean') {
                                return a[field.id] === b[field.id] ? 0 : a[field.id] ? 1 : -1
                            }
                            return (<string>a[field.id])?.localeCompare(b[field.id])
                        })
                        : data.sort((a, b) => {
                            if (typeof (a[field.id]) === 'boolean') {
                                return a[field.id] === b[field.id] ? 0 : a[field.id] ? -1 : 1
                            }
                            return (<string>b[field.id])?.localeCompare(a[field.id])
                        })
            }
        }
    }

    ngAfterViewInit(): void {
        this.emitPageChange(this.sort)
        this.cd.detectChanges()
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next()
        this.ngUnsubscribe.unsubscribe()
    }

    addTimezone(date: string): string {
        return date.endsWith('Z') ? date : date.concat('Z')
    }

    buttonIndexClass(field: ListField): string {
        let index: number
        if (field.type === 'actions') {
            // TODO: support multiple actions _columns
            return `${this.cellClass(field)} button-column-0`
        }
        const buttonColumns: Array<ListField> = this._columns.filter(i => this.buttonTypes.includes(i.type) && i.type !== 'actions')
        const hasActions: boolean = !!this._columns.filter(i => i.type === 'actions').length
        index = buttonColumns.findIndex(i => i.id === field.id)
        return `${this.cellClass(field)} button-column-${hasActions ? index + 1 : index}`
    }

    cellClass(field: ListField, row?: T): string {
        let output: string
        if (!this.notClickable && field.clickable) {
            output = 'clickable'
        }
        if (!!this.getCellClass && !!this.getCellClass(field, row)) {
            output = !!output ? output + ' ' + this.getCellClass(field, row) : this.getCellClass(field, row)
        }
        return output
    }

    executeAction(option: ListFieldAction, row: T): void {
        this.actionClick.emit({ option, row })
    }

    executeChipClick(option: SelectOption<T>, row: T, field: ListField): void {
        this.chipClick.emit({ option, row, field })
    }

    executeStringOrButtonClick(row: T, field: ListField): void {
        this.stringOrButtonClick.emit({ row, field })
    }

    executeCheckToggle(evt: any, row: T, field: ListField): void {
        if (evt) {
            evt?.stopPropagation()
        }
        this.checkButtonClick.emit({ row, field })
    }

    hideAction(row: T, column: ListField): boolean {
        return !!column?.hideAction ? column?.hideAction(row, column) : false
    }

    sortStart(field: ListField): SortDirection {
        return this.numberTypes.includes(field?.type) ? 'desc' : 'asc'
    }

    formatDate(timestamp: number): Date {
        return !!timestamp ? new Date(new Intl.DateTimeFormat('en-US').format(new Date(timestamp * 1000))) : undefined
    }

    onCallbackLinkClick(event: MouseEvent, row: T, field: ListField): void {
        this.callbackLinkClick.emit({ row, field })
    }

    onDownload(row: T): void {
        this.download.emit(row)
    }

    onRemove(row: T): void {
        if (!!row?.id) {
            this.remove.emit(row.id)
        }
    }

    onRowClick(row: T): void {
        this.rowClick.emit(row)
    }

    onVisibilityChange(type: 'header' | 'paginator', status: VisibilityState): void {
        switch (type) {
            case 'paginator':
                this.bottomVisible = status === 'visible'
                break
            case 'header':
                this.topVisible = status === 'visible'
                break
            default: break
        }
    }

    setSort(sort: Sort): void {
        // TODO: remove this hack -- the reason for it at the time was to set the correct direction on mat-header-column arrows (UI)
        // TODO: possible solution: https://github.com/angular/components/issues/10242#issuecomment-655340461
        if (sort.active !== this.sortActiveSubject.getValue() || sort.direction !== this.sortDirectionSubject.getValue()) {
            // CREDIT: https://github.com/angular/components/issues/10242#issuecomment-535457992
            const start: 'asc' | 'desc' = sort.direction as any
            // reset state so that start is the first sort direction that you will see
            this.sort.sort({ id: null, start, disableClear: false })
            this.sort.sort({ id: sort.active, start, disableClear: false });

            // ugly hack
            (this.sort?.sortables?.get(sort.active) as MatSortHeader)?._setAnimationTransitionState({ toState: 'active' })
        }
    }

    statusClass(status: string): string {
        return this.statusPipe?.transform(status) || status
    }

    supressEvent(evt?: Event): void {
        evt?.preventDefault()
        evt?.stopPropagation()
    }

    sortDisabled(field: ListField): boolean {
        return field.type === 'remove' || field.notSortable
    }

    getCheckButtonClass(row: T, column: ListField): string {
        return !!column?.checkButtonClass ? column?.checkButtonClass(row, column) : !!row[column.id] ? 'selected' : ''
    }

    getHeaderClass(field: ListField): string {
        if (['actions', 'check-button'].includes(field.type)) {
            return 'center-aligned button-column'
        }
        const alignment: string = this.numberTypes.includes(field?.type) ? 'right-aligned' : ''
        return !!field.notSortable ? `${alignment} not-sortable` : alignment
    }

    isCheckButtonDisabled(row: T, column: ListField): boolean {
        return column.checkButtonDisabled ? column.checkButtonDisabled(row, column) : !!column?.disabled
    }

    isChipListClickable(row: T, column: ListField): boolean {
        return !!column?.chipListClickable ? column.chipListClickable(row, column) : !!column?.clickable
    }

    clearAll(): void {
        this.selection.clear()
        this.checked.emit([])
    }

    toggleAll(): void {
        this.selection.select(...this.dataTable.filteredData)
        this.checked.emit(this.selection.selected)
    }

    toggle(item: any): void {
        this.selection.toggle(item)
        this.checked.emit(this.selection.selected)
    }

    private emitPageChange(sort: Sort): void {
        this.currentPage = {
            page: {
                pageIndex: this.paginator.pageIndex,
                pageSize: this.paginator.pageSize,
                length: this.paginator.length,
            },
            sort: sort,
        }
        this.pageChange.emit(this.currentPage)
        this.saveTablePreferences(sort, this.currentPage.page)
    }

    // TODO: better way to handle this generically... for now a "sort id" is `${sort.active}-${sort.direction}` and its used for SortSelectComponent
    private getSortId(): Observable<string> {
        return this.sort$.pipe(
            map(sort => `${sort.active}-${sort.direction}`),
        )
    }

    private getSortObs(): Observable<Sort> {
        return combineLatest([
            this.sortActiveSubject.asObservable(),
            this.sortDirectionSubject.asObservable(),
        ]).pipe(
            map(([active, direction]) => {
                return { active, direction }
            }),
        )
    }

    private getPersistedOpts(direction: SortDirection): { pageSize: number, pageIndex: number, sort: Sort } {
        if (!this.id || !this.tableService) {
            return
        }

        const pagingSettings: PagingSettings = this.tableService.getPagingSettings(this.id)
        const sort: Sort = this.tableService.getSort(this.id, direction)

        return { sort, ...pagingSettings }
    }

    private saveTablePreferences(sort: Sort, page: PageEvent): void {
        if (!this.id || !this.tableService) {
            return
        }

        this.tableService.saveSort(sort, this.id)
        this.tableService.savePagingSettings(page, this.id)
    }

    private scrollToTop(): void {
        // FF & Chrome scroll the app-table element while Safari scrolls the subnav-content-container
        window.document.querySelectorAll('app-table, .subnav-content-container')
            .forEach(node => {
                node.scrollTop = 0
                node.scrollLeft = 0
            })
    }
}
