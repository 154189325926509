import { AuthConfig } from '@auth0/auth0-angular'

export const Auth0Environment: AuthConfig = {
    clientId: 'vbyNPgI9qbyeF9wJocaq25fiGbIIjF24',
    domain: 'login-dev.poweredbyliquid.com',
    redirectUri: 'https://reef-dev.poweredbyliquid.com/auth-callback',
    audience: 'https://liquid-api-dv.azurewebsites.net',
    scope: 'read:current_user',
    httpInterceptor: {
        allowedList: [
            {
                uri: 'https://liquid-api-dv.azurewebsites.net/*',
                tokenOptions: {
                    audience: 'https://liquid-api-dv.azurewebsites.net',
                    scope: 'read:current_user',
                },
            },
        ],
    },
}

export const environment: any = {
    production: false,
    host: 'https://reef-dev.poweredbyliquid.com',
    liquidApiSettings: {
        apiServicePrefix: 'https://liquid-api-dv.azurewebsites.net',
        orgAvatarPrefix: 'https://liquid-avatars-dv.azureedge.net',
        userAvatarPrefix: 'https://liquid-profiles-dv.azureedge.net',
    },
    liquidAppHost: 'https://web-dev.poweredbyliquid.com',
    logging: {
        apiKey: 'f19bc42dcfd0fe715f9f7af24c28acbf',
        consoleBreadcrumbsEnabled: true,
        releaseStage: 'dv',
    },
    internalEmailAdress: {
        support: 'support@poweredbyliquid.com',
    },
}
