import { Component, OnInit } from '@angular/core'
import { PageEvent } from '@angular/material/paginator'
import { Sort } from '@angular/material/sort'
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs'
import { catchError, map, switchMap, take, tap } from 'rxjs/operators'

import { AddressService } from '../../address'
import { PaymentAccount, PaymentAccountDto, TableId } from '../../core/models'
import { BusinessService, PaymentAccountService } from '../../core/services'
import { CurrencyService } from '../../currencies/services'
import { DialogService } from '../../dialogs'
import { ListField, PageRequest } from '../../table/models'
import { ReviewBankDialogComponent } from '../review-bank-dialog/review-bank-dialog.component'

@Component({
    selector: 'lqd-foreign-banks-review',
    templateUrl: './banks-review.component.html',
    styleUrls: ['./banks-review.component.scss'],
})
export class BanksReviewComponent implements OnInit {

    private results: BehaviorSubject<Array<PaymentAccountDto>> = new BehaviorSubject([])

    readonly columns: Array<ListField> = [
        {
            id: 'createdTimestamp',
            label: 'Created',
            type: 'timestamp-long',
        },
        {
            id: 'accountHolderName',
            label: 'Account Holder Name',
        },
        {
            id: 'country',
            label: 'Country',
        },
        {
            id: 'status',
            label: 'Review Bank',
            checkButtonIcon: 'edit',
            type: 'check-button',
            notSortable: true,
        },
    ]
    length: number
    get rows$(): Observable<Array<PaymentAccountDto>> { return this.results.asObservable() }
    readonly tableId: string = TableId.foreignBankReview

    constructor(
        private addresses: AddressService,
        private businesses: BusinessService,
        private currencies: CurrencyService,
        private dialogs: DialogService,
        private paymentAccounts: PaymentAccountService,
    ) {

    }

    ngOnInit(): void {
        this.addresses.initialize().subscribe()
    }

    onPageChange(event: { page: PageEvent, sort: Sort }): void {
        if (!event?.page?.pageSize || event?.page?.pageIndex < 0 || !event?.sort?.active || !event?.sort?.direction) {
            return
        }

        const page: PageEvent = event.page
        const sort: Sort = event.sort
        const pageRequest: PageRequest = {
            page: page.pageIndex + 1,
            pageSize: page.pageSize,
            sortBy: `${sort.active} ${sort.direction}`,
        }

        this.businesses.getPaymentAccountsForReview(pageRequest)
            .pipe(
                take(1),
                tap(data => {
                    this.length = data.rowCount
                    this.results.next(data.results)
                }),
            )
            .subscribe()
    }

    onRowClick(account: PaymentAccount): void {
        this.onCheckButtonClick({ row: account })
    }

    onCheckButtonClick(event: { row: PaymentAccount }): void {
        const paymentAccount: PaymentAccount = event.row
        const countryId: string = this.addresses.countries.find(option => option.label === paymentAccount.country)?.value

        this.dialogs.wait()
        combineLatest([
            this.paymentAccounts.getOtherBankAccountProps(paymentAccount.id),
            this.currencies.get(countryId),
        ])
            .pipe(
                map(([bankAccountProperties, countryInfo]) => ({
                    data: {
                        account: paymentAccount,
                        details: bankAccountProperties,
                        properties: countryInfo.properties,
                    },
                })),
                switchMap(config => this.dialogs.open(ReviewBankDialogComponent, config).afterClosed()),
                tap(reviewedAccount => {
                    if (reviewedAccount?.missing) {
                        this.dialogs.inform('Selected action was not performed as bank details in bank account properties were incomplete.', 'Warning')
                        return
                    } else if (reviewedAccount?.override) {
                        this.dialogs.inform('Selected action was not performed as we cannot create new bank with a SWIFT Code that exist on other bank record.', 'Warning')
                        return
                    } else if (!reviewedAccount) {
                        // dialog closed
                        return
                    }

                    const reviewedAccountIndex: number = this.results.value.findIndex(account => account.id === reviewedAccount.id)
                    const accounts: Array<PaymentAccountDto> = this.results.value

                    accounts.splice(reviewedAccountIndex, 1)

                    this.results.next(accounts)
                }),
                catchError(error => this.dialogs.error(error)),
            )
            .subscribe()

    }
}
