import { Injectable } from '@angular/core'

import { AdminLog, AdminRequestType, LogEvent } from '../models'

@Injectable({
    providedIn: 'root',
})
export class LogFactory {

    private readonly DELETE: string = 'DELETE'
    private readonly POST: string = 'POST'
    private readonly PUT: string = 'PUT'
    private readonly PATCH: string = 'PATCH'

    constructor() { }

    create(log: AdminLog): AdminLog {
        return {
            ...log,
            logEvent: this.createEvent(log),
        }
    }

    private createEvent(log: AdminLog): LogEvent {

        // RequestType comes in form 'LiquidApi.Model.Controller.Admin.BeneficialOwnerRequest', just need last part
        const requestType: string = log.requestType?.split('.').slice(-1)[0] || ''
        const reqBody: any = JSON.parse(log.requestBody)
        const isPreview: boolean = reqBody?.IsPreview

        switch (log.httpMethod) {
            case this.POST: {
                switch (requestType) {
                    case AdminRequestType.BeneficialOwner:
                        return LogEvent.BeneficialOwnerAdded
                    case AdminRequestType.BusinessFlag:
                        return LogEvent.BusinessFlagUpdate
                    case AdminRequestType.BusinessUnmask:
                        return LogEvent.BusinessFieldUnmask
                    case AdminRequestType.PaymentAccountUnmask:
                        return LogEvent.PaymentAccountFieldUnmask
                    case AdminRequestType.DummyAccount:
                        return LogEvent.DummyAccountAdded
                    case AdminRequestType.KycDocument:
                        return LogEvent.KycDocumentAdded
                }
            }
                break
            case this.PUT: {
                switch (requestType) {
                    case AdminRequestType.BeneficialOwner:
                        return LogEvent.BeneficialOwnerUpdate
                    case AdminRequestType.TransactionStatus:
                        return LogEvent.TransactionStatusUpdate
                }
            }
                break
            case this.PATCH: {
                switch (requestType) {
                    case AdminRequestType.KycStatus:
                        return isPreview ? LogEvent.KycStatusUpdatePreview : LogEvent.KycStatusUpdate
                    case AdminRequestType.PaymentStatus:
                        return isPreview ? LogEvent.PaymentStatusUpdatePreview : LogEvent.PaymentStatusUpdate
                }
            }
                break
            case this.DELETE:
                return LogEvent.BeneficialOwnerDeleted
            default:
                return LogEvent.NotFound

        }
    }

}
