import { Component, Inject } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { of } from 'rxjs'
import { filter, switchMap, take, tap } from 'rxjs/operators'

import { Address, AddressService, AddressStore, Country } from '../../address'
import { AddIntermediaryDialogComponent } from '../../banks/add-intermediary-dialog/add-intermediary-dialog.component'
import { BankDetails } from '../../banks/models/bank-details.interface'
import { BankService } from '../../banks/services'
import { BankInfoReviewDecision, DefaultLabel, PaymentAccountDto, SelectOption } from '../../core/models'
import { PaymentAccountService } from '../../core/services'
import { Bank, OtherBankAccountProperty } from '../../country-bank-account-config'
import { CurrencyStore } from '../../currencies/services'
import { DialogService } from '../../dialogs'

@Component({
    selector: 'lqd-review-bank-dialog',
    templateUrl: './review-bank-dialog.component.html',
    styleUrls: ['./review-bank-dialog.component.scss'],
})
export class ReviewBankDialogComponent {

    readonly actionOptions: Array<SelectOption<string>> = [
        {
            label: 'No Change',
            value: BankInfoReviewDecision.NoChange,
        },
        {
            label: 'Use default bank',
            value: BankInfoReviewDecision.SetToBank,
        },
        {
            label: 'Update default',
            value: BankInfoReviewDecision.UpsertBank,
        },
    ]

    originalData: any
    bankDetailsFromDb: BankDetails
    bankFullAddress: string
    details: Array<OtherBankAccountProperty>
    displayAddress: string
    form: FormGroup
    paymentAccount: PaymentAccountDto
    action: FormControl
    bankExistsInDb?: boolean = undefined
    bankHasUSIntermediary?: boolean = undefined

    constructor(
        private addressService: AddressService,
        private addressStore: AddressStore,
        private bankService: BankService,
        private currencyStore: CurrencyStore,
        private dialogService: DialogService,
        private paymentAccountService: PaymentAccountService,
        @Inject(MAT_DIALOG_DATA) data: {
            account: PaymentAccountDto,
            bank: Bank,
            details: Array<OtherBankAccountProperty>,
        },
        private dialogRef: MatDialogRef<ReviewBankDialogComponent>,
    ) {
        this.originalData = data
        this.action = new FormControl(data.account.bankInformationReviewDecision, Validators.required)
        this.form = new FormGroup({
            'action': this.action,
        })
        this.details = data.details
        this.paymentAccount = data.account
        this.loadBank()
    }

    loadBank(): void {
        const swiftFromAccountDetails: string = this.details?.find(d => d?.countryBankAccountProperty?.providerFieldName.toLowerCase() === 'creditoragentfininstbic')?.value
        if (!swiftFromAccountDetails) {
            return
        }

        this.bankService.getBankDetailBySwift(swiftFromAccountDetails)
            .pipe(
                tap(bank => {
                    this.bankDetailsFromDb = bank
                    this.setBankDetailsFromDbParams()
                }, error => this.dialogService.error(error)),
            ).subscribe()

    }

    private setBankDetailsFromDbParams(): void {
        if (!this.bankDetailsFromDb) {
            this.bankExistsInDb = false
            this.bankHasUSIntermediary = false
        } else {
            this.bankExistsInDb = true
            this.bankHasUSIntermediary = !!this.bankDetailsFromDb?.intermediaries?.length
            this.bankFullAddress = this.addressService.getAddressString(this.getBankAddress(this.bankDetailsFromDb))
        }
    }

    submitAcceptance(): void {
        this.dialogService.wait()
        let upsertOrSetBank: Bank
        const decision: BankInfoReviewDecision = <BankInfoReviewDecision>this.action.value
        switch (decision) {
            case BankInfoReviewDecision.UpsertBank:
                upsertOrSetBank = this.extractBankData(decision)
                break
            case BankInfoReviewDecision.SetToBank:
                upsertOrSetBank = this.bankDetailsFromDb ? undefined : this.extractBankData(decision)
                break
            default:
                break
        }

        if (upsertOrSetBank && !(upsertOrSetBank.swiftCode && upsertOrSetBank.countryId)) {
            this.dialogRef.close({ missing: true })
            this.dialogService.close()
            return
        }
        this.paymentAccountService.getBank(upsertOrSetBank?.swiftCode)
            .pipe(
                switchMap(bank => !bank || decision === BankInfoReviewDecision.SetToBank ? this.paymentAccountService.acceptBank(upsertOrSetBank, this.paymentAccount.id, decision) : of(undefined)),
                tap(account => {
                    this.dialogService.close()
                    if (account) {
                        this.dialogRef.close(account)
                    } else {
                        this.dialogRef.close({ override: true })
                    }
                }),
            )
            .subscribe()
    }

    private extractBankData(decision: BankInfoReviewDecision): Bank {
        const name: string = this.details.find(x => x.countryBankAccountProperty.bankAccountPropertyDef?.defaultLabel === DefaultLabel.BankName)?.value
        const swiftCode: string = this.details.find(x => x.countryBankAccountProperty.bankAccountPropertyDef?.defaultLabel === DefaultLabel.SwiftCode)?.value
        const branchAddressProperty: OtherBankAccountProperty = this.details.find(x => x.countryBankAccountProperty.bankAccountPropertyDef?.defaultLabel === DefaultLabel.BankBranchAddress)
        const branchAddress: Address = this.getAddress(branchAddressProperty?.countryBankAccountProperty?.id, this.details)

        return {
            address1: branchAddress?.street1,
            address2: branchAddress?.street2,
            branch: '',
            city: branchAddress?.city,
            countryId: this.details[0].countryBankAccountProperty?.countryId,
            countryName: branchAddress?.country,
            postalCode: branchAddress?.postalCode,
            id: decision === BankInfoReviewDecision.UpsertBank && !!this.bankDetailsFromDb ? this.bankDetailsFromDb?.id ?? '00000000-0000-0000-0000-000000000000' : '00000000-0000-0000-0000-000000000000',
            name,
            swiftCode,
        }
    }

    private getAddress(parentId: string, properties: Array<OtherBankAccountProperty>): Address {
        if (!parentId) {
            return undefined
        }

        const addressChildProps: Array<OtherBankAccountProperty> = properties.filter(prop => !!prop.countryBankAccountProperty.parentId && prop.countryBankAccountProperty.parentId === parentId)
        const address: Address = {
            street1: addressChildProps.find(p => p.countryBankAccountProperty.bankAccountPropertyDef?.defaultLabel.toLowerCase() === 'street1')?.value,
            street2: addressChildProps.find(p => p.countryBankAccountProperty.bankAccountPropertyDef?.defaultLabel.toLowerCase() === 'street2')?.value,
            city: addressChildProps.find(p => p.countryBankAccountProperty.bankAccountPropertyDef?.defaultLabel.toLowerCase() === 'city')?.value,
            state: addressChildProps.find(p => p.countryBankAccountProperty.bankAccountPropertyDef?.defaultLabel.toLowerCase() === 'state')?.value,
            country: addressChildProps.find(p => p.countryBankAccountProperty.bankAccountPropertyDef?.defaultLabel.toLowerCase() === 'country')?.value,
            postalCode: addressChildProps.find(p => p.countryBankAccountProperty.bankAccountPropertyDef?.defaultLabel.toLowerCase() === 'postalcode')?.value,
        }
        return address
    }

    private getBankAddress(bank: BankDetails): Address {
        return {
            street1: bank.address1,
            street2: bank.address2,
            city: bank.city,
            state: undefined,
            countryId: bank.countryId,
            country: bank.countryName,
            postalCode: bank.postalCode,
        }
    }

    popAddIntermediary(): void {
        this.addressStore.getCountries()
            .pipe(
                take(1),
                switchMap(countryList => {
                    return this.currencyStore.getAllCurrencyCodes()
                        .pipe(
                            take(1),
                            tap(currencyCodes => {
                                this.showIntermediaryAddDialog(countryList, currencyCodes)
                            }),
                        )
                }),
            ).subscribe()
    }

    showIntermediaryAddDialog(countries: Country[], currencyCodes: string[]): void {
        const dialogConfig: MatDialogConfig = {
            data: {
                bank: this.bankDetailsFromDb,
                countries,
                currencyCodes,
            },
        }
        this.dialogService.open(AddIntermediaryDialogComponent, dialogConfig, undefined, false)
            .afterClosed()
            .pipe(
                filter(createRequest => !!createRequest),
                switchMap((results: any) => {
                    const createRequest: any = {
                        bankId: results.bankId,
                        countryId: results.selectedCountry,
                        currencyCode: results.selectedCurrencyCode,
                        intermediaryBankId: results.intermediaryBankId,
                    }
                    return this.bankService.createIntermediaryBankSetup(createRequest)
                        .pipe(
                            tap((bank: BankDetails) => {
                                this.bankDetailsFromDb = bank
                                this.setBankDetailsFromDbParams()
                            }, error => {
                                this.dialogService.error(error)
                            }),
                        )
                }),
            )
            .subscribe()
    }

}
