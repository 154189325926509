import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { catchError, take } from 'rxjs/operators'

import { DialogService } from '../../dialogs'
import { PagedSet, PageRequest } from '../../table/models/'
import { AdminClientInvoiceDetails, AdminJPMTransactionDetails, AdminTransaction, ClientInvoiceProcessingEvent, JPMTransactionCheck } from '../models'

import { ClientInvoiceStore } from './client-invoice.store'

@Injectable({
    providedIn: 'root',
})
export class ClientInvoiceService {

    constructor(
        private dialogs: DialogService,
        private store: ClientInvoiceStore,
    ) { }

    dismissJPMTransactionDetail(jpmTransactionDetailId: string, dismissalNote: string): Observable<AdminJPMTransactionDetails> {
        return this.store.dismissJPMTransactionDetail(jpmTransactionDetailId, dismissalNote)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    get(req: PageRequest): Observable<PagedSet<AdminTransaction>> {
        return this.store.get(req)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    getInReview(req: PageRequest): Observable<PagedSet<AdminTransaction>> {
        return this.store.getInReview(req)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    getScheduled(req: PageRequest): Observable<PagedSet<AdminTransaction>> {
        return this.store.getScheduled(req)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    getPaymentRequestCheckPage(transactionId: string, page: PageRequest): Observable<PagedSet<JPMTransactionCheck>> {
        return this.store.getPaymentRequestCheckPage(transactionId, page)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    getPayoutRequestCheckPage(transactionId: string, page: PageRequest): Observable<PagedSet<JPMTransactionCheck>> {
        return this.store.getPayoutRequestCheckPage(transactionId, page)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    getInvoiceDetails(clientInvoiceId: string): Observable<AdminClientInvoiceDetails> {
        return this.store.getInvoiceDetails(clientInvoiceId)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    getInvoiceProcessingEvents(clientInvoiceId: string, page: PageRequest): Observable<PagedSet<ClientInvoiceProcessingEvent>> {
        return this.store.getProcessingEvents(clientInvoiceId, page)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    getJPMTransactionDetails(jpmTxDetailId: string): Observable<AdminJPMTransactionDetails> {
        return this.store.getJPMTransactionDetails(jpmTxDetailId)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    getPaymentTnT(e2eId: string): Observable<any> {
        return this.store.getPaymentTnT(e2eId)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    getJPMReqForPayout(payoutId: string): Observable<any> {
        return this.store.getJPMReqForPayout(payoutId)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    getUnassociatedJPMTransactions(req: PageRequest): Observable<PagedSet<AdminJPMTransactionDetails>> {
        return this.store.getUnassociatedJPMTransactions(req)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    resendPayout(clientInvoiceId: string): Observable<boolean> {
        return this.store.resendPayout(clientInvoiceId)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    cancelPayout(payoutId: string): Observable<Object> {
        return this.store.cancelPayout(payoutId)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    resendPayment(clientInvoiceId: string, bypassPayout: boolean): Observable<boolean> {
        return this.store.resendPayment(clientInvoiceId, bypassPayout)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }

    setScheduledPaymentReviewAction(clientInvoiceId: string, action: string, message: string): Observable<AdminClientInvoiceDetails> {
        return this.store.setScheduledPaymentReviewAction(clientInvoiceId, action, message)
            .pipe(
                take(1),
                catchError(error => this.dialogs.error(error)),
            )
    }
}
